import axios from 'axios';
import envConfig from '../envConfig';

const api = axios.create({
  baseURL: envConfig.REACT_APP_API_URL,
  headers: {
    'Ocp-Apim-Subscription-Key': envConfig.REACT_APP_API_TOKEN,
  },
});
api.interceptors.request.use(
  (request) => {
    request.headers['Accept-Language'] = 'es-US';
    request.headers.Authorization = `Bearer ${window.localStorage.getItem('ant-token')}`.replaceAll('"', '');
    return request;
  }
);
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location = '/signin';
      window.localStorage.removeItem('ant-token');
      window.localStorage.removeItem('ant-user');
      window.localStorage.removeItem('ant-state');
      window.localStorage.removeItem('ant-card');
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
