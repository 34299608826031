import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, { useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Avatar from 'react-avatar-edit';
import api from '../../../../../../api/api';
import {
  profileAtom,
  tokenAtom,
  userAtom,
} from '../../../../../../atoms/Atoms';

function Modal() {
  const ref = useRef(null);
  const [profile, setProfile] = useRecoilState(profileAtom);
  const token = useRecoilValue(tokenAtom);
  const setUser = useSetRecoilState(userAtom);
  const [image, setImage] = useState(null);

  const close = () => {
    setProfile({ ...profile, avatar: null });
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const onCrop = async (preview) => {
    setImage(dataURLtoFile(preview, 'avatar.png'));
  };

  const onSubmit = async () => {
    const data = new FormData();
    data.append('imageFile', image);

    await api
      .post('user/avatar', data, {
        headers: {
          'Content-Type': 'multipart/form-data; boundary=236676',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          setUser(response.data.data);
          window.localStorage.setItem(
            'ant-user',
            JSON.stringify(response.data.data)
          );
          setProfile({ ...profile, avatar: null });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Transition appear show={!!profile.avatar} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={close}
      >
        <div className="flex justify-center items-center h-screen">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="bg-gray-900 absolute inset-0 backdrop-filter backdrop-blur-sm bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex flex-col w-full h-5/6 md:h-auto w-full max-w-lg my-8 divide-y divide-gray-200 transition-all transform bg-white shadow-xl duration-500 easy-in-out">
              {/* HEADER */}
              <Dialog.Title
                as="h3"
                className="flex items-center space-x-2 px-4 justify-between text-lg font-medium h-20"
              >
                {/* HEADER TITLE */}
                <div>Avatar</div>
                {/* HEADER ACTIONS */}
                <div className="flex items-center space-x-2">
                  <button
                    type="button"
                    onClick={close}
                    className="flex items-center justify-center text-gray-500 hover:text-gray-600 ring-0 focus:ring-0 bg-gray-100 w-10 h-10 rounded-full outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                  >
                    <XIcon className="w-5 h-5" />
                  </button>
                </div>
              </Dialog.Title>
              {/* CONTENT */}
              <div className="flex flex-col flex-1 items-center justify-center overflow-y-auto no-scrollbar p-4">
                <Avatar
                  ref={ref}
                  width={400}
                  height={400}
                  onCrop={onCrop}
                  src={profile.avatar}
                />
              </div>
              {/* FOOTER */}
              <div className="flex items-center justify-end space-x-2 px-4 bg-gray-100 h-20">
                <button
                  type="button"
                  className="font-semibold text-xs flex items-center justify-center rounded h-10 w-20 text-white bg-gray-700 hover:bg-gray-600 outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-in-out"
                  onClick={close}
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  className="font-semibold text-xs flex items-center justify-center rounded h-10 w-20 text-white bg-green-400 hover:bg-green-500 outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-in-out"
                  onClick={onSubmit}
                >
                  Guardar
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
