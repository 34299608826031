import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import _ from 'lodash';
import is from 'is_js';
import moment from 'moment-timezone';
import { ChevronDownIcon, ClockIcon } from '@heroicons/react/outline';
import { Menu, Transition } from '@headlessui/react';
import {
  envAtom,
  locationAtom,
  notificationsAtom,
  serviceAtom,
  storeAtom,
  tokenAtom,
  promoCodeAtom
} from '../../../../../atoms/Atoms';
import { checkoutSelector } from '../../../../../atoms/Selectors';
import api from '../../../../../api/api';
import Loader from '../../../../shared-components/loader/Loader';

function Promocodes() {
  const token = useRecoilValue(tokenAtom);
  const checkout = useRecoilValue(checkoutSelector);
  const store = useRecoilValue(storeAtom);
  const service = useRecoilValue(serviceAtom);
  const env = useRecoilValue(envAtom);
  const location = useRecoilValue(locationAtom);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [promoCode, setPromoCode] = useRecoilState(promoCodeAtom);
  const [code, setCode] = useState('');

  function validateCoupon() {
    setLoading(true);
    api.get(`promocode/validate/${code}/${store.id}`)
    .then((response) => {
      setLoading(false);
      if (response.data.error) {
        setNotifications({
          ...notifications,
          title: 'Error al validar el código promocional',
          description:
            'Lo sentimos, hubo un error al validar código promocional. Favor de intentar nuevamente.',
          error: true
        });
      } else if (response.data.data.isValid) {
        if (response.data.data.minimumAmount > checkout.subtotal) {
          setNotifications({
            ...notifications,
              title: 'Código promocional no valido',
              description:
                `Lo sentimos, el código promocional requiere un minimo de $${response.data.data.minimumAmount.toFixed(2)}. Favor de intentar uno diferente.`,
              error: true
          });
        } else if (response.data.data.appliesTo === 0 && service !== 0) {
          setNotifications({
            ...notifications,
              title: 'Código promocional no valido',
              description:
                'Lo sentimos, el código promocional solo se aplica a pedidos para recoger. Favor de intentar uno diferente.',
              error: true
          });
        } else if (response.data.data.appliesTo === 1 && service !== 1) {
          setNotifications({
            ...notifications,
              title: 'Código promocional no valido',
              description:
                'Lo sentimos, el código promocional solo se aplica a pedidos para entrega. Favor de intentar uno diferente.',
              error: true
          });
        } else {
          setPromoCode(response.data.data);
        }
      } else if (response.data.data.isExpired) {
        setNotifications({
          ...notifications,
            title: 'Código promocional no valido',
            description:
              'Lo sentimos, el código promocional ya ha expirado. Favor de intentar uno diferente.',
            error: true
        });
      } else if (response.data.data.isRedeemed) {
        setNotifications({
          ...notifications,
            title: 'Código promocional no valido',
            description:
              'Lo sentimos, el código promocional ya fue redimido. Favor de intentar uno diferente.',
            error: true
        });
      } else if (response.data.data.isDifferentStore) {
        setNotifications({
          ...notifications,
            title: 'Código promocional no valido',
            description:
              `Lo sentimos, el código promocional solo aplica para la tienda ${response.data.data.businessName}. Favor de intentar uno diferente.`,
            error: true
        });
      } else if (response.data.data.isDifferentUser) {
        setNotifications({
          ...notifications,
            title: 'Código promocional no valido',
            description:
              'Lo sentimos, el código promocional es para otro usuario. Favor de intentar uno diferente.',
            error: true
        });
      } else {
        setNotifications({
          ...notifications,
            title: 'Código promocional no valido',
            description:
              'Lo sentimos, el código promocional no es valido. Favor de intentar uno diferente.',
            error: true
        });
      }
    })
    .catch((err) => {
      setLoading(false);
      setNotifications({
        ...notifications,
          title: 'Error al validar el código promocional',
          description: err.message,
          error: true
      });
    });
  }

  return (
    <div className="p-4">
      <div className="border">
        <div className="p-4 flex items-center bg-gray-100 space-x-4">
          <div>
            <div className="text-lg font-extrabold tracking-tight">Código promocional</div>
            <div className="text-xs text-gray-500 font-normal tracking-tight">
              Si tienes un código promocional puedes aplicarlo a continuación.
            </div>
          </div>
        </div>
        <div className="p-4 h-20 flex">
          <div className="w-full mr-2">
            <input
              name="name"
              type="text"
              disabled={promoCode}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="text-xs rounded p-4 w-full disabled:opacity-80 disabled:cursor-not-allowed appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
            />
          </div>
          <button
            type="button"
            hidden={promoCode}
            disabled={!promoCode && code?.length === 0}
            onClick={() => validateCoupon()}
            className="uppercase disabled:opacity-30 disabled:cursor-not-allowed tracking-tight font-bold bg-green-400 text-white"
          >
            <div className="flex mx-4">
              <span>Someter</span>
              {loading && (
                <div className="mt-2 ml-2">
                  <Loader />
                </div>
              )}
            </div>
          </button>
          <button
            type="button"
            hidden={!promoCode}
            onClick={() => setPromoCode(null)}
            className="uppercase disabled:opacity-30 disabled:cursor-not-allowed tracking-tight font-bold bg-red-400 text-white"
          >
            <span className="mx-4">Remover</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Promocodes;
