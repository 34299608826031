import React from 'react';
import { useRecoilValue } from 'recoil';
import { orderAtom, storeAtom } from '../../../../../atoms/Atoms';
import { checkoutSelector } from '../../../../../atoms/Selectors';

function Total() {
  const order = useRecoilValue(orderAtom);
  const store = useRecoilValue(storeAtom);
  const checkout = useRecoilValue(checkoutSelector);

  if (store) {
    return (
      <div>
        <div className="flex flex-col items-start md:items-end tracking-tight font-extrabold text-white text-xl md:text-3xl">
          {`Total $${checkout.total.toFixed(2)}`}
        </div>
        <div className="flex flex-col items-start md:items-end text-xs font-medium text-gray-300">
          <div className="flex items-center space-x-2">
            {order.length > 1 ? 'Productos:' : 'Producto:'} {order.length}
          </div>
          <div className="flex">Subtotal: ${checkout.subtotal.toFixed(2)}</div>
          <div className="flex">
            Impuesto Municipal: ${checkout.munTax.toFixed(2)}
          </div>
          <div className="flex">
            Impuesto Estatal: ${checkout.staTax.toFixed(2)}
          </div>
          <div className="flex">Servicio: ${checkout.service.toFixed(2)}</div>
          {checkout.discountTotal > 0 && (
            <div className="flex text-green-300">Descuento: ${checkout.discountTotal.toFixed(2)}</div>
          )}
        </div>
      </div>
    );
  }

  return null;
}

export default Total;
