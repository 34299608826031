import React, { useState } from 'react';
import _ from 'lodash';
import { Dialog, Transition } from '@headlessui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import api from '../../../../../../api/api';
import { paymentMethodsAtom, tokenAtom } from '../../../../../../atoms/Atoms';
import Loader from '../../../../../shared-components/loader/Loader';

function Alert() {
  const [payments, setPayments] = useRecoilState(paymentMethodsAtom);
  const token = useRecoilValue(tokenAtom);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onCancel = () => setPayments({ ...payments, alert: null });

  const deletePayment = () => {
    setLoading(true);

    api
      .post(`user/cards/remove/${payments.alert}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.error) {
          setLoading(false);
          setError(response.data.error);
        } else {
          const array = _.filter(
            payments.payments,
            (card) => card.id !== payments.alert
          );
          setPayments({
            ...payments,
            payments: array,
            alert: null,
          });
          setLoading(false);
          setError(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  return (
    <Transition appear show={!!payments.alert} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onCancel}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => {
              setError(null);
              setLoading(false);
            }}
          >
            <Dialog.Overlay className="bg-gray-900 absolute inset-0 backdrop-filter backdrop-blur-sm bg-opacity-50" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="space-y-4 inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Eliminar Método de Pago
              </Dialog.Title>
              <div className="text-sm text-gray-700">
                <span className="font-semibold">
                  ¿Estás seguro que deseas eliminar el método de pago?
                </span>{' '}
                Al presionar que si, el método de pago será eliminado de manera
                permanente.
              </div>
              <div className="text-rose-400 text-xs">{error}</div>
              <div className="flex items-center space-x-2">
                <button
                  type="button"
                  className="text-gray-600 font-semibold text-xs flex items-center justify-center rounded h-10 w-20 border hover:border-gray-500 outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-in-out"
                  onClick={onCancel}
                >
                  No
                </button>
                <button
                  type="button"
                  className="text-white font-semibold text-xs flex items-center justify-center rounded h-10 w-20 bg-rose-500 hover:bg-rose-400 outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-in-out"
                  onClick={deletePayment}
                >
                  {loading ? <Loader color="bg-white" /> : 'Si'}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Alert;
