import React from 'react';
import { useRecoilState } from 'recoil';
import { instructionsAtom } from '../../../../../../atoms/Atoms';

function Instructions() {
  const [instructions, setInstructions] = useRecoilState(instructionsAtom);
  return (
    <div className="w-full">
      <div className="text-sm font-medium uppercase mb-2 pb-2">
        Instrucciones Especiales
      </div>
      <textarea
        name="instructions"
        rows="3"
        value={instructions}
        onChange={(e) => setInstructions(e.target.value)}
        className="p-3 w-full text-sm appearance-none ring-0 focus:ring-0 border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
      />
    </div>
  );
}

export default Instructions;
