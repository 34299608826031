import React, { useEffect, useState } from 'react';
import is from 'is_js';
import { Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Checkout from './shared-components/checkout/Checkout';
import Items from './shared-components/items/Items';
import Address from './shared-components/address/Adress';
import Hours from './shared-components/hours/Hours';
import Person from './shared-components/person/Person';
import Payment from './shared-components/payment/Payment';
import Promocodes from './shared-components/promocodes/Promocodes';
import logo from '../../../assets/images/logo.png';
import Breadcrumbs from './shared-components/breadcrumps/Breadcrumbs';
import { notificationsAtom, promoCodeAtom } from '../../../atoms/Atoms';
import { checkoutSelector } from '../../../atoms/Selectors';
import envConfig from '../../../envConfig';

function Order() {
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const setPromoCode = useSetRecoilState(promoCodeAtom);
  const checkout = useRecoilValue(checkoutSelector);
  const [showErrors, setShowErrors] = useState(true);
  const user = localStorage.getItem('ant-user');
  const store = localStorage.getItem('ant-store');
  const cart = localStorage.getItem('ant-cart');
  const [userIsValid, setUserIsValid] = useState(true);

  useEffect(() => {
    if (is.null(user)) {
      setNotifications({ ...notifications, userError: true });
    } else {
      let isValid = true;
      if (envConfig.ValidateUserEmail === 'true' &&
        !user.emailConfirmed) {
        isValid = false;
      }
      if (envConfig.ValidateUserPhone === 'true' &&
        !user.phoneNumberConfirmed) {
        isValid = false;
      }
      if (!isValid) {
        setNotifications({ ...notifications, userValidationError: true });
      }
      setUserIsValid(isValid);
    }
  }, [user]);

  useEffect(() => {
    if (is.null(store)) {
      setNotifications({ ...notifications, storeError: true });
    }
  }, [store]);

  useEffect(() => {
    if (is.null(cart)) {
      setNotifications({ ...notifications, orderError: true });
    }
  }, [cart]);

  useEffect(() => {
    setPromoCode(null);
  }, []);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="bg-cyan-500">
          <div className="bg-black bg-opacity-90 text-white h-auto md:h-screen overflow-y-auto no-scrollbar">
            <div className="flex items-center justify-between p-4">
              <img alt="logo" src={logo} className="h-16" />
              <Breadcrumbs />
            </div>
            <Items />
          </div>
        </div>
        <div className="h-auto md:h-screen overflow-y-auto no-scrollbar">
          <Address />
          <Promocodes />
          <Hours />
          <Person />
          <Payment />
          <Checkout />
        </div>
      </div>
      <Transition
        show={is.not.empty(checkout.errors) && showErrors}
        enter="transform transition duration-500 delay-200"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-full"
        className="fixed inset-x-0 bottom-0"
      >
        <div className="flex bg-amber-500 p-4 text-white space-x-4 text-sm">
          <div>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="text-3xl text-amber-800"
            />
          </div>
          <div className="flex-1 text-sm font-medium text-amber-100">
            <div className="font-semibold text-base">Para completar orden</div>
            <ul className="list-disc pl-5 space-y-1 mt-2">
              {checkout?.errors?.hour && <li>{checkout?.errors?.hour}</li>}
              {checkout?.errors?.card && <li>{checkout?.errors?.card}</li>}
              {checkout?.errors?.minimum && <li>{checkout?.errors?.minimum}</li>}
              {user && !userIsValid && <li>Completar validación de cuenta</li>}
            </ul>
          </div>
          <div className="flex items-center">
            <button
              type="button"
              onClick={() => setShowErrors(false)}
              className="text-sm bg-amber-600 hover:bg-amber-700 font-semibold rounded px-3 py-2 transition duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
            >
              Esconder
            </button>
          </div>
        </div>
      </Transition>
    </>
  );
}

export default Order;
