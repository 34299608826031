export default function formatItem(
  item,
  addons,
  requirements,
  sides,
  option,
  currentPrice,
  instructions
) {
  const { id, name, imageUrl } = item;
  return {
    itemId: id,
    sectionName: item.sectionName,
    stateTaxPct: item.stateTaxPct,
    munTaxPct: item.munTaxPct,
    name,
    amount: 1,
    itemImage: imageUrl,
    price: currentPrice,
    specialInstructions: instructions,
    priceId: option ? option.id : '',
    priceName: option ? option.name : null,
    addons: addons && addons.length > 0 ? addons.map((a) => a.id) : [],
    addonsDescription:
      addons && addons.length > 0 ? addons.map((a) => a.name) : [],
    requirements:
      requirements && requirements.length > 0
        ? requirements.map((r) => r.id)
        : [],
    requirementsDescription:
      requirements && requirements.length > 0 ? requirements.map((a) => a.name) : [],
    sides:
      sides && sides.length > 0
        ? sides.map((r) => r.id)
        : [],
    sidesDescription:
      sides && sides.length > 0 ? sides.map((a) => a.name) : [],
  };
}
