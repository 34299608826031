import React from 'react';
import { Transition } from '@headlessui/react';
import envConfig from '../../../../envConfig';

function Footer() {
  return (
    <Transition
      appear
      show
      enter="transform transition duration-700 ease-in-out"
      enterFrom="opacity-0 translate-y-full"
      enterTo="opacity-100 translate-y-0"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 -translate-x-0"
      leaveTo="opacity-0 -translate-x-full"
      className="text-white h-20 mb-4 flex justify-center items-center flex-shrink-0 font-medium text-xs px-4"
    >
      <div className="flex flex-col items-center">
        <div>Copyright © {new Date().getFullYear()} Antonino&apos;s Pizza</div>
        <div className="flex flex-row p-2">
          <a href={`mailto:${envConfig.ClaimsEmail}`} className="text-accent hover:underline hover:text-yellow-200 text-xs font-regular tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0 mr-2">Reclamaciones</a>
          <a href="/terms" className="text-accent hover:underline hover:text-yellow-200 text-xs font-regular tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0 mr-2">Terminos de Uso</a>
          <a href="/privacy" className="text-accent hover:underline hover:text-yellow-200 text-xs font-regular tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">{' '}Política de Privacidad</a>
        </div>
      </div>
    </Transition>
  );
}

export default Footer;
