import React from 'react';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { timezoneAtom } from '../../../../../../atoms/Atoms';
import { orderStatus, orderType, renderDate } from '../../../../../../utils';
import Dropdown from './Dropdown';

function Order({ order }) {
  const timezone = useRecoilValue(timezoneAtom);

  function returnColor(status) {
    switch (status) {
      case 3:
        return 'text-green-500';
      case 8:
        return 'text-red-500';
      case 9:
          return 'text-red-500';
      default:
        return 'text-gray-800';
    }
  }

  return (
    <div className="flex items-center justify-between space-x-4 p-4">
      <div>
        <div className="text-xs font-semibold tracking-tight text-gray-800">
          Pedido #{order && order.confirmationCode}
        </div>
        <div className="text-xs">
          <span>Total: </span>
          <span className="text-gray-800 font-medium">
            ${order && order.orderTotal.toFixed(2)}
          </span>
        </div>
        <div className="text-xs">
          <span>{orderType(order.type)}: </span>
          <span className="text-gray-800 font-medium">
            {renderDate(order?.endSlotTime, timezone, 'dddd, D [de] MMMM, YYYY')}
          </span>
        </div>
        <div className="text-xs">
          <span>Hora: </span>
          <span className="text-gray-800 font-medium">
            {renderDate(order?.endSlotTime, timezone, 'h:mm A')}
          </span>
        </div>
        <div className="text-xs">
          <span>Estado: </span>{' '}
          <span className={`font-normal ${order && returnColor(order.status)}`}>
            {orderStatus(order.status)}
          </span>
        </div>
      </div>
      <Dropdown
        id={order.id}
        confirmation={order.confirmationCode}
        enabled={order.canCancelOrder}
      />
    </div>
  );
}

export default Order;
