import { atom } from 'recoil';
import envConfig from '../envConfig';

export const envAtom = atom({
  key: 'env',
  default: {
    url: envConfig.REACT_APP_API_URL,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

export const stateAtom = atom({
  key: 'currentState',
  default: null,
});

export const timezoneAtom = atom({
  key: 'timezone',
  default: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

export const menuAtom = atom({
  key: 'menu',
  default: null,
});

export const orderAtom = atom({
  key: 'order',
  default: [],
});

export const sidebarAtom = atom({
  key: 'sidebar',
  default: false,
});

export const storesAtom = atom({
  key: 'stores',
  default: null,
});

export const storeAtom = atom({
  key: 'store',
  default: null,
});

export const userAtom = atom({
  key: 'user',
  default: null,
});

export const tokenAtom = atom({
  key: 'token',
  default: null,
});

export const serviceAtom = atom({
  key: 'service',
  default: 0,
});

export const itemAtom = atom({
  key: 'item',
  default: null,
});

export const keywordAtom = atom({
  key: 'keyword',
  default: '',
});

export const moreAtom = atom({
  key: 'more',
  default: false,
});

export const optionAtom = atom({
  key: 'option',
  default: '',
});

export const quantityAtom = atom({
  key: 'quantity',
  default: 1,
});

export const addonsAtom = atom({
  key: 'addons',
  default: [],
});

export const sideAtom = atom({
  key: 'side',
  default: [],
});

export const instructionsAtom = atom({
  key: 'instructions',
  default: '',
});

export const checkoutInstructionsAtom = atom({
  key: 'checkout-instructions',
  default: '',
});

export const personAtom = atom({
  key: 'person',
  default: {
    name: '',
    phone: '',
    instructions: '',
  },
});

export const cardsAtom = atom({
  key: 'credit-cards',
  default: null,
});

export const cardAtom = atom({
  key: 'credit-card',
  default: null,
});

export const paymentOptionAtom = atom({
  key: 'payment-option',
  default: null,
});

export const showCreditCardFormAtom = atom({
  key: 'show-credit-card-form',
  default: false,
});

export const notificationsAtom = atom({
  key: 'token-error',
  default: {
    token: null,
    creditCardSuccess: false,
    creditCardError: null,
    creditCardDeleteSuccess: false,
    creditCardDeleteError: null,
    signInError: null,
    signUpError: null,
    signUpSuccess: false,
    forgotSuccess: false,
    userError: false,
    userValidationError: false,
    orderError: false,
    storeError: false,
  },
});

export const hourAtom = atom({
  key: 'hour',
  default: null,
});

export const orderDetailsAtom = atom({
  key: 'order-details',
  default: null,
});

export const pickupModalAtom = atom({
  key: 'pickupModal',
  default: false,
});

export const locationAtom = atom({
  key: 'location',
  default: {
    modal: false,
    loading: true,
    current: 'locations',
    locations: [],
    location: null,
    loadingBusinesses: false,
    businesses: [],
    alert: null,
    page: 0,
    error: null,
    profileModal: false,
    profileCurrent: null,
    set_favorite: false,
    check_favorite: null,
    image_modal: false,
    image_coords: null,
    add_new_modal: false,
  },
});

export const refreshLocationsAtom = atom({
  key: 'refreshLocations',
  default: false,
});

export const confirmationAtom = atom({
  key: 'confirmation',
  default: null,
});

export const profileAtom = atom({
  key: 'profile',
  default: {
    orders: [],
    ordersLoading: false,
    ordersError: null,
    alert: null,
    avatar: null,
    showPasswordError: false,
    passwordError: null,
  },
});

export const paymentMethodsAtom = atom({
  key: 'paymentMethods',
  default: {
    payments: [],
    loading: false,
    error: null,
    alert: null,
    modal: false,
    current: null,
    modalError: null,
  },
});

export const promoCodeAtom = atom({
  key: 'promoCode',
  default: 0
});
