import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Dialog, Transition } from '@headlessui/react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { LocationMarkerIcon, XIcon } from '@heroicons/react/outline';
import {
  locationAtom,
  storeAtom,
  tokenAtom,
} from '../../../../../../atoms/Atoms';
import Button from '../../../../../shared-components/buttons/Button';
import api from '../../../../../../api/api';
import Loader from '../../../../../shared-components/loader/Loader';

function FavoriteModal() {
  const [locations, setLocations] = useRecoilState(locationAtom);
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = useRecoilValue(tokenAtom);
  const setStore = useSetRecoilState(storeAtom);

  const closeModal = () => {
    setLocations({ ...locations, set_favorite: false });
  };

  const getBusiness = (e) => {
    setLoading(true);
    api
      .get(`/businesses/${e.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setLoading(false);
          setError(response.data.error);
        } else {
          setLoading(false);
          setError(null);
          setData(response.data.data.results);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  useEffect(() => {
    if (locations.check_favorite) getBusiness(locations.check_favorite);
  }, [locations.check_favorite]);

  return (
    <Transition show={locations.set_favorite} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="flex justify-center items-center h-screen">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="bg-gray-900 absolute inset-0 backdrop-filter backdrop-blur-sm bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-500"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            afterLeave={() => {
              setLocations({ ...locations, check_favorite: null });
            }}
          >
            <div className="flex flex-col w-full h-5/6 w-full max-w-lg my-8 divide-y divide-gray-200 transition-all transform bg-white shadow-xl duration-500 easy-in-out">
              {/* HEADER */}
              <Dialog.Title
                as="h3"
                className="flex items-center space-x-2 px-4 justify-between text-lg font-medium h-20"
              >
                {/* HEADER TITLE */}
                <div>Seleccionar Restaurante</div>
                {/* HEADER ACTIONS */}
                <div className="flex items-center space-x-2">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="flex items-center justify-center text-gray-500 hover:text-gray-600 ring-0 focus:ring-0 bg-gray-100 w-10 h-10 rounded-full outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                  >
                    <XIcon className="w-5 h-5" />
                  </button>
                </div>
              </Dialog.Title>
              {loading && (
                <div className="flex flex-col flex-1 items-center justify-center overflow-y-auto no-scrollbar p-4">
                  <div className="flex flex-col justify-center items-center space-y-3">
                    <Loader />
                    <div className="text-xs text-xs text-gray-400">
                      Buscando Restaurantes...
                    </div>
                  </div>
                </div>
              )}
              {!loading && data.length === 0 && (
                <div className="flex flex-col flex-1 items-center justify-center overflow-y-auto no-scrollbar p-4">
                  <div className="flex flex-col justify-center items-center space-y-3">
                    <div className="text-xs text-xs text-gray-400">
                      No encontramos restaurantes cercanos a la localidad
                      seleccionada.
                    </div>
                  </div>
                </div>
              )}
              {!loading && data.length > 0 && (
                <div className="flex flex-col flex-1 overflow-y-auto no-scrollbar p-4 space-y-2">
                  {_.map(data, (e) => (
                    <button
                      key={e.id}
                      type="button"
                      onClick={async () => {
                        await setStore(e);
                        await localStorage.setItem(
                          'ant-store',
                          JSON.stringify(e)
                        );
                        await localStorage.setItem(
                          'ant-location',
                          JSON.stringify(locations.check_favorite)
                        );
                        await setLocations({
                          ...locations,
                          set_favorite: false,
                          location: locations.check_favorite,
                        });
                      }}
                      className="flex space-x-2 text-sm text-left px-4 py-3 hover:bg-gray-100 rounded ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out"
                    >
                      <LocationMarkerIcon className="w-4 h-4 text-gray-600 leading-none" />
                      <div className="leading-none space-y-1">
                        <div className="font-semibold">{e.name}</div>
                        <div className="font-normal text-gray-400 text-xs">
                          {e.locations[0].address1}
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              )}
              {error && (
                <div className="p-4 text-rose-400 text-xs">{error}</div>
              )}
              <div className="flex items-center justify-end space-x-2 px-4 bg-gray-100 h-20">
                <Button
                  className="w-20 bg-gray-700 hover:bg-gray-600"
                  text="Cerrar"
                  textColor="text-white"
                  onClick={closeModal}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default FavoriteModal;
