import React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  ChevronDownIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  PhoneIcon,
} from '@heroicons/react/outline';
import { moreAtom, storeAtom } from '../../../../../atoms/Atoms';
import Business from './shared-components/business/Business';
import { phoneNumber } from '../../../../../utils';

function Title() {
  const store = useRecoilValue(storeAtom);
  const [more, setMore] = useRecoilState(moreAtom);

  return (
    <div className="container mx-auto p-4 mt-4">
      <div className="text-4xl font-bold">
        {store && store.locations[0].name}
      </div>
      <div className="text-gray-500 text-xs mt-2">
        {store && store.locations[0].address1}
      </div>
      <div className="flex items-center space-x-2 mt-4">
        <div className="hidden md:flex items-center justify-center h-8 border rounded-full text-xs uppercase px-4">
          <PhoneIcon className="w-4 h-4 mr-1" />
          {store && phoneNumber(store.locations[0].orderPhone)}
        </div>
        <button
          type="button"
          className="space-x-1 flex items-center h-8 border rounded-full text-xs uppercase px-4 hover:bg-gray-50 transition duration-500 ease-in-out outline-none focus:outline-none"
          onClick={() => {
            window.open(`https://maps.google.com/?q=${store.locations[0].geo.lat},${store.locations[0].geo.lon}`, '_blank');
          }}
        >
          <LocationMarkerIcon className="w-4 h-4 mr-1" />
          <span>{store && store.locations[0].city}</span>
        </button>
        <button
          type="button"
          className="space-x-1 flex items-center h-8 border rounded-full text-xs uppercase px-4 hover:bg-gray-50 transition duration-500 ease-in-out outline-none focus:outline-none"
          onClick={() => setMore(!more)}
        >
          <InformationCircleIcon className="w-4 h-4 mr-1" />
          <span>Más Información</span>
          <ChevronDownIcon
            className={`transition duration-500 easy-in-out ${
              more ? 'transform rotate-180' : ''
            } w-4 h-4 mr-1`}
          />
        </button>
      </div>
      <div className="overflow-hidden">
        <Business />
      </div>
    </div>
  );
}

export default Title;
