import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
  HomeIcon,
  LogoutIcon,
  ReceiptRefundIcon,
  ShoppingBagIcon,
} from '@heroicons/react/outline';
import { useTitle } from 'react-use';
import { Transition } from '@headlessui/react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import logo from '../../../../assets/images/logo.png';
import {
  orderAtom,
  storeAtom,
  tokenAtom,
  userAtom,
} from '../../../../atoms/Atoms';

function Sidebar({ navigation }) {
  const history = useHistory();
  const [user, setUser] = useRecoilState(userAtom);
  const order = useRecoilValue(orderAtom);
  const store = useRecoilValue(storeAtom);
  const setToken = useSetRecoilState(tokenAtom);

  useTitle('Antoninos | Perfil');

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64 bg-cyan-500">
        <div className="flex flex-col h-0 flex-1 bg-black bg-opacity-90">
          <div className="flex-1 flex flex-col overflow-y-auto">
            <NavLink
              exact
              to="/"
              className="flex items-center flex-shrink-0 px-4 h-16"
            >
              <img className="h-14 w-auto" src={logo} alt="antonino's" />
            </NavLink>
            <nav className="flex-1 p-4 space-y-1 text-gray-400">
              <NavLink
                exact
                to="/"
                activeClassName="bg-gray-800 text-white"
                className="h-10 group hover:text-white hover:bg-gray-800 flex items-center px-2 py-2 text-xs font-medium rounded-md transition duration-500 ease-in-out"
              >
                <HomeIcon className="mr-4 flex-shrink-0 h-4 w-4" />
                <span className="flex-1">Inicio</span>
              </NavLink>
              {store && (
                <NavLink
                  to="/menu"
                  activeClassName="bg-gray-800 text-white"
                  className="h-10 group hover:text-white hover:bg-gray-800 flex items-center px-2 py-2 text-xs font-medium rounded-md transition duration-500 ease-in-out"
                >
                  <ShoppingBagIcon className="mr-4 flex-shrink-0 h-4 w-4" />
                  <span className="flex-1">Menú</span>
                </NavLink>
              )}
              {order?.length > 0 && (
                <NavLink
                  to="/order"
                  activeClassName="bg-gray-800 text-white"
                  className="h-10 group hover:text-white hover:bg-gray-800 flex items-center px-2 py-2 text-xs font-medium rounded-md transition duration-500 ease-in-out"
                >
                  <ReceiptRefundIcon className="mr-4 flex-shrink-0 h-4 w-4" />
                  <span className="flex-1">Orden</span>
                  <Transition
                    show={order?.length > 0}
                    enter="transform transition duration-1000"
                    enterFrom="opacity-0 scale-50"
                    enterTo="opacity-100 scale-100"
                    leave="transform duration-1000 transition ease-in-out"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                    className="w-6 h-6 border-2 border-green-400 text-xs text-green-400 font-bold flex items-center justify-center rounded-full"
                  >
                    {order?.length}
                  </Transition>
                </NavLink>
              )}
              <div className="text-xs font-medium text-white py-2">
                Perfil del Usuario
              </div>
              {navigation.map((item) => (
                <NavLink
                  exact
                  key={item.name}
                  to={item.path}
                  activeClassName="bg-gray-800 text-white"
                  className="h-10 group hover:text-white hover:bg-gray-800 flex items-center px-2 py-2 text-xs font-medium rounded-md transition duration-500 ease-in-out"
                >
                  <item.icon className="mr-4 flex-shrink-0 h-4 w-4" />
                  <span className="flex-1">{item.name}</span>
                  <Transition
                    show={item.badge > 0}
                    enter="transform transition duration-1000"
                    enterFrom="opacity-0 scale-50"
                    enterTo="opacity-100 scale-100"
                    leave="transform duration-1000 transition ease-in-out"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                    className="w-6 h-6 border-2 border-green-400 text-xs text-green-400 font-bold flex items-center justify-center rounded-full"
                  >
                    {item.badge}
                  </Transition>
                </NavLink>
              ))}
              <button
                type="button"
                onClick={() => {
                  setUser(null);
                  setToken(null);
                  localStorage.removeItem('ant-token');
                  localStorage.removeItem('ant-user');
                  localStorage.removeItem('ant-state');
                  localStorage.removeItem('ant-card');
                  history.push('/');
                }}
                className="w-full h-10 group hover:bg-rose-500 hover:text-white flex items-center px-2 py-2 text-xs font-medium rounded-md transition duration-500 ease-in-out"
              >
                <LogoutIcon className="mr-4 flex-shrink-0 h-4 w-4" />
                <span className="flex-1 text-left">Cerrar Sesión</span>
              </button>
            </nav>
          </div>
          <div className="flex-shrink-0 flex p-4">
            <div className="flex-shrink-0 w-full group block">
              <div className="flex items-center">
                <div className="bg-gray-700 rounded-full">
                  <img
                    src={user?.avatar}
                    alt="Avatar"
                    className="flex flex-shrink-0 h-10 w-10 rounded-full"
                  />
                </div>
                <div className="ml-3 text-xs font-medium text-gray-400">
                  <p className="transform truncate">{user?.completeName}</p>
                  <p className="transform truncate">{user?.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
