import React from 'react';

function Title() {
  return (
    <div className="flex flex-col items-center md:items-start text-4xl md:text-5xl tracking-tight font-extrabold">
      Antonino&apos;s Pizza
    </div>
  );
}

export default Title;
