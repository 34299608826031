import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.pr';
import { personAtom, serviceAtom, userAtom } from '../../../../../atoms/Atoms';
import { phoneNumber } from '../../../../../utils';

function Person() {
  const [person, setPerson] = useRecoilState(personAtom);
  const user = useRecoilValue(userAtom);
  const service = useRecoilValue(serviceAtom);
  useEffect(() => {
    if (user) {
      setPerson({
        name: user.completeName,
        phone: phoneNumber(user.phoneNumber),
      });
    }
  }, [user]);

  return (
    <div className="px-4">
      <div className="border">
        <div className="p-4 flex items-center bg-gray-100 space-x-4">
          <div className={`${service === 0 ? 'block' : 'hidden'}`}>
            <div className="text-lg font-extrabold tracking-tight">
              ¿Quién recogerá la orden?
            </div>
            <div className="text-xs text-gray-500 font-normal tracking-tight">
              Favor de escribir el nombre completo y teléfono de la persona que
              recogerá la ordén.
            </div>
          </div>
          <div className={`${service === 1 ? 'block' : 'hidden'}`}>
            <div className="text-lg font-extrabold tracking-tight">
              ¿Quién recibirá la orden?
            </div>
            <div className="text-xs text-gray-500 font-normal tracking-tight">
              Favor de escribir el nombre completo y teléfono de la persona que
              recibirá la ordén.
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 text-xs p-4">
          <div>
            <div className="font-bold tracking-tight mb-1">Nombre</div>
            <input
              name="name"
              type="text"
              value={person.name}
              onChange={(e) => setPerson({ ...person, name: e.target.value })}
              placeholder="Juan del Pueblo"
              className="text-xs rounded p-4 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
            />
          </div>
          <div>
            <div className="font-bold tracking-tight mb-1">Teléfono</div>
            <Cleave
              name="phone"
              type="phone"
              options={{
                phone: true,
                phoneRegionCode: 'PR',
              }}
              value={person.phone}
              onChange={(e) => {
                setPerson({ ...person, phone: e.target.rawValue });
              }}
              placeholder="787 777 7777"
              className="text-xs rounded p-4 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
            />
          </div>
          <div className="col-span-2">
            <div className="font-bold tracking-tight mb-1">
              Instrucciones especiales (opcional)
            </div>
            <textarea
              name="instructions"
              rows="3"
              value={person.instructions}
              onChange={(e) => {
                setPerson({ ...person, instructions: e.target.value });
              }}
              placeholder="Escribir instrucciones..."
              className="text-xs rounded p-4 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Person;
