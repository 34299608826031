import React, { Fragment, useRef, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Dialog, Transition } from '@headlessui/react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { locationAtom, refreshLocationsAtom, tokenAtom } from '../../../../../../atoms/Atoms';
import api from '../../../../../../api/api';
import List from './shared-components/list/List';
import Register from './shared-components/register/Register';

function Delivery() {
  const ref = useRef(null);
  const history = useHistory();
  const token = useRecoilValue(tokenAtom);
  const refreshLocations = useRecoilValue(refreshLocationsAtom);
  const [locations, setLocations] = useRecoilState(locationAtom);

  useEffect(() => {
    if (locations.modal) {
      if (token) {
        setLocations({
          ...locations,
          loading: true,
          error: null,
        });

        api
          .get('user/locations', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.data.error) {
              setLocations({
                ...locations,
                loading: false,
                error: response.data.error,
              });
            }

            setLocations({
              ...locations,
              locations: response.data.data.results,
              loading: false,
              error: null,
            });
          })
          .catch((error) => {
            setLocations({
              ...locations,
              loading: false,
              error: error.message,
            });
          });
      } else {
        setLocations({
          ...locations,
          modal: false,
        });
        history.push('/signin', { from: 'locations-modal' });
      }
    }
  }, [token, locations.modal], refreshLocations);

  return (
    <Transition.Root show={locations.modal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={ref}
        open={locations.modal}
        onClose={() => setLocations({ ...locations, modal: false })}
      >
        <div className="flex items-center justify-center h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="flex flex-col bg-gray-800 text-white rounded-xl overflow-hidden shadow-xl transform transition-all w-full md:w-5/6 h-5/6"
          >
            <header className="flex items-center justify-between p-4">
              <div>
                <div className="text-xl font-bold">
                  ¿Dónde querés recibir tu pedido?
                </div>
              </div>
              <button
                type="button"
                className="border-0 text-gray-500 hover:text-gray-700 outline-none focus:outline-none ring-0 focus:ring-0 transition ease-in-out duration-500"
                onClick={() => setLocations({ ...locations, modal: false })}
              >
                <FontAwesomeIcon icon={faTimesCircle} className="text-white text-lg" />
              </button>
            </header>
            <main className="group relative flex flex-col flex-1 overflow-y-auto rounded-b-xl dark">
              {/* Tabs Nav */}
              <div className="sticky top-0 z-50 hidden md:grid grid-cols-2 shadow-lg divide-x divide-gray-100 dark:divide-gray-700">
                <button
                  type="button"
                  className={`${
                    locations.current === 'locations'
                      ? 'bg-emerald-500 hover:bg-emerald-500 text-white'
                      : 'bg-gray-600 hover:bg-gray-500 text-white'
                  } h-14 uppercase flex items-center justify-center space-x-1 p-4 text-xs font-semibold outline-none focus:outline-none ring-0 focus-ring-0 transition duration-200 ease-linear`}
                  onClick={() => {
                    setLocations({
                      ...locations,
                      current: 'locations',
                    });
                  }}
                >
                  <div>Mis Localidades</div>
                  <div className="flex items-center justify-center h-6 w-6 text-white bg-amber-400 rounded-full">
                    {locations.locations.length}
                  </div>
                </button>
                <button
                  type="button"
                  className={`${
                    locations.current === 'register'
                      ? 'bg-emerald-500 hover:bg-emerald-500 text-white'
                      : 'bg-gray-600 hover:bg-gray-500 text-white'
                  } h-14 uppercase flex items-center justify-center space-x-1 p-4 text-xs font-semibold outline-none focus:outline-none ring-0 focus-ring-0 transition duration-500 ease-linear`}
                  onClick={() => {
                    setLocations({
                      ...locations,
                      current: 'register',
                    });
                  }}
                >
                  <div>Registrar Nueva Localidad</div>
                </button>
              </div>
              {/* Tabs Content */}
              {locations.current === 'locations' && <List />}
              {locations.current === 'register' && <Register />}
            </main>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Delivery;
