import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons';
import { Transition } from '@headlessui/react';
import { useRecoilState } from 'recoil';
import { notificationsAtom } from '../../../../atoms/Atoms';

function UserValidationError() {
  const history = useHistory();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  return (
    <Transition
      show={notifications.userValidationError}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-4 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="divide-y max-w-sm w-full bg-white shadow-lg rounded-xl pointer-events-auto overflow-hidden">
        <div className="p-4 text-xs">
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
              <FontAwesomeIcon icon={faUserCircle} className="text-red-500" />
            </div>
            <div className="flex-1">
              <p className=" font-medium text-gray-700">Falta de validación</p>
              <div className="mt-1 text-gray-400">
                Tu cuenta aún no está validada. Para poder enviar pedidos,
                complete la validación de su cuenta.
              </div>
            </div>
          </div>
        </div>
        <div className="flex divide-x">
          <button
            type="button"
            onClick={() => {
              history.push('/profile/settings');
              setNotifications({ ...notifications, userValidationError: false });
            }}
            className="text-blue-500 p-4 w-full text-xs font-semibold tracking-tight bg-gray-100 hover:bg-gray-50 transition duration-500 ease-in-out"
          >
            Ir al perfil
          </button>
        </div>
      </div>
    </Transition>
  );
}

export default UserValidationError;
