import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useMount } from 'react-use';
import { Dialog, Transition } from '@headlessui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import api from '../../../../../../api/api';
import { profileAtom, tokenAtom } from '../../../../../../atoms/Atoms';
import Loader from '../../../../../shared-components/loader/Loader';

function Alert() {
  const [profile, setProfile] = useRecoilState(profileAtom);
  const token = useRecoilValue(tokenAtom);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [store, setStore] = useState(null);

  console.log(store);
  const onCancel = () => setProfile({ ...profile, alert: null });

  useEffect(() => {
    if (profile) {
      const arr = JSON.parse(JSON.stringify(profile));
      const i = _.findIndex(
      arr.orders,
      (o) => o.id === profile.alert
    );
    setStore(arr?.orders[i]);
    }
  }, [profile]);

  const cancelOrder = () => {
    setLoading(true);
    api
      .post(`order/cancel/${profile.alert}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setLoading(false);
          setError(response.data.error);
        } else {
          const array = JSON.parse(JSON.stringify(profile));
          const index = _.findIndex(
            array.orders,
            (o) => o.id === profile.alert
          );
          array.orders[index] = response.data.data;

          setProfile({
            ...profile,
            alert: null,
            orders: array.orders,
          });

          setLoading(false);
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <Transition appear show={!!profile.alert} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onCancel}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="bg-gray-900 absolute inset-0 backdrop-filter backdrop-blur-sm bg-opacity-50" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="space-y-4 inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Cancelar Pedido
              </Dialog.Title>
              <div className="text-sm text-gray-700">
                <span className="font-semibold">
                  Para cancelar su pedido debe comunicarse con la tienda.
                </span>{' '}
                <br />
                {store?.businessName}: {store?.businessPhone}
              </div>
              <div className="text-rose-400 text-xs">{error}</div>
              <div className="flex items-center space-x-2" style={{ float: 'right' }}>
                <button
                  type="button"
                  className="text-gray-600 font-semibold text-xs flex items-center justify-center rounded h-10 w-20 border hover:border-gray-500 outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-in-out"
                  onClick={onCancel}
                >
                  Ok
                </button>
                {/* <button
                  type="button"
                  className="text-white font-semibold text-xs flex items-center
                  justify-center rounded h-10 w-20 bg-rose-500 hover:bg-rose-400
                  outline-none focus:outline-none ring-0 focus:ring-0 transition-colors
                  duration-500 ease-in-out"
                  onClick={cancelOrder}
                >
                  {loading ? <Loader color="bg-white" /> : 'Si'}
                </button> */}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Alert;
