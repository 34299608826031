import React, { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { XIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { locationAtom, tokenAtom } from '../../../../../../atoms/Atoms';
import Button from '../../../../../shared-components/buttons/Button';
import Form from '../../../../../shared-components/form/Form';
import api from '../../../../../../api/api';
import Input from '../../../../../shared-components/form/Input';
import Textarea from '../../../../../shared-components/form/Textarea';

function Modal() {
  const [locations, setLocations] = useRecoilState(locationAtom);
  const token = useRecoilValue(tokenAtom);

  const closeModal = () => {
    setLocations({ ...locations, profileModal: false });
  };

  const formik = useFormik({
    initialValues: {
      AdditionalInstructions: '',
      Name: '',
      AddressLine: '',
    },
    validationSchema: Yup.object().shape({
      Name: Yup.string().required('Campo requerido'),
      AddressLine: Yup.string().required('Campo requerido'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      api
        .put(
          `user/location/${locations.profileCurrent?.id}`,
          {
            AdditionalInstructions: formik.values.AdditionalInstructions,
            Name: formik.values.Name,
            AddressLine: formik.values.AddressLine,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
          } else {
            const array = JSON.parse(JSON.stringify(locations));

            const index = _.findIndex(
              array.locations,
              (l) => l.id === locations.profileCurrent.id
            );

            array.locations[index] = response.data.data;

            setLocations({
              ...locations,
              profileModal: false,
              profileCurrent: null,
              locations: array.locations,
            });

            formik.resetForm();
          }
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (locations.profileCurrent) {
      formik.setValues({
        AdditionalInstructions: locations.profileCurrent.additionalInstructions,
        Name: locations.profileCurrent.name,
        AddressLine: locations.profileCurrent.addressLine,
      });
    }
  }, [locations.profileCurrent]);

  return (
    <Transition show={locations.profileModal} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="flex justify-center items-center h-screen">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="bg-gray-900 absolute inset-0 backdrop-filter backdrop-blur-sm bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-500"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            afterLeave={() => {
              setLocations({ ...locations, profileCurrent: null });
            }}
          >
            <div className="flex flex-col w-full h-5/6 md:h-auto w-full max-w-lg my-8 divide-y divide-gray-200 transition-all transform bg-white shadow-xl duration-500 easy-in-out">
              {/* HEADER */}
              <Dialog.Title
                as="h3"
                className="flex items-center space-x-2 px-4 justify-between text-lg font-medium h-20"
              >
                {/* HEADER TITLE */}
                <div>Editar</div>
                {/* HEADER ACTIONS */}
                <div className="flex items-center space-x-2">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="flex items-center justify-center text-gray-500 hover:text-gray-600 ring-0 focus:ring-0 bg-gray-100 w-10 h-10 rounded-full outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                  >
                    <XIcon className="w-5 h-5" />
                  </button>
                </div>
              </Dialog.Title>
              <div className="flex flex-col flex-1 overflow-y-auto no-scrollbar p-4">
                <Form>
                  <Input
                    label="Nombre"
                    name="Name"
                    type="text"
                    placeholder="Casa, trabajo ,universidad..."
                    required
                    value={formik.values.Name}
                    onChange={formik.handleChange}
                    error={
                      formik.errors.Name &&
                      formik.touched.Name &&
                      formik.errors.Name
                    }
                    className="col-span-1 md:col-span-2"
                  />
                  <Input
                    label="Dirección"
                    name="AddressLine"
                    type="text"
                    placeholder="717 Calle La Paz, San Juan. PR 00907..."
                    required
                    value={formik.values.AddressLine}
                    onChange={formik.handleChange}
                    error={
                      formik.errors.AddressLine &&
                      formik.touched.AddressLine &&
                      formik.errors.AddressLine
                    }
                    className="col-span-1 md:col-span-2"
                  />
                  <Textarea
                    rows={4}
                    label="Instrucciones Adicionales"
                    name="AdditionalInstructions"
                    placeholder="......"
                    value={formik.values.AdditionalInstructions}
                    onChange={formik.handleChange}
                    className="col-span-1 md:col-span-2"
                  />
                </Form>
              </div>
              <div className="flex items-center justify-end space-x-2 px-4 bg-gray-100 h-20">
                <Button
                  className="w-20 bg-gray-700 hover:bg-gray-600"
                  text="Cerrar"
                  textColor="text-white"
                  onClick={closeModal}
                />
                <Button
                  className="w-20 bg-green-400 hover:bg-green-500"
                  text="Guardar"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  loaderColor="bg-white"
                  textColor="text-white"
                  onClick={() => formik.submitForm()}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
