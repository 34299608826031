import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useRecoilValue } from 'recoil';
import { storeAtom } from '../../../../../../../../atoms/Atoms';
import envConfig from '../../../../../../../../envConfig';

mapboxgl.accessToken = envConfig.REACT_APP_MAPBOX_ACCESS_TOKEN;

function BusinessMap() {
  const node = useRef(null);
  const business = useRecoilValue(storeAtom);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: node.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [business.locations[0].geo.lon, business.locations[0].geo.lat],
      zoom: 5,
      attributionControl: false,
      renderWorldCopies: false,
    });

    map.on('load', () => {
      map.easeTo({ bearing: 40, duration: 10000, pitch: 0, zoom: 18 });

      new mapboxgl.Marker({
        color: 'rgba(16, 185, 129)',
        scale: 0.65,
      })
        .setLngLat([
          business.locations[0].geo.lon,
          business.locations[0].geo.lat,
        ])
        .addTo(map);
    });
  }, [business]);

  return <div className="w-full h-96" ref={node} />;
}

export default BusinessMap;
