import React from 'react';
import { Transition } from '@headlessui/react';

function Banner() {
  return (
    <div className="relative h-64 md:h-96 pb-20 overflow-hidden bg-black transition duration-500 ease-in-out bg-fixed bg-pizza-image-8 bg-auto md:bg-cover bg-center bg-no-repeat">
      <div className="absolute bg-opacity-90 bg-gradient-to-r from-black via-transparent to-black inset-0 flex flex-col px-4 items-center justify-center text-center">
        <Transition appear show className="w-full md:w-1/2">
          <Transition.Child
            enter="transition ease-in-out duration-1000 transform"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-1 translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="opacity-1 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <div className="font-serif text-4xl tracking-tight font-extrabold text-white">
              ¡Ordena y Saborea!
            </div>
          </Transition.Child>
          <Transition.Child
            enter="transition ease-in-out duration-1000 transform"
            enterFrom="opacity-0 -translate-x-full"
            enterTo="opacity-1 translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="opacity-1 translate-x-0"
            leaveTo="opacity-0 -translate-x-full"
            className="mt-1"
          >
            <div className="text-white font-semibold">
              Pizza con sabor boricua, preparada diariamente con ingredientes de
              calidad atendidas por dueños locales que brinda un servicio
              personalizado
            </div>
          </Transition.Child>
        </Transition>
      </div>
    </div>
  );
}

export default Banner;
