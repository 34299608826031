import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import is from 'is_js';
import { BellIcon, ShoppingCartIcon } from '@heroicons/react/outline';
import { Transition } from '@headlessui/react';
import { orderAtom } from '../../../../../../atoms/Atoms';

function Cart() {
  const history = useHistory();
  const order = useRecoilValue(orderAtom);
  const [show, setShow] = useState(false);

  return (
    <div className="h-20 flex items-center p-4">
      <div className="relative inline-flex rounded-md shadow-sm">
        <button
          type="button"
          onMouseEnter={() => {
            if (is.empty(order)) setShow(true);
          }}
          onMouseLeave={() => setShow(false)}
          onClick={() => {
            if (is.not.empty(order)) history.push('/order');
          }}
          className="inline-flex uppercase items-center px-4 py-3 text-xs font-semibold rounded-md text-white bg-green-500 ring-0 outline-none focus:ring-0 focus:outline-none transform hover:scale-90 transition ease-in-out duration-500"
        >
          <ShoppingCartIcon className="h-3 w-3 mr-1" />
          Checkout
        </button>
        {order.length > 0 && (
          <span className="flex absolute h-6 w-6 top-0 right-0 -mt-3 -mr-2">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-400 dark:bg-amber-400 opacity-75" />
            <span className="relative flex items-center justify-center rounded-full h-6 w-6 bg-rose-500 dark:bg-amber-500 text-white">
              <span>{order.length}</span>
            </span>
          </span>
        )}
      </div>
      <Transition
        show={show}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="text-gray-800 normal-case font-normal text-xs border border-gray-100 p-4 origin-top-right absolute right-0 mt-2 w-56 rounded-xl shadow-lg bg-white">
          <div className="flex items-start space-x-2">
            <div className="flex-shrink-0">
              <BellIcon className="w-4 h-4" />
            </div>
            <div className="flex-1">
              Aún no tiene productos en su orden. Añada productos a su orden
              para completar la misma.
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default Cart;
