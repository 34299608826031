import React from 'react';
import { Transition } from '@headlessui/react';
import { StarIcon } from '@heroicons/react/solid';

function Placeholder({ index }) {
  return (
    <Transition.Child
      as={React.Fragment}
      enter={`transform transition duration-1000 delay-${index}00`}
      enterFrom="opacity-0 -translate-x-20"
      enterTo="opacity-100 translate-x-0"
      leave="transform duration-1000 transition ease-in-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0 scale-95 "
    >
      <div className="col-span-1 flex flex-col text-center bg-white rounded-md divide-y divide-gray-200">
        <div className="flex-1 flex flex-col p-8">
          <div className="animate-pulse bg-gray-200 w-32 h-32 flex-shrink-0 mx-auto rounded-full" />
          <div className="mt-6 text-gray-900 text-xs font-medium uppercase" />
          <div className="mt-1 flex-grow flex flex-col justify-between text-xs">
            <div className="flex flex-col items-center justify-center space-y-1">
              <div className="flex animate-pulse h-2 w-10 bg-gray-200 rounded-full" />
              <div className="flex animate-pulse h-2 w-48 bg-gray-200 rounded-full" />
            </div>
            <div className="mt-3">
              <div className="animate-pulse flex items-center justify-center text-gray-200 transition duration-500 ease-in-out">
                <StarIcon className="w-7 h-7" />
              </div>
            </div>
          </div>
        </div>
        <div className="-mt-px flex divide-x divide-gray-200 h-12">
          <div className="w-0 flex-1 flex">
            <div className="relative w-0 flex-1 h-12 inline-flex items-center disabled:opacity-50 justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 ring-0 focus:ring-0 outline-none focus:outline-none">
              <div className="animate-pulse h-2 w-10 bg-gray-200 rounded-full" />
            </div>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <div className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 ring-0 focus:ring-0 outline-none focus:outline-none">
              <div className="animate-pulse h-2 w-10 bg-gray-200 rounded-full" />
            </div>
          </div>
        </div>
      </div>
    </Transition.Child>
  );
}

export default Placeholder;
