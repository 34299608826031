import React from 'react';
import is from 'is_js';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { orderAtom } from '../../../../../../atoms/Atoms';

function Item({ item }) {
  const [order, setOrder] = useRecoilState(orderAtom);
  return (
    <div className="flex w-full text-sm p-4 space-x-4">
      {item && item.itemImage && (
        <div className="flex-shrink-0 p-2 border border-white border-opacity-10 rounded">
          <img
            alt="Product"
            src={item.itemImage}
            className="h-20 w-20 object-cover object-center rounded"
          />
        </div>
      )}
      <div className="space-y-2 flex-grow text-xs text-gray-400">
        <div>
          <div className="text-xs tracking-tight text-gray-400">
            {item.sectionName}
          </div>
          <div className="font-bold text-sm tracking-tight text-white">
            {item.name}
          </div>
          {is.not.empty(item.priceName) && (
            <div>{item.priceName}</div>
          )}
          {is.not.empty(item.requirements) && (
            <div>{item.requirementsDescription.toString().replaceAll(',', ' | ')}</div>
          )}
          {is.not.empty(item.specialInstructions) && (
            <div>Instrucciones Especiales: {item.specialInstructions}</div>
          )}
          {is.not.empty(item.addons) && (
            <div>Extras: {item.addonsDescription.toString().replaceAll(',', ' | ')}</div>
          )}
          {is.not.empty(item.sides) && (
            <div>Acompañantes: {item.sides.toString().replaceAll(',', ' | ')}</div>
          )}
        </div>
        <button
          type="button"
          className="flex items-center justify-center text-center text-xs transition duration-500 ease-in-out bg-gray-700 hover:bg-rose-600 text-white font-semibold px-3 py-2 space-x-2 rounded"
          onClick={() => {
            const idx = order.indexOf(item);
            const filter = _.filter(order, (e, i) => i !== idx);
            setOrder(filter);
            if (filter.length > 0) {
              localStorage.setItem('ant-cart', JSON.stringify(filter));
            } else {
              localStorage.removeItem('ant-cart');
            }
          }}
        >
          Eliminar
        </button>
      </div>
      <div className="flex-shrink-0 text-sm tracking-tight text-white font-bold">
        ${item.price.toFixed(2)}
      </div>
    </div>
  );
}

export default Item;
