import { BellIcon } from '@heroicons/react/outline';
import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Transition } from '@headlessui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import api from '../../../../../api/api';
import { profileAtom, tokenAtom } from '../../../../../atoms/Atoms';
import Form from '../../../../shared-components/form/Form';
import Input from '../../../../shared-components/form/Input';
import Button from '../../../../shared-components/buttons/Button';

function Password() {
  const token = useRecoilValue(tokenAtom);
  const [profile, setProfile] = useRecoilState(profileAtom);

  const formik = useFormik({
    initialValues: {
      current: '',
      password: '',
      confirm: '',
    },
    validationSchema: Yup.object().shape({
      current: Yup.string().required('Este campo es requerido.'),
      password: Yup.string().required('Este campo es requerido.'),
      confirm: Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          'Las contraseñas deben ser la misma.'
        )
        .required('Este campo es requerido.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      setProfile({
        ...profile,
        showPasswordError: false,
      });

      api
        .post(
          'user/password/update',
          {
            CurrentPassword: formik.values.current,
            NewPassword: formik.values.password,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            setProfile({
              ...profile,
              showPasswordError: true,
              passwordError: response.data.error,
            });
          } else {
            formik.resetForm();
            setProfile({
              ...profile,
              showPasswordError: false,
              passwordError: null,
            });
          }
          setSubmitting(false);
        })
        .catch((error) => {
          setProfile({
            ...profile,
            showPasswordError: true,
            passwordError: error.response,
          });
          setSubmitting(false);
        });
    },
  });

  return (
    <div className="w-full">
      <div className="p-4 space-y-2">
        <div className="text-xl font-extrabold tracking-tight">Contaseña</div>
        <div className="text-sm">
          Cambie su actual contraseña utilizando el siguiente formulario.
        </div>
        <Form>
          <Input
            label="Contraseña Actual"
            required
            type="password"
            name="current"
            id="current"
            placeholder="******"
            autoCapitalize="false"
            value={formik.values.current}
            onChange={formik.handleChange}
            error={
              formik.errors.current &&
              formik.touched.current &&
              formik.errors.current
            }
            className="col-span-1 md:col-span-2"
          />
          <Input
            label="Nueva Contraseña"
            required
            type="password"
            name="password"
            id="password"
            placeholder="******"
            autoCapitalize="false"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={
              formik.errors.password &&
              formik.touched.password &&
              formik.errors.password
            }
          />
          <Input
            label="Confirmar Contraseña"
            required
            type="password"
            name="confirm"
            id="confirm"
            placeholder="******"
            autoCapitalize="false"
            value={formik.values.confirm}
            onChange={formik.handleChange}
            error={
              formik.errors.confirm &&
              formik.touched.confirm &&
              formik.errors.confirm
            }
          />
        </Form>
        <Button
          text="Guardar"
          textColor="text-white"
          disabled={formik.isSubmitting}
          loading={formik.isSubmitting}
          onClick={formik.submitForm}
          loaderColor="bg-emerald-300"
          className="bg-emerald-500 hover:bg-emerald-400 w-20"
        />
        <Transition
          show={profile.showPasswordError}
          enter="transform duration-1000 transition ease-in-out"
          enterFrom="opacity-0 -translate-x-full"
          enterTo="opacity-100 rotate-0 translate-x-0"
          leave="transform duration-1000 transition ease-in-out"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 -translate-x-full"
          className="flex items-center text-xs text-rose-500 space-x-2 mt-4 pt-4"
        >
          <BellIcon className="w-4 h-4" />
          <div>{profile.passwordError}</div>
        </Transition>
      </div>
    </div>
  );
}

export default Password;
