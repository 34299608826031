import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

function ProtectedRoute({ component: Component, ...rest }) {
  const [user] = useLocalStorage('ant-user');

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return (
            <Route
              {...rest}
              render={(routeProps) => <Component {...routeProps} />}
            />
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

export default ProtectedRoute;
