import React from 'react';
import { useRecoilState } from 'recoil';
import { SearchIcon } from '@heroicons/react/outline';
import { keywordAtom } from '../../../../../../atoms/Atoms';

function Search() {
  const [keyword, setKeyword] = useRecoilState(keywordAtom);
  return (
    <div className="hidden md:flex flex-1 relative">
      <div className="absolute flex items-center justify-center z-10 inset-0 w-8">
        <SearchIcon className="w-4 h-4" />
      </div>
      <input
        type="search"
        name="keyword"
        id="keyword"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        className="pl-8 h-full placeholder-gray-500 bg-transparent w-full text-xs font-semibold border-0 ring-0 outline-none focus:ring-0 focus:outline-none"
        placeholder="Buscar Productos..."
      />
    </div>
  );
}

export default Search;
