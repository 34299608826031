import React from 'react';
import { useSetRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPie,
  faSteak,
  faSandwich,
  faPizzaSlice,
  faDrumstickBite,
  faSoup,
  faSalad,
  faUtensilFork,
  faGlassCitrus,
} from '@fortawesome/pro-duotone-svg-icons';
import { itemAtom } from '../../../../../../atoms/Atoms';

function Item({ item }) {
  const setItem = useSetRecoilState(itemAtom);

  const placeholder = (category) => {
    switch (category) {
      case 'Aperitivos':
        return faSalad;
      case 'Papa Asada':
        return faUtensilFork;
      case 'Alitas':
        return faDrumstickBite;
      case 'Bebidas':
        return faGlassCitrus;
      case 'Pastas':
        return faSoup;
      case 'Italian Grill':
        return faSteak;
      case 'Sandwiches':
        return faSandwich;
      case 'Postre':
        return faPie;
      default:
        return faPizzaSlice;
    }
  };

  return (
    <button
      type="button"
      className="flex h-32 border hover:bg-gray-50 outline-none focus:outline-none"
      onClick={() => setItem(item)}
    >
      <div className="flex flex-col justify-between p-4 h-full w-2/3 text-left">
        <div>
          <div className="font-bold text-sm mb-2">{item.name}</div>
          <div className="text-xs overflow-hidden truncate-2-lines">
            {item.description}
          </div>
        </div>
        <div className="text-xs font-semibold text-green-400">
          {item.prices[1] || item.addOns[0] ? 'Desde' : ''} $
          {item && item.prices[0].activePrice.toFixed(2)}
        </div>
      </div>
      <div className="flex justify-center items-center w-1/3 h-full bg-cover bg-center overflow-hidden">
        {item.imageUrl ? (
          <img alt="Product" src={item.imageUrl} />
        ) : (
          <FontAwesomeIcon
            icon={placeholder(item.sectionName)}
            className="text-6xl text-gray-300"
          />
        )}
      </div>
    </button>
  );
}

export default Item;
