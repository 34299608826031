import React from 'react';
import { useRecoilValue } from 'recoil';
import { serviceAtom } from '../../../../../../atoms/Atoms';

function Service() {
  const service = useRecoilValue(serviceAtom);

  return (
    <div className="flex flex-1 md:flex-initial items-center h-20 transition duration-200 ease-in-out pr-4 py-6">
      <span>Servicio: {service === 0 ? 'Pickup' : 'Delivery'}</span>
    </div>
  );
}

export default Service;
