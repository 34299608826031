import React from 'react';
import { useRecoilState } from 'recoil';
import { locationAtom } from '../../../../../../../../atoms/Atoms';
import { Spinner } from '../../../../../../../shared-components/Spinner';
import EmptyLocations from '../../../../../../../shared-components/illustrations/EmptyLocations';
import useBreakpoint from '../../../../../../../../hooks/useBreakpoint';
import SmallDevicesList from './shared-components/SmallDevicesList';
import MediumDevicesList from './shared-components/MediumDevicesList';

function List() {
  const [location, setLocation] = useRecoilState(locationAtom);
  const breakpoint = useBreakpoint();

  if (location.loading) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!location.loading && location.locations.length === 0) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center bg-gray-800 h-2/3">
        <EmptyLocations className="h-44 w-auto" />
        <div className="flex flex-col justify-center items-center text-center p-4">
          <div className="text-sm font-extrabold tracking-tight">
            Sin Localidades
          </div>
          <div className="text-xs">
            Aún no tienes localidades registradas en tu perfil. Comienza
            registrando una localidad para seleccionar la tienda mas cercana a
            ti.
          </div>
          <button
            type="button"
            onClick={() => setLocation({ ...location, current: 'register' })}
            className="flex items-center disabled:opacity-20 space-x-2 px-3 py-2 mt-4 rounded font-semibold text-xs text-white bg-green-500 hover:bg-green-600 transition duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none cursor-pointer"
          >
            Registrar
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-1 text-sm overflow-hidden dark">
      {breakpoint < 3 ? <SmallDevicesList /> : <MediumDevicesList />}
    </div>
  );
}

export default List;
