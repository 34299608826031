import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  LocationMarkerIcon,
  PhoneIcon,
  ReceiptTaxIcon,
} from '@heroicons/react/outline';
import { serviceAtom, storeAtom } from '../../../../../atoms/Atoms';
import { phoneNumber } from '../../../../../utils';

function Address() {
  const business = useRecoilValue(storeAtom);
  const service = useRecoilValue(serviceAtom);

  return (
    <div className="p-4 flex bg-gray-100 space-x-4">
      <ReceiptTaxIcon className="h-12 w-12" />
      <div className="text-xs font-medium">
        <div className={`${service === 1 ? 'block' : 'hidden'} font-bold tracking-tight`}>Tienda que entregará su orden</div>
        <div className={`${service === 1 ? 'hidden' : 'block'} font-bold tracking-tight`}>¿Donde recoger su orden?</div>
        <div className="text-gray-400">
          {business && business.locations[0].address1}
        </div>
        <div className="text-gray-400">
          {business && business.locations[0].city}
        </div>
        <div className="flex items-center space-x-2 mt-2">
          <a
            className="rounded-full border border-gray-300 space-x-1 px-3 py-1 flex items-center tracking-tight font-bold text-xs text-gray-600 hover:bg-gray-300 transition duration-500 ease-in-out outline-none focus:outline-none"
            href={`tel:${
              business && phoneNumber(business.locations[0].orderPhone)
            }`}
          >
            <PhoneIcon className="h-4 w-4" />
            <span>
              {business && phoneNumber(business.locations[0].orderPhone)}
            </span>
          </a>
          <button
            type="button"
            className="rounded-full bg-yellow-300 space-x-1 px-3 py-1 flex items-center tracking-tight font-bold text-xs text-yellow-600 hover:bg-gray-400 hover:text-white transition duration-500 ease-in-out outline-none focus:outline-none"
            onClick={() => {
              if (
                navigator.platform.indexOf('iPhone') !== -1 ||
                navigator.platform.indexOf('iPod') !== -1 ||
                navigator.platform.indexOf('iPad') !== -1
              ) {
                window.open(
                  `maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${business.locations[0].geo.lat},${business.locations[0].geo.lon}`
                );
              } else {
                window.open(
                  `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${business.locations[0].geo.lat},${business.locations[0].geo.lon}`
                );
              }
            }}
          >
            <LocationMarkerIcon className="h-4 w-4" />
            <span>Ver direcciones</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Address;
