import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon, EyeIcon, TrashIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { profileAtom } from '../../../../../../atoms/Atoms';

function Dropdown({ id, confirmation, enabled }) {
  const history = useHistory();
  const [profile, setProfile] = useRecoilState(profileAtom);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="text-gray-500 hover:bg-gray-200 px-1 py-2 rounded ring-0 focus:ring-0 outline-none focus:outline-none transition-colors duration-500 ease-in-out">
        <DotsVerticalIcon className="h-6 w-6" />
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 shadow-lg ring-0 focus:ring-0 outline-none focus:outline-none">
          <Menu.Item>
            <button
              type="button"
              onClick={() => {
                history.push(`/profile/orders/${confirmation}`);
              }}
              className="group text-xs hover:bg-gray-50 font-medium flex items-center w-full p-4 space-x-2 ring-0 focus:ring-0 outline-none focus:outline-none transition-colors duration-500 ease-in-out"
            >
              <EyeIcon className="w-4 h-4" aria-hidden="true" />
              <span>Ver Detalles</span>
            </button>
          </Menu.Item>
          <Menu.Item>
            <button
              type="button"
              disabled={!enabled}
              onClick={() => setProfile({ ...profile, alert: id })}
              className="group text-xs text-rose-500 hover:bg-gray-50 font-medium disabled:opacity-20 flex items-center w-full p-4 space-x-2 ring-0 focus:ring-0 outline-none focus:outline-none transition-colors duration-500 ease-in-out"
            >
              <TrashIcon className="w-4 h-4" aria-hidden="true" />
              <span>Cancelar Pedido</span>
            </button>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default Dropdown;
