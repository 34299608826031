import React from 'react';
import axios from 'axios';
import Cleave from 'cleave.js/react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Transition } from '@headlessui/react';
import { SaveIcon, XIcon } from '@heroicons/react/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCcAmex,
  faCcVisa,
  faCcDinersClub,
  faCcMastercard,
  faCcDiscover,
} from '@fortawesome/free-brands-svg-icons';
import {
  cardAtom,
  cardsAtom,
  envAtom,
  notificationsAtom,
  showCreditCardFormAtom,
  tokenAtom,
} from '../../../../../../atoms/Atoms';
import envConfig from '../../../../../../envConfig';
import Loader from '../../../../../shared-components/loader/Loader';

function CreditCardForm() {
  const token = useRecoilValue(tokenAtom);
  const [open, setOpen] = useRecoilState(showCreditCardFormAtom);
  const setCard = useSetRecoilState(cardAtom);
  const setCards = useSetRecoilState(cardsAtom);
  const env = useRecoilValue(envAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const fetchCards = () => {
    axios
      .get(`${env.url}user/cards`, {
        headers: {
          'Ocp-Apim-Subscription-Key': envConfig.REACT_APP_API_TOKEN,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setNotifications({
            ...notifications,
            token: response.data.error,
          });
        } else {
          setCards(response.data.data.results);
        }
      })
      .catch((err) => {
        setNotifications({ ...notifications, token: err.message });
      });
  };

  const formik = useFormik({
    initialValues: {
      'cc-type': '',
      'cc-name': '',
      'cc-number': '',
      'cc-exp': '',
      'cc-csc': '',
      'cc-zip': '',
    },
    validationSchema: Yup.object().shape({
      'cc-name': Yup.string().required(
        'TokenError. Este campo es requerido. Favor de escribir su nombre.'
      ),
      'cc-number': Yup.string().required(
        'TokenError. Este campo es requerido. Favor de escribir el número de tarjeta.'
      ),
      'cc-exp': Yup.string().required(
        'TokenError. Este campo es requerido. Favor de escribir la fecha de expiración.'
      ),
      'cc-csc': Yup.string().required(
        'TokenError. Este campo es requerido. Favor de escribir el codigo de seguridad.'
      ),
      'cc-zip': Yup.string().required(
        'TokenError. Este campo es requerido. Favor de escribir el codigo postal de la tarjeta.'
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      axios
        .post(
          `${env.url}user/cards`,
          {
            AddressZip: values['cc-zip'],
            Cvc: values['cc-csc'],
            ExpMonth: values['cc-exp'].slice(0, 2),
            ExpYear: values['cc-exp'].slice(3, 7),
            Name: values['cc-name'],
            Number: values['cc-number'],
          },
          {
            headers: {
              'Ocp-Apim-Subscription-Key': envConfig.REACT_APP_API_TOKEN,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            setNotifications({
              ...notifications,
              creditCardError: response.data.error,
            });
          } else {
            localStorage.setItem('ant-cc-id', response.data.data.id);
            setCard(response.data.data.id);
            formik.resetForm();
            setOpen(false);
            setNotifications({
              ...notifications,
              creditCardSuccess: true,
            });
            fetchCards();
          }
          setSubmitting(false);
        })
        .catch((err) => {
          setNotifications({
            ...notifications,
            creditCardSuccess: false,
            creditCardError: err.message,
          });
          setSubmitting(false);
        });
    },
  });

  return (
    <Transition
      show={open}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-100 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <div className="p-4 text-xs">
        <div className="flex items-center justify-end mb-4">
          <div className="flex justify-end items-center space-x-1 text-purple-200 text-3xl">
            <FontAwesomeIcon
              icon={faCcAmex}
              className={
                formik.values['cc-type'] === 'amex' ? 'text-purple-400' : ''
              }
            />
            <FontAwesomeIcon
              icon={faCcVisa}
              className={
                formik.values['cc-type'] === 'visa' ? 'text-purple-400' : ''
              }
            />
            <FontAwesomeIcon
              icon={faCcDinersClub}
              className={
                formik.values['cc-type'] === 'diners' ? 'text-purple-400' : ''
              }
            />
            <FontAwesomeIcon
              icon={faCcMastercard}
              className={
                formik.values['cc-type'] === 'mastercard'
                  ? 'text-purple-400'
                  : ''
              }
            />
            <FontAwesomeIcon
              icon={faCcDiscover}
              className={
                formik.values['cc-type'] === 'discover' ? 'text-purple-400' : ''
              }
            />
          </div>
        </div>
        <form
          noValidate
          className="grid grid-cols-2 gap-4 text-xs"
          onSubmit={formik.handleSubmit}
        >
          <div className="col-span-2">
            <div className="font-bold tracking-tight mb-1">
              Nombre <span className="text-red-400">*</span>
            </div>
            <input
              name="cc-name"
              type="text"
              placeholder="Juan del Pueblo"
              required
              autoComplete="cc-name"
              value={formik.values['cc-name']}
              onChange={formik.handleChange}
              className="text-xs rounded p-4 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
            />
            {formik.errors['cc-name'] && formik.touched['cc-name'] && (
              <div className="text-xs text-red-400 p-2">
                {formik.errors['cc-name']}
              </div>
            )}
          </div>
          <div>
            <div className="flex items-center space-x-2  font-bold tracking-tight mb-1">
              <div>
                Número de tarjeta <span className="text-red-400">*</span>
              </div>
            </div>
            <Cleave
              name="cc-number"
              type="text"
              placeholder="**** **** **** 3742"
              required
              autoComplete="cc-number"
              value={formik.values['cc-number']}
              onChange={formik.handleChange}
              options={{
                creditCard: true,
                onCreditCardTypeChanged(type) {
                  formik.setFieldValue('cc-type', type);
                },
              }}
              className="text-xs rounded p-4 w-full  appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
            />
            {formik.errors['cc-number'] && formik.touched['cc-number'] && (
              <div className="text-xs text-red-400 p-2">
                {formik.errors['cc-number']}
              </div>
            )}
          </div>
          <div>
            <div className=" font-bold tracking-tight mb-1">
              Fecha de expiración <span className="text-red-400">*</span>
            </div>
            <Cleave
              name="cc-exp"
              type="text"
              placeholder="MM/YYYY"
              required
              autoComplete="cc-exp"
              value={formik.values['cc-exp']}
              onChange={formik.handleChange}
              options={{
                date: true,
                datePattern: ['m', 'Y'],
              }}
              className="text-xs rounded p-4 w-full  appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
            />
            {formik.errors['cc-exp'] && formik.touched['cc-exp'] && (
              <div className="text-xs text-red-400 p-2">
                {formik.errors['cc-exp']}
              </div>
            )}
          </div>
          <div>
            <div className=" font-bold tracking-tight mb-1">
              Número de seguridad <span className="text-red-400">*</span>
            </div>
            <input
              name="cc-csc"
              type="text"
              placeholder="****"
              required
              autoComplete="cc-csc"
              value={formik.values['cc-csc']}
              onChange={formik.handleChange}
              className="text-xs rounded p-4 w-full ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
            />
            {formik.errors['cc-csc'] && formik.touched['cc-csc'] && (
              <div className="text-xs text-red-400 p-2">
                {formik.errors['cc-csc']}
              </div>
            )}
          </div>
          <div>
            <div className="font-bold tracking-tight mb-1">
              Codigo postal <span className="text-red-400">*</span>
            </div>
            <input
              name="cc-zip"
              type="text"
              placeholder="00907..."
              required
              autoComplete="postal-code"
              value={formik.values['cc-zip']}
              onChange={formik.handleChange}
              className="text-xs rounded p-4 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
            />
            {formik.errors['cc-zip'] && formik.touched['cc-zip'] && (
              <div className="text-xs text-red-400 p-2">
                {formik.errors['cc-zip']}
              </div>
            )}
          </div>
          <div className="flex items-center space-x-2">
            <button
              type="button"
              onClick={() => {
                formik.resetForm();
                setOpen(false);
              }}
              className="flex items-center space-x-2 px-3 py-2 rounded font-semibold text-xs bg-gray-200 hover:bg-gray-300 hover:border-green-500 transition duration-500 ease-in-out outline-none focus:outline-none cursor-pointer"
            >
              <XIcon className="w-4 h-4" />
              <span>Cancelar</span>
            </button>
            <button
              type="submit"
              className="flex items-center space-x-2 px-3 py-2 rounded font-semibold text-xs text-white bg-black hover:bg-green-500 hover:border-green-500 transition duration-500 ease-in-out outline-none focus:outline-none cursor-pointer"
            >
              {formik.isSubmitting ? (
                <Loader color="bg-white" />
              ) : (
                <SaveIcon className="w-4 h-4" />
              )}
              <span>Guardar</span>
            </button>
          </div>
        </form>
      </div>
    </Transition>
  );
}

export default CreditCardForm;
