import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import * as Scroll from 'react-scroll';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { menuAtom } from '../../../../../../atoms/Atoms';

function Categories() {
  const menu = useRecoilValue(menuAtom);

  return (
    <div className="hidden md:block relative">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className="h-20 font-semibold px-4 space-x-4 flex items-center hover:text-green-500 outline-none focus:outline-none ring-0 focus:ring-0 transition duration-200 ease-in-out">
              <span>Categorias</span>
              <ChevronDownIcon
                className={`w-3 h-3 transition duration-500 easy-in-out ${
                  open ? 'transform rotate-180' : ''
                }`}
              />
            </Menu.Button>

            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="dark:text-gray-700 left-0 border border-gray-100 absolute h-56 w-56 no-scrollbar overflow-auto mt-1 bg-white divide-y divide-gray-100 rounded-xl shadow-lg outline-none focus:outline-none"
              >
                {menu?.sections &&
                  _.map(menu?.sections, (e) => (
                    <>
                      {e.items && e.items.length > 0 && (
                        <Menu.Item
                          key={e.departmentName}
                          as={Scroll.Link}
                          activeClass="text-green-500"
                          to={e.departmentName}
                          spy
                          smooth
                          offset={-80}
                          duration={500}
                          className="flex items-center text-xs normal-case font-semibold space-x-2 w-full px-4 py-3 hover:text-green-500 cursor-pointer transition duration-500 easy-in-out outline-none focus:outline-none ring-0 active:ring-0 focus:ring-0"
                        >
                          <ChevronRightIcon className="w-3 h-3 text-gray-300" />
                          <span>{e.departmentName}</span>
                        </Menu.Item>
                      )}
                    </>
                  ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

export default Categories;
