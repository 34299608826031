import React from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import is from 'is_js';
import { orderAtom } from '../../../../../atoms/Atoms';
import Item from './shared-components/Item';
import Total from '../total/Total';

function Items() {
  const order = useRecoilValue(orderAtom);

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
        <div className="tracking-tight font-extrabold text-6xl">Su Orden</div>
        <Total />
      </div>
      <div className="divide-y divide-white divide-opacity-10">
        {is.not.empty(order) &&
          _.map(order, (item, i) => <Item key={`item-${i}`} item={item} />)}
      </div>
    </div>
  );
}

export default Items;
