import React from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import { storeAtom } from '../../../../../../../../atoms/Atoms';

function BusinessHours() {
  const business = useRecoilValue(storeAtom);

  return (
    <div>
      <div className="text-2xl font-bold border-b pb-4 mb-4">Horarios</div>
      {_.map(business.locations[0].hours, (h) => (
        <div
          key={h.weekDay}
          className="flex justify-between text-sm text-gray-700 mb-2"
        >
          <div
            className={
              new Date().getDay() === h.weekDay ? 'text-green-500' : ''
            }
          >
            {h.weekDayName}
          </div>
          <div
            className={
              new Date().getDay() === h.weekDay ? 'text-green-500' : ''
            }
          >
            {h.openHour % 12 || 12}:00 am - {h.closeHour % 12 || 12}:00 pm
          </div>
        </div>
      ))}
    </div>
  );
}

export default BusinessHours;
