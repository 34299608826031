import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { keywordAtom } from '../../../atoms/Atoms';
import './Popup.css'; // Import your styles

function Popup({ onClose, photo, details, price, cta, search }) {
  const [keyword, setKeyword] = useRecoilState(keywordAtom);

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button type="button" className="close-button" onClick={onClose}>
          X
        </button>
        {photo !== null ?
          <div className="image-container">
            <img src={photo} alt="Product" className="popup-image" />
          </div>
        : null}
        <p>{details}</p>
        <p className="popup-price"><span style={{ color: 'red' }}>{price}</span></p>
        <button
          type="button"
          className="add-to-cart-button"
          onClick={() => {
            onClose();
            if (search !== null) {
              setKeyword(search);
            }
          }}
        >
          {cta}
        </button>
      </div>
    </div>
  );
}

export default Popup;
