import React from 'react';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { addonsAtom } from '../../../../../../atoms/Atoms';

function Toppings({ items }) {
  const [addons, setAddons] = useRecoilState(addonsAtom);

  return (
    <div>
      {_.map(items, (a) => (
        <div key={a.id}>
          <div className="uppercase text-sm font-medium space-x-1 mb-2 pb-2 border-b">
            {a.name}
          </div>
          {_.map(a.items, (i) => (
            <div key={i.id} className="mb-4">
              <div className="text-sm text-gray-700 flex items-center space-x-2">
                <input
                  type="checkbox"
                  className="border-gray-200 border-2 text-black focus:border-gray-300 focus:ring-black h-5 w-5 cursor-pointer"
                  onChange={() => {
                    const exist = _.includes(addons, i);
                    if (!exist) {
                      setAddons([...addons, i]);
                    } else {
                      const array = _.filter(addons, (add) => add !== i);
                      setAddons(array);
                    }
                  }}
                />
                <div>
                  <div>{i.name}</div>
                  <div className="text-gray-400 text-xs">
                    +${i.price.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Toppings;
