import React from 'react';
import { CreditCardIcon } from '@heroicons/react/outline';
import CreditCardForm from './shared-components/CreditCardForm';
import PaymentOptions from './shared-components/PaymentOptions';

function Payment() {
  return (
    <div className="p-4">
      <div className="border">
        <div className="p-4 flex items-center bg-gray-100 space-x-4">
          <CreditCardIcon className="h-12 w-12" />
          <div className="text-lg font-extrabold tracking-tight mb-2 bg-gray-100">
            <div>Método de Pago</div>
            <div className="text-xs text-gray-500 font-normal tracking-tight">
              Favor de seleccionar el método de pago o registrar una nueva
              tarjeta de credito.
            </div>
          </div>
        </div>
        <div className="divide-y">
          <PaymentOptions />
          <CreditCardForm />
        </div>
      </div>
    </div>
  );
}

export default Payment;
