import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { profileAtom, userAtom } from '../../../../../../atoms/Atoms';

function Avatar() {
  const user = useRecoilValue(userAtom);
  const [profile, setProfile] = useRecoilState(profileAtom);

  const handleChange = async (e) => {
    e.preventDefault();

    if (e.target.files.length) {
      setProfile({
        ...profile,
        avatar: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  return (
    <div className="p-4 space-y-2">
      <div className="text-xl font-extrabold tracking-tight">Imagen</div>
      <div className="text-sm">
        Añade o edita tu imagen de perfil. Solo es permitido formato de imagen
        jpeg o png. Para mejores resultados, utilice una imagen cuadrada.
      </div>
      <div className="relative flex-shrink-0 bg-gray-300 rounded-full h-20 w-20 hover:opacity-50 transition duration-500 ease-in-out">
        <label htmlFor="upload-button">
          <img
            src={user?.avatar}
            alt={user?.completeName}
            className="h-full w-full cursor-pointer rounded-full"
          />
        </label>
        <input
          type="file"
          id="upload-button"
          className="hidden"
          accept="image/png, image/jpeg"
          onChange={handleChange}
        />
      </div>
      <div className="text-xs text-gray-500">
        Haga click en la imagen para cambiar su imagen de perfil.
      </div>
    </div>
  );
}

export default Avatar;
