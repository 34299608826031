import React, { useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SearchIcon, XIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useClickAway } from 'react-use';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { locationAtom, tokenAtom } from '../../../../../../atoms/Atoms';
import Button from '../../../../../shared-components/buttons/Button';
import Form from '../../../../../shared-components/form/Form';
import api from '../../../../../../api/api';
import Input from '../../../../../shared-components/form/Input';
import Textarea from '../../../../../shared-components/form/Textarea';
import envConfig from '../../../../../../envConfig';

function Modal(props) {
  // const { google } = props;
  const ref = useRef();
  const suggestionsRef = useRef();
  const [state, setState] = useState({
    address: '',
    draggable: true,
    lat: 18.3977264,
    lng: -66.1040551,
    // google,
    suggestions: [],
  });
  const [locations, setLocations] = useRecoilState(locationAtom);
  const token = useRecoilValue(tokenAtom);

  const containerStyle = {
    width: '100%',
    height: '400px'
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: envConfig.REACT_APP_GEO_API_KEY,
    language: 'es-US',
    libraries: ['places']
  });

  const closeModal = () => {
    setLocations({ ...locations, add_new_modal: false });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      latitude: '',
      longitude: '',
      city: '',
      zipcode: '',
      instructions: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Campo requerido'),
      address: Yup.string().required('Campo requerido'),
      latitude: Yup.number()
        .typeError('Solo numeros en este campo')
        .required('Campo requerido'),
      longitude: Yup.number()
        .typeError('Solo numeros en este campo')
        .required('Campo requerido'),
      city: Yup.string().required('Campo requerido'),
      zipcode: Yup.string().required('Campo requerido'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      api
        .post(
          'user/locations',
          {
            AdditionalInstructions: values.instructions,
            AddressLine: values.address,
            City: values.city,
            Name: values.name,
            Lat: values.latitude,
            Lon: values.longitude,
            Zipcode: values.zipcode,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
          } else {
            setLocations({
              ...locations,
              locations: [...locations.locations, response.data.data],
              add_new_modal: false,
            });
          }
          formik.resetForm();
          setSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
        });
    },
  });

  const apiHasLoaded = (map) => {
    const placesService = new window.google.maps.places.PlacesService(map);
    const autocompleteService = new window.google.maps.places.AutocompleteService();
    const bounds = new window.google.maps.LatLngBounds({
      lat: state.lat,
      lng: state.lng,
    });
    map.fitBounds(bounds);
    setState({
      ...state,
      mapApiLoaded: true,
      mapInstance: map,
      placesService,
      autocompleteService
    });
  };

  const fetchPredictions = () => {
    if (state.address.length && state.mapApiLoaded) {
      state.autocompleteService.getPlacePredictions(
        {
          input: state.address,
          componentRestrictions: { country: 'pr' },
        },
        (predictions, status) => {
          if (status !== 'OK') {
            return;
          }

          setState({
            ...state,
            suggestions: predictions,
          });
        }
      );
    } else {
      setState({
        ...state,
        suggestions: [],
      });
    }
  };

  const getDetails = (placeId, lat, lng) => {
    state.placesService.getDetails(
      {
        placeId,
        fields: [
          'name',
          'address_components',
          'formatted_address',
          'geometry',
          'place_id',
        ],
      },
      (result, status) => {
        if (status === 'OK') {
          state.mapInstance.fitBounds(result.geometry.viewport);

          _.each(result.address_components, (component) => {
            switch (component.types[0]) {
              case 'postal_code':
                return formik.setFieldValue('zipcode', component.long_name);
              case 'locality':
                return formik.setFieldValue('city', component.long_name);
              default:
                return null;
            }
          });

          formik.setFieldValue('name', result.name);
          formik.setFieldValue('address', result.formatted_address);
          formik.setFieldValue('latitude', result.geometry.location.lat());
          formik.setFieldValue('longitude', result.geometry.location.lng());

          if (lat && lng) {
            setState({
              ...state,
              draggable: true,
              address: '',
              suggestions: [],
              lat,
              lng,
            });
          } else {
            setState({
              ...state,
              draggable: true,
              address: '',
              suggestions: [],
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
            });
          }
        }
      }
    );
  };

  useEffect(() => {
    fetchPredictions();
  }, [state.address]);

  useClickAway(suggestionsRef, () => {
    setState({
      ...state,
      address: '',
      suggestions: [],
    });
  });

  return (
    <Transition show={locations.add_new_modal} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="flex justify-center items-center h-screen px-4 md:px-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="bg-gray-900 absolute inset-0 backdrop-filter backdrop-blur-sm bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-500"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            afterLeave={() => {
              setLocations({ ...locations, profileCurrent: null });
            }}
          >
            <div className="flex flex-col w-full h-5/6 w-full max-w-lg my-8 divide-y divide-gray-200 transition-all transform bg-white shadow-xl duration-500 easy-in-out">
              {/* HEADER */}
              <Dialog.Title
                as="h3"
                className="flex items-center space-x-2 px-4 justify-between text-lg font-medium h-20"
              >
                {/* HEADER TITLE */}
                <div>Añadir Localidad</div>
                {/* HEADER ACTIONS */}
                <div className="flex items-center space-x-2">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="flex items-center justify-center text-gray-500 hover:text-gray-600 ring-0 focus:ring-0 bg-gray-100 w-10 h-10 rounded-full outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                  >
                    <XIcon className="w-5 h-5" />
                  </button>
                </div>
              </Dialog.Title>
              <div className="flex flex-col flex-1 overflow-y-auto no-scrollbar p-4">
                <div className="text-xs mb-4 text-gray-400">
                  Utiliza el &quot;search&quot; para encontrar tu dirección
                  física y completar los campos del formulario de manera
                  automática. Además, puedes mover el marcador en el mapa si
                  necesitas mas precisión.
                </div>
                <div>
                  <div className="flex flex-col text-xs py-4">
                    <div className="flex space-x-2">
                      <div className="flex flex-shrink-0 items-center justify-center bg-gradient-to-r from-green-400 to-blue-500 w-8 h-8 font-bold ordinal slashed-zero tabular-nums text-white rounded-full">
                        01
                      </div>
                      <div>
                        <div className="font-semibold">Buscar dirección</div>
                        <div className="text-gray-500 mb-4">
                          Escribe tu dirección física para completar los campos
                          del formulario de manera automática.
                        </div>
                      </div>
                    </div>
                    <div className="h-12 group relative w-full">
                      <div className="flex items-center h-full text-sm px-4 border border-gray-200 bg-gray-100 rounded">
                        <SearchIcon className="w-4 h-4" />
                        <input
                          type="text"
                          className="pl-2 bg-transparent text-xs border-0 w-full leading-tight outline-none focus:outline-none ring-0 focus:ring-0 px-0"
                          value={state.address}
                          placeholder="Buscar dirección..."
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                          onChange={(e) => {
                            setState({ ...state, address: e.target.value });
                          }}
                        />
                      </div>
                      <Transition
                        appear={state.suggestions.length > 0}
                        show={state.suggestions.length > 0}
                        enter="transform transition duration-500"
                        enterFrom="opacity-0 -translate-y-50"
                        enterTo="opacity-100 -translate-y-0"
                        leave="transform transition duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="absolute z-10 mt-2 w-full divide-y divide-gray-100 shadow-xl bg-white px-4"
                      >
                        {_.map(state.suggestions, (suggestion) => (
                          <button
                            key={suggestion.place_id}
                            type="button"
                            onClick={() => getDetails(suggestion.place_id)}
                            className="text-gray-400 hover:text-gray-500 text-xs text-left block w-full py-4 px-4 truncate ring-0 focus:ring-0 outline-none focus:outline-none transition duration-200 ease-linear cursor-pointer"
                          >
                            {suggestion.description}
                          </button>
                        ))}
                      </Transition>
                    </div>
                  </div>
                  <div className="flex flex-col text-xs py-4">
                    <div className="flex space-x-2">
                      <div className="flex flex-shrink-0 items-center justify-center bg-gradient-to-r from-green-400 to-blue-500 w-8 h-8 font-bold ordinal slashed-zero tabular-nums text-white rounded-full">
                        02
                      </div>
                      <div>
                        <div className="font-semibold">
                          Mover marcador (opcional)
                        </div>
                        <div className="text-gray-500 mb-4">
                          Si es necesario, mueve el marcador en el mapa para
                          ajustar la localización.
                        </div>
                      </div>
                    </div>
                    <div className="h-96 relative">
                      {isLoaded && (
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          zoomControl
                          draggable
                          center={{
                            lat: state.lat,
                            lng: state.lng,
                          }}
                          zoom={10}
                          onLoad={apiHasLoaded}
                        >
                          <Marker
                            draggable
                            position={{ lat: state.lat, lng: state.lng }}
                            onDragend={(a, b, coords) => {
                              const lat = coords.latLng.lat();
                              const lon = coords.latLng.lng();
                              formik.setFieldValue('latitude', lat);
                              formik.setFieldValue('longitude', lon);
                              console.log('latitude', lat);
                              console.log('longitude', lon);
                              setState({
                                  ...state,
                                  lat,
                                  lng: lon,
                                });
                            }}
                          />
                        </GoogleMap>
                      )}
                      {/* <Map
                        style={{
                          position: 'absolute',
                        }}
                        google={state.google}
                        onReady={apiHasLoaded}
                        zoomControl
                        initialCenter={{
                          lat: state.lat,
                          lng: state.lng,
                        }}
                      >
                        <Marker
                          draggable
                          position={{ lat: state.lat, lng: state.lng }}
                          onDragend={(a, b, coords) => {
                            const lat = coords.latLng.lat();
                            const lon = coords.latLng.lng();
                            formik.setFieldValue('latitude', lat);
                            formik.setFieldValue('longitude', lon);
                            setState({
                                ...state,
                                lat,
                                lng: lon,
                              });
                          }}
                        />
                      </Map> */}
                    </div>
                  </div>
                  <div className="flex flex-col text-xs py-4">
                    <div className="flex space-x-2">
                      <div className="flex flex-shrink-0 items-center justify-center bg-gradient-to-r from-green-400 to-blue-500 w-8 h-8 font-bold ordinal slashed-zero tabular-nums text-white rounded-full">
                        03
                      </div>
                      <div>
                        <div className="font-semibold">
                          Editar los campos (opcional)
                        </div>
                        <div className="text-gray-500">
                          Si es necesario, puedes editar los campos del
                          formulario exceptuando la latitud y la longitud.
                        </div>
                      </div>
                    </div>
                    <Form>
                      <Input
                        name="name"
                        label="Nombre"
                        type="text"
                        required
                        placeholder="Casa..."
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        className="col-span-1 md:col-span-2"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        error={
                          formik.errors.name &&
                          formik.touched.name &&
                          formik.errors.name
                        }
                      />
                      <Input
                        name="address"
                        label="Dirección"
                        type="text"
                        required
                        placeholder="717 Calle La Paz"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        className="col-span-1 md:col-span-2"
                        onChange={formik.handleChange}
                        value={formik.values.address}
                        error={
                          formik.errors.address &&
                          formik.touched.address &&
                          formik.errors.address
                        }
                      />
                      <Input
                        name="city"
                        label="Municipio"
                        type="text"
                        required
                        placeholder="San Juan"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        onChange={formik.handleChange}
                        value={formik.values.city}
                        error={
                          formik.errors.city &&
                          formik.touched.city &&
                          formik.errors.city
                        }
                      />
                      <Input
                        name="zipcode"
                        label="Codigo Postal"
                        type="text"
                        required
                        placeholder="00907"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        onChange={formik.handleChange}
                        value={formik.values.zipcode}
                        error={
                          formik.errors.zipcode &&
                          formik.touched.zipcode &&
                          formik.errors.zipcode
                        }
                      />
                      <Input
                        name="latitude"
                        label="Latitud"
                        type="text"
                        disabled
                        required
                        placeholder="18.3977264"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        onChange={formik.handleChange}
                        value={formik.values.latitude}
                        error={
                          formik.errors.latitude &&
                          formik.touched.latitude &&
                          formik.errors.latitude
                        }
                      />
                      <Input
                        name="longitude"
                        label="Longitud"
                        type="text"
                        disabled
                        required
                        placeholder="-66.1040551"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        onChange={formik.handleChange}
                        value={formik.values.longitude}
                        error={
                          formik.errors.longitude &&
                          formik.touched.longitude &&
                          formik.errors.longitude
                        }
                      />
                      <Textarea
                        name="instructions"
                        label="Instrucciones"
                        className="col-span-1 md:col-span-2"
                        rows={4}
                        onChange={formik.handleChange}
                        value={formik.values.instructions}
                      />
                    </Form>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end space-x-2 px-4 bg-gray-100 h-20">
                <Button
                  className="w-20 bg-gray-700 hover:bg-gray-600"
                  text="Cerrar"
                  textColor="text-white"
                  onClick={closeModal}
                />
                <Button
                  className="w-20 bg-green-400 hover:bg-green-500"
                  text="Guardar"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  loaderColor="bg-white"
                  textColor="text-white"
                  onClick={() => formik.submitForm()}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
