import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useLocalStorage } from 'react-use';
import moment from 'moment';
import api from '../../../../../../api/api';
import { tokenAtom, userAtom, notificationsAtom } from '../../../../../../atoms/Atoms';
import Input from '../../../../../shared-components/form/Input';
import Button from '../../../../../shared-components/buttons/Button';
import envConfig from '../../../../../../envConfig';

function AccountValidation() {
  const [user, setUser] = useRecoilState(userAtom);
  const token = useRecoilValue(tokenAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [loadingEmailValidation, setLoadingEmailValidation] = useState(false);
  const [loadingPhoneValidation, setLoadingPhoneValidation] = useState(false);
  const [emailValidationCode, setEmailValidationCode] = useState('');
  const [phoneValidationCode, setPhoneValidationCode] = useState('');
  const [lastCodeSent, setLastCodeSent] = useLocalStorage('ant-codes');

  function checkValidDate(dateToCheck) {
    if (!dateToCheck) {
      return true;
    }
    const totalMinutes = moment().diff(dateToCheck, 'minutes');
    return totalMinutes > 5;
  }

  function sendEmailCode() {
    if (!checkValidDate(lastCodeSent?.email)) {
      setNotifications({ ...notifications, error: true, description: 'Por favor esperar un total de 5 minutos antes de enviar otro código' });
    } else {
      setLoadingEmailValidation(true);
      api.post('validate/email/request', null)
      .then((response) => {
        setLoadingEmailValidation(false);
        if (response.data.success) {
          setLastCodeSent({
            ...lastCodeSent,
            email: moment()
          });
          setNotifications({ ...notifications, message: true, title: '¡Éxito!', description: response.data.message });
        } else {
          setNotifications({ ...notifications, error: true, description: response.data.error });
        }
      })
      .catch((error) => {
        setLoadingEmailValidation(false);
        setNotifications({ ...notifications, error: true, description: error.message });
      });
    }
  }

  function sendPhoneCode() {
    if (!checkValidDate(lastCodeSent?.phone)) {
      setNotifications({ ...notifications, error: true, description: 'Por favor esperar un total de 5 minutos antes de enviar otro código' });
    } else {
      setLoadingPhoneValidation(true);
      api.post('validate/phone/request', null)
      .then((response) => {
        setLoadingPhoneValidation(false);
        if (response.data.success) {
          setLastCodeSent({
            ...lastCodeSent,
            phone: moment()
          });
          setNotifications({ ...notifications, message: true, title: '¡Éxito!', description: response.data.message });
        } else {
          setNotifications({ ...notifications, error: true, description: response.data.error });
        }
      })
      .catch((error) => {
        setLoadingPhoneValidation(false);
        setNotifications({ ...notifications, error: true, description: error.message });
      });
    }
  }

  function checkAccountValidation() {
    api.get('currentstate')
    .then((response) => {
      let isValid = true;
      if (envConfig.ValidateUserEmail && !response.data.data.profile.emailConfirmed) {
        isValid = false;
      }
      if (envConfig.ValidateUserPhone && !response.data.data.profile.phoneNumberConfirmed) {
        isValid = false;
      }
      setUser({
        ...response.data.data.profile,
        isValidated: isValid
      });
      localStorage.setItem(
        'ant-user',
        JSON.stringify({
          ...response.data.data.profile,
          isValidated: isValid
        })
      );
    }).catch((err) => {
      console.log('error getting current state');
    });
  }

  function validateEmailCode() {
    setLoadingEmailValidation(true);
    api.post('user/email/validation', { Token: emailValidationCode })
    .then((response) => {
        setLoadingEmailValidation(false);
      if (response.data.success) {
        setNotifications({ ...notifications, message: true, title: '¡Éxito!', description: response.data.message });
        checkAccountValidation();
      } else {
        setNotifications({ ...notifications, error: true, description: response.data.error });
      }
    })
    .catch((error) => {
        setLoadingEmailValidation(false);
      setNotifications({ ...notifications, error: true, description: error.message });
    });
  }

  function validatePhoneCode() {
    setLoadingPhoneValidation(true);
    api.post('user/phone/validation', { Token: phoneValidationCode })
    .then((response) => {
        setLoadingPhoneValidation(false);
      if (response.data.success) {
        setNotifications({ ...notifications, message: true, title: '¡Éxito!', description: response.data.message });
        checkAccountValidation();
      } else {
        setNotifications({ ...notifications, error: true, description: response.data.error });
      }
    })
    .catch((error) => {
        setLoadingPhoneValidation(false);
      setNotifications({ ...notifications, error: true, description: error.message });
    });
  }

  useEffect(() => {
    if (lastCodeSent === undefined) {
      setLastCodeSent({
        email: moment().add(-1, 'days'),
        phone: moment().add(-1, 'days')
      });
    }
  }, [lastCodeSent]);

  return (
    <>
      {!user?.isValidated && (
        <div className="p-4 space-y-2">
          <div className="text-xl font-extrabold tracking-tight">Validar cuenta</div>
          <div className="text-sm">
            Termine de validar su cuenta para poder utilizar todas las funciones.
          </div>
          {envConfig.ValidateUserEmail === 'true' && !user?.emailConfirmed && (
            <div className="flex">
              <Input
                label="Validar Email"
                type="text"
                value={emailValidationCode}
                onChange={(obj) => setEmailValidationCode(obj.target.value)}
                placeholder="Introduzca el código"
                name="emailCode"
                id="emailcode"
              />
              <Button
                text="Enviar código"
                textColor="text-gray-400"
                disabled={loadingEmailValidation}
                onClick={(e) => sendEmailCode()}
                className="bg-gray-200 hover:bg-gray-300 w-20 mx-2 py-6 mt-5"
              />
              <Button
                text="Validar"
                textColor="text-white"
                disabled={loadingEmailValidation || emailValidationCode.length === 0}
                onClick={(e) => validateEmailCode()}
                loaderColor="bg-emerald-300"
                className="bg-emerald-500 hover:bg-emerald-400 w-20 py-6 mt-5"
              />
            </div>
          )}
          {envConfig.ValidateUserPhone === 'true' && !user?.phoneNumberConfirmed && (
            <div className="flex">
              <Input
                label="Validar Teléfono"
                type="text"
                value={phoneValidationCode}
                onChange={(obj) => setPhoneValidationCode(obj.target.value)}
                placeholder="Introduzca el código"
                name="phoneCode"
                id="phonecode"
              />
              <Button
                textColor="text-gray-400"
                text="Enviar código"
                disabled={loadingPhoneValidation}
                onClick={(e) => sendPhoneCode()}
                className="bg-gray-200 hover:bg-gray-300 w-20 mx-2 py-6 mt-5"
              />
              <Button
                text="Validar"
                textColor="text-white"
                disabled={loadingPhoneValidation || phoneValidationCode.length === 0}
                onClick={(e) => validatePhoneCode()}
                loaderColor="bg-emerald-300"
                className="bg-emerald-500 hover:bg-emerald-400 w-20 py-6 mt-5"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default AccountValidation;
