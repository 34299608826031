import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useLocalStorage, useMount, useLocation } from 'react-use';
import { useRecoilState, useSetRecoilState } from 'recoil';
import LogRocket from 'logrocket';
import ReactPixel from 'react-facebook-pixel';
import {
  storesAtom,
  userAtom,
  orderAtom,
  tokenAtom,
  storeAtom,
  stateAtom,
  serviceAtom,
  locationAtom,
} from '../atoms/Atoms';
import Order from './pages/order/Order';
import Menu from './pages/menu/Menu';
import Home from './pages/home/Home';
import SignIn from './pages/signin/SignIn';
import SignUp from './pages/signup/SignUp';
import Password from './pages/password/Password';
import NotFound from './pages/404/404';
import Notifications from './shared-components/notifications/Notifications';
import Sidebar from './shared-components/sidebar/Sidebar';
import Reset from './pages/reset/Reset';
import OrderDetails from './pages/order-details/details/OrderDetails';
import Profile from './pages/profile/Profile';
import api from '../api/api';
import ProtectedRoute from './shared-components/ProtectedRoute';
import Privacy from './pages/privacy/Privacy';
import Terms from './pages/terms/Terms';
import envConfig from '../envConfig';

if (envConfig.UseLogRocket === 'true') {
  LogRocket.init('uy6uyx/antoninos-pizza');
}

function App() {
  const location = useLocation();

  // Find local storage on mount
  const [token] = useLocalStorage('ant-token');
  const [store] = useLocalStorage('ant-store');
  const [user] = useLocalStorage('ant-user');
  const [state] = useLocalStorage('ant-state');
  const [order] = useLocalStorage('ant-cart');
  const [service] = useLocalStorage('ant-service');
  const [localLocation] = useLocalStorage('ant-location');
  // Set global state (Atoms)
  const [userA, setUser] = useRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setOrder = useSetRecoilState(orderAtom);
  const setState = useSetRecoilState(stateAtom);
  const setService = useSetRecoilState(serviceAtom);
  const setStore = useSetRecoilState(storeAtom);
  const setStores = useSetRecoilState(storesAtom);
  const [locations, setLocations] = useRecoilState(locationAtom);
  const [fetchedState, setFetchedState] = useState(false);

  if (envConfig.FacebookPixelId) {
    const advancedMatching = { em: user ? user.email : '' };
    const options = {
      autoConfig: true,
      debug: false
    };
    ReactPixel.init(envConfig.FacebookPixelId, advancedMatching, options);
  }

  function getCurrentUserState() {
    if (userA && !fetchedState) {
      setFetchedState(true);
      api.get('currentstate')
        .then((response) => {
          let isValid = true;
          if (envConfig.ValidateUserEmail === 'true' && !response.data.data.profile.emailConfirmed) {
            isValid = false;
          }
          if (envConfig.ValidateUserPhone === 'true' && !response.data.data.profile.phoneNumberConfirmed) {
            isValid = false;
          }
          setUser({
            ...response.data.data.profile,
            isValidated: isValid
          });
        }).catch((err) => {
          console.log('error getting current state');
        });
    }
  }

  useEffect(() => {
    getCurrentUserState();
  }, [userA]);

  useEffect(() => {
    if (envConfig.FacebookPixelId) {
      ReactPixel.pageView(); // For tracking page view
    }
    // if (envConfig.GoogleAnalyticsTrackingId) {
    //   ReactGA.pageview(location.pathname);
    // }
  }, [location]);

  // Send local storage to global state on mount
  useMount(() => {
    if (token) {
      setToken(token);
    }

    if (state) {
      setState(state);
    }

    if (store) {
      setStore(store);
    }

    if (user) {
      setUser(user);
    }

    if (order) {
      setOrder(order);
    }

    if (service) {
      setService(service);
    }

    if (localLocation) {
      setLocations({ ...locations, location: localLocation });
    }

    api
      .get('businesses')
      .then((response) => {
        setStores(response.data.data.results);
      })
      .catch((error) => console.log(error));
  });

  return (
    <Router>
      <Notifications />
      <Sidebar />
      <div className="text-gray-800 font-sans antialiased">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/menu">
            <Menu />
          </Route>
          <Route path="/order">
            <Order />
          </Route>
          <Route path="/signin">
            <SignIn />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/password">
            <Password />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/reset/:id/:token">
            <Reset />
          </Route>
          <Route path="/order-details/:code">
            <OrderDetails />
          </Route>
          <ProtectedRoute
            path="/profile/:section?/:order?"
            component={Profile}
          />
          <Route path="*" exact>
            <NotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
