import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import _ from 'lodash';
import is from 'is_js';
import moment from 'moment-timezone';
import { ChevronDownIcon, ClockIcon } from '@heroicons/react/outline';
import { Menu, Transition } from '@headlessui/react';
import {
  envAtom,
  hourAtom,
  locationAtom,
  notificationsAtom,
  serviceAtom,
  storeAtom,
  tokenAtom,
} from '../../../../../atoms/Atoms';
import { checkoutSelector } from '../../../../../atoms/Selectors';
import api from '../../../../../api/api';
import Loader from '../../../../shared-components/loader/Loader';

function Hours() {
  const token = useRecoilValue(tokenAtom);
  const checkout = useRecoilValue(checkoutSelector);
  const store = useRecoilValue(storeAtom);
  const service = useRecoilValue(serviceAtom);
  const env = useRecoilValue(envAtom);
  const location = useRecoilValue(locationAtom);
  const [hours, setHours] = useState(null);
  const [hour, setHour] = useRecoilState(hourAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  useEffect(() => {
    if (is.not.null(token) && is.not.null(store)) {
      api
        .post(
          `business/hours/${store.locations[0].id}`,
          {
            OrderTotal: checkout.subtotal,
            ItemCount: checkout.itemCount,
            OrderType: service,
            DeliveryLocationId: service === 1 ? location.location.id : null,
            BusinessItemIds: _.map(checkout.order, (e) => e.id)
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            setNotifications({
              ...notifications,
              token: response.data.error,
            });
          } else {
            setHours(response.data.data.results[0].times);
          }
        })
        .catch((err) => {
          setNotifications({ ...notifications, token: err.message });
        });
    }
  }, [token, store]);

  return (
    <div className="p-4">
      <div className="border">
        <div className="p-4 flex items-center bg-gray-100 space-x-4">
          <div>
            <div className="text-lg font-extrabold tracking-tight">Hora</div>
            <div className={`${service === 1 ? 'block' : 'hidden'} text-xs text-gray-500 font-normal tracking-tight`}>
              Favor de seleccionar hora en la que desea recibir su orden.
            </div>
            <div className={`${service === 1 ? 'hidden' : 'block'} text-xs text-gray-500 font-normal tracking-tight`}>
              Favor de seleccionar hora en la que desea recoger su orden.
            </div>
          </div>
        </div>
        <div className="h-20">
          <Transition
            show={!hours}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="flex flex-col items-center justify-center text-xs h-20">
              <Loader color="bg-gray-600" />
            </div>
          </Transition>
          <Transition
            show={!!hours}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="flex flex-col justify-center px-4 h-20">
              <div className="text-xs flex flex-col relative w-full">
                <Menu>
                  {({ open }) => (
                    <>
                      <Menu.Button className="border text-xs flex items-center justify-between hover:text-gray-600 font-semibold ring-0 px-4 h-14 rounded outline-none focus:outline-none transition duration-500 ease-in-out">
                        <span>
                          {(hour &&
                            moment(hour.endTime)
                              .tz(env.timezone)
                              .format('h:mm:ss a')) ||
                            'Seleccionar hora disponbible...'}
                        </span>
                        <ChevronDownIcon
                          className={`h-4 w-4 transition duration-500 easy-in-out ${
                            open ? 'transform rotate-180' : ''
                          }`}
                        />
                      </Menu.Button>
                      <Transition
                        show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="bg-white mt-1 rounded absolute max-h-64 right-0 w-full border shadow-xl overflow-y-auto no-scrollbar origin-top-right divide-y divide-gray-100 outline-none focus:outline-none">
                          {hours &&
                            _.map(hours, (e) => {
                              const today = moment();
                              const date = moment(e.endTime);
                              const same = moment(today).isSame(date, 'day');

                              return (
                                <Menu.Item key={e.time}>
                                  {({ active }) => (
                                    <button
                                      type="button"
                                      disabled={is.any.falsy(
                                        same,
                                        e.isAvailable
                                      )}
                                      className={`font-medium flex flex-row items-center justify-between w-full px-4 py-4 text-xs leading-5 text-left transition duration-500 easy-in-out disabled:opacity-40 disabled:cursor-not-allowed ${
                                        active && 'bg-gray-100'
                                      }`}
                                      onClick={() => setHour(e)}
                                    >
                                      <div className="flex items-center space-x-2">
                                        <ClockIcon className="w-3 h-3" />
                                        <span>
                                          {moment(e.endTime)
                                            .tz(env.timezone)
                                            .format('h:mm:ss a')}
                                        </span>
                                      </div>
                                      {is.any.falsy(same, e.isAvailable) && (
                                        <span className="text-xs text-gray-400">
                                          (No Disponible)
                                        </span>
                                      )}
                                    </button>
                                  )}
                                </Menu.Item>
                              );
                            })}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
}

export default Hours;
