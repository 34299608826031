import React from 'react';
import { ChevronRightIcon, LogoutIcon } from '@heroicons/react/outline';
import { Menu, Transition } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { tokenAtom, userAtom } from '../../atoms/Atoms';

function Dropdown({ reverse }) {
  const [user, setUser] = useRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);

  return (
    <Menu as="div" className="relative hidden md:inline-block text-left">
      <Menu.Button
        className={`${
          reverse && 'flex-row-reverse'
        } group flex items-center text-gray-100 hover:bg-black hover:bg-opacity-20 p-4 rounded-xl transition duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none cursor-pointer`}
      >
        <div className={`${reverse ? 'text-left' : 'text-right'}`}>
          <div className="font-semibold text-xs">Hola, {user?.firstName}</div>
          <div className="text-xs">{user?.email}</div>
        </div>
        <div
          className={`${reverse ? 'mr-2' : 'ml-2'} bg-gray-700 rounded-full`}
        >
          <img
            src={user?.avatar}
            alt="Avatar"
            className="flex flex-shrink-0 h-10 w-10 rounded-full"
          />
        </div>
      </Menu.Button>
      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="absolute z-10 w-56 bg-cyan-500 text-gray-300 mt-2 rounded-2xl shadow-lg"
      >
        <Menu.Items className="bg-black text-xs font-medium border border-gray-800 bg-opacity-90 divide-y divide-gray-800 rounded-xl ring-0 focus:ring-0 outline-none focus:outline-none">
          <Menu.Item
            as={NavLink}
            to="/profile/orders"
            className="group hover:text-gray-500 flex items-center p-4 w-full hover:bg-black hover:bg-opacity-20 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out"
          >
            <div className="flex-1">Pedidos</div>
            <ChevronRightIcon className="w-3 h-3" />
          </Menu.Item>
          <Menu.Item
            as={NavLink}
            to="/profile/settings"
            className="group hover:text-gray-500 flex items-center p-4 w-full hover:bg-black hover:bg-opacity-20 ring-0 focus:ring-0 outline-none focus:outline-none duration-500 ease-in-out"
          >
            <div className="flex-1">Configuración</div>
            <ChevronRightIcon className="w-3 h-3" />
          </Menu.Item>
          <Menu.Item
            as={NavLink}
            to="/profile/loyalty"
            className="group hover:text-gray-500 flex items-center p-4 w-full hover:bg-black hover:bg-opacity-20 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out"
          >
            <div className="flex-1">Lealtad</div>
            <ChevronRightIcon className="w-3 h-3" />
          </Menu.Item>
          <Menu.Item
            as={NavLink}
            to="/profile/password"
            className="group hover:text-gray-500 flex items-center p-4 w-full hover:bg-black hover:bg-opacity-20 ring-0 focus:ring-0 outline-none focus:outline-none duration-500 ease-in-out"
          >
            <div className="flex-1">Contraseña</div>
            <ChevronRightIcon className="w-3 h-3" />
          </Menu.Item>
          <Menu.Item
            as={NavLink}
            to="/profile/payment"
            className="group hover:text-gray-500 flex items-center p-4 w-full hover:bg-black hover:bg-opacity-20 ring-0 focus:ring-0 outline-none focus:outline-none duration-500 ease-in-out"
          >
            <div className="flex-1">Métodos de Pago</div>
            <ChevronRightIcon className="w-3 h-3" />
          </Menu.Item>
          <Menu.Item
            as={NavLink}
            to="/profile/locations"
            className="group hover:text-gray-500 flex items-center p-4 w-full hover:bg-black hover:bg-opacity-20 ring-0 focus:ring-0 outline-none focus:outline-none duration-500 ease-in-out"
          >
            <div className="flex-1">Mis Localidades</div>
            <ChevronRightIcon className="w-3 h-3" />
          </Menu.Item>
          <Menu.Item
            as="button"
            type="button"
            onClick={() => {
              setUser(null);
              setToken(null);
              localStorage.removeItem('ant-token');
              localStorage.removeItem('ant-user');
              localStorage.removeItem('ant-state');
              localStorage.removeItem('ant-card');
            }}
            className="group text-rose-400 hover:text-rose-500 font-medium flex items-center p-4 w-full hover:bg-black hover:bg-opacity-20 ring-0 focus:ring-0 outline-none focus:outline-none duration-500 ease-in-out"
          >
            <div className="flex-1 text-left">Cerrar Sesión</div>
            <LogoutIcon className="w-3 h-3" />
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default Dropdown;
