import { Transition } from '@headlessui/react';
import { BellIcon, CreditCardIcon } from '@heroicons/react/outline';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useRecoilState, useRecoilValue } from 'recoil';
import _ from 'lodash';
import api from '../../../../../api/api';
import { paymentMethodsAtom, tokenAtom, userAtom } from '../../../../../atoms/Atoms';
import Button from '../../../../shared-components/buttons/Button';
import Form from '../../../../shared-components/form/Form';
import Input from '../../../../shared-components/form/Input';
import CleaveInput from '../../../../shared-components/form/CleaveInput';
import amex from '../../../../../assets/images/cc/amex.svg';
import visa from '../../../../../assets/images/cc/visa.svg';
import diners from '../../../../../assets/images/cc/diners.svg';
import mastercard from '../../../../../assets/images/cc/mastercard.svg';
import discover from '../../../../../assets/images/cc/discover.svg';
import Alert from './shared-components/Alert';
import Modal from './shared-components/Modal';

function Payment() {
  const token = useRecoilValue(tokenAtom);
  const user = useRecoilValue(userAtom);
  const [payments, setPayments] = useRecoilState(paymentMethodsAtom);

  const formik = useFormik({
    initialValues: {
      'cc-type': '',
      'cc-name': '',
      'cc-number': '',
      'cc-exp': '',
      'cc-csc': '',
      'cc-zip': '',
    },
    validationSchema: Yup.object().shape({
      'cc-name': Yup.string().required('Campo requerido'),
      'cc-number': Yup.string().required('Campo requerido'),
      'cc-exp': Yup.string().required('Campo requerido'),
      'cc-csc': Yup.string().required('Campo requerido'),
      'cc-zip': Yup.string().required('Campo requerido'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setPayments({
        ...payments,
        loading: true,
        error: null,
      });

      api
        .post(
          'user/cards',
          {
            AddressZip: values['cc-zip'],
            Cvc: values['cc-csc'],
            ExpMonth: values['cc-exp'].slice(0, 2),
            ExpYear: values['cc-exp'].slice(3, 7),
            Name: values['cc-name'],
            Number: values['cc-number'],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            setPayments({
              ...payments,
              loading: false,
              error: response.data.error,
            });
          } else {
            setPayments({
              ...payments,
              loading: false,
              payments: [...payments, response.data.data],
            });
            formik.resetForm();
          }
          setSubmitting(false);
        })
        .catch((err) => {
          setPayments({
            ...payments,
            loading: false,
            error: err.message,
          });
          setSubmitting(false);
        });
    },
  });

  return (
    <>
      <Alert />
      <Modal />
      <div className="divide-y divide-gray-200">
        <div className="p-4 space-y-2">
          <div className="text-xl font-extrabold tracking-tight">
            Métodos de Pago
          </div>
          <div className="text-sm">
            Añade una nueva tarjeta de credito/débito o edita las existentes.
            Las tarjetas de credito/debito que aceptamos son:{' '}
            <span className="font-semibold">
              Visa, Master Card, American Express, Diners Club y Discover.
            </span>
          </div>
        </div>
        <div className="p-4">
          <div className="flex flex-col space-y-2 text-sm">
            <div> Añadir nuevo método de pago/tarjeta de crédito</div>
            <div className="flex items-center space-x-2">
              <img alt="Amex" src={amex} className="w-10 h-10" />
              <img alt="Visa" src={visa} className="w-10 h-10" />
              <img alt="Mastercard" src={mastercard} className="w-10 h-10" />
              <img alt="Diners" src={diners} className="w-10 h-10" />
              <img alt="Discover" src={discover} className="w-10 h-10" />
            </div>
          </div>
          <div>
            <Form>
              <CleaveInput
                label="Número de tarjeta"
                name="cc-number"
                type="text"
                placeholder="**** **** **** 3742"
                required
                autoComplete="cc-number"
                value={formik.values['cc-number']}
                onChange={formik.handleChange}
                options={{
                  creditCard: true,
                  onCreditCardTypeChanged(type) {
                    formik.setFieldValue('cc-type', type);
                  },
                }}
                error={
                  formik.errors['cc-number'] &&
                  formik.touched['cc-number'] &&
                  formik.errors['cc-number']
                }
                className="col-span-1 md:col-span-2"
              />
              <Input
                label="Nombre"
                name="cc-name"
                type="text"
                placeholder="Juan del Pueblo"
                required
                autoComplete="cc-name"
                value={formik.values['cc-name']}
                onChange={formik.handleChange}
                error={
                  formik.errors['cc-name'] &&
                  formik.touched['cc-name'] &&
                  formik.errors['cc-name']
                }
                className="col-span-1 md:col-span-2"
              />
              <CleaveInput
                label="Fecha de expiración"
                name="cc-exp"
                type="text"
                placeholder="MM/YYYY"
                required
                autoComplete="cc-exp"
                value={formik.values['cc-exp']}
                onChange={formik.handleChange}
                options={{
                  date: true,
                  datePattern: ['m', 'Y'],
                }}
                error={
                  formik.errors['cc-exp'] &&
                  formik.touched['cc-exp'] &&
                  formik.errors['cc-exp']
                }
              />
              <Input
                label="Número de seguridad"
                name="cc-csc"
                type="text"
                placeholder="****"
                required
                autoComplete="cc-csc"
                value={formik.values['cc-csc']}
                onChange={formik.handleChange}
                error={
                  formik.errors['cc-csc'] &&
                  formik.touched['cc-csc'] &&
                  formik.errors['cc-csc']
                }
              />
              <Input
                label="Codigo postal"
                name="cc-zip"
                type="text"
                placeholder="00907..."
                required
                autoComplete="postal-code"
                value={formik.values['cc-zip']}
                onChange={formik.handleChange}
                error={
                  formik.errors['cc-zip'] &&
                  formik.touched['cc-zip'] &&
                  formik.errors['cc-zip']
                }
              />
              <Transition
                show={!!payments.error}
                enter="transform duration-1000 transition ease-in-out"
                enterFrom="opacity-0 -translate-x-full"
                enterTo="opacity-100 rotate-0 translate-x-0"
                leave="transform duration-1000 transition ease-in-out"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 -translate-x-full"
                className="flex items-center text-xs text-rose-500 space-x-2 mt-4 pt-4"
              >
                <BellIcon className="w-4 h-4" />
                <div>{payments.error}</div>
              </Transition>
            </Form>
            <Button
              text="Guardar"
              textColor="text-white"
              disabled={formik.isSubmitting}
              onClick={() => formik.submitForm()}
              loading={formik.isSubmitting}
              loaderColor="bg-emerald-300"
              className="bg-emerald-500 hover:bg-emerald-400 w-20"
            />
          </div>
        </div>
        <div className="p-4 space-y-4">
          <div className="text-sm">
            Métodos de pagos/tarjetas de créditos guardadas:
          </div>
          {payments.loading && (
            <ul className="grid grid-cols-1 gap-6 lg:grid-cols-3">
              {_.times(3, (x) => (
                <li
                  key={x}
                  className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 animate-pulse bg-gray-50"
                >
                  <div className="w-full flex items-center justify-between px-6 space-x-6 h-28">
                    <div className="flex-1 truncate space-y-3">
                      <div className="flex items-center space-x-3">
                        <div className="h-2 w-40 bg-gray-200 rounded-full" />
                        <div className="h-2 w-12 bg-gray-200 rounded-full" />
                      </div>
                      <div className="h-2 w-28 bg-gray-200 rounded-full" />
                      <div className="h-2 w-32 bg-gray-200 rounded-full" />
                    </div>
                    <div className="flex items-center justify-center w-10 h-10 bg-gradient-to-r from-gray-200 to-gray-300 animate-pulse text-white rounded-full flex-shrink-0">
                      <CreditCardIcon className="w-5 h-5" />
                    </div>
                  </div>
                  <div className="-mt-px flex divide-x divide-gray-200 bg-gray-50 rounded-b-lg">
                    <div className="w-0 flex-1 flex">
                      <button
                        type="button"
                        className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                      >
                        <div className="h-2 w-12 bg-gray-200 rounded-full" />
                      </button>
                    </div>
                    <div className="-ml-px w-0 flex-1 flex">
                      <button
                        type="button"
                        className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                      >
                        <div className="h-2 w-12 bg-gray-200 rounded-full" />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
          <ul className="grid grid-cols-1 gap-6 lg:grid-cols-3">
            {_.map(payments.payments, (card) => (
              <li
                key={card.id}
                className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
              >
                <div className="w-full flex items-center justify-between p-6 space-x-6">
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="text-gray-900 text-sm font-medium truncate">
                        {card.brand}
                      </h3>
                      <span className="font-mono flex-shrink-0 inline-block px-2 py-0.5 text-purple-800 text-xs font-medium bg-purple-100 rounded-full">
                        {card.expirationDate}
                      </span>
                    </div>
                    <p className="mt-1 text-gray-500 truncate font-mono text-xs">
                      {card.cardholderName}
                    </p>
                    <p className="mt-1 text-gray-500 truncate font-mono text-xs">
                      **** **** **** {card.last4}
                    </p>
                  </div>
                  <div className="flex items-center justify-center w-10 h-10 bg-gradient-to-r from-green-400 to-blue-500 text-white rounded-full flex-shrink-0">
                    <CreditCardIcon className="w-5 h-5" />
                  </div>
                </div>
                <div className="-mt-px flex divide-x divide-gray-200 bg-gray-50 rounded-b-lg">
                  <div className="w-0 flex-1 flex">
                    <button
                      type="button"
                      onClick={() => {
                        setPayments({ ...payments, alert: card.id });
                      }}
                      className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                    >
                      Eliminar
                    </button>
                  </div>
                  <div className="-ml-px w-0 flex-1 flex">
                    <button
                      type="button"
                      onClick={() => {
                        setPayments({
                          ...payments,
                          modal: true,
                          current: card,
                        });
                      }}
                      className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                    >
                      Editar
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Payment;
