import React from 'react';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { sideAtom } from '../../../../../../atoms/Atoms';

function Sides({ items }) {
  const [side, setSide] = useRecoilState(sideAtom);

  return (
    <div>
      {_.map(items, (a, idx) => (
        <div key={`${idx}-${a.id}`}>
          <div className="font-bold uppercase text-gray-800 mb-2 pb-2 border-b">
            {`${a.name} (Incluye ${a.minRequired})`}
          </div>
          {_.map(a.items, (i) => (
            <div key={i.menuItemId} className="mb-4">
              <div className="flex items-center justify-between text-gray-900 space-y-4 space-x-2">
                <div>{i.name}</div>
                <input
                  type="radio"
                  name={a.name}
                  value={i.id}
                  onChange={() => {
                    const tempSides = [...side];
                    const foundIdx = tempSides.findIndex((add) => add.parentId === a.id);
                    if (foundIdx >= 0) {
                      tempSides.splice(foundIdx, 1);
                    }
                    setSide([...tempSides, i]);
                  }}
                  className="border-gray-200 border-2 text-black focus:border-gray-300 focus:ring-black h-6 w-6 cursor-pointer"
                />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Sides;
