import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons';
import Banner from '../../../assets/images/404.svg';

function NotFound() {
  const history = useHistory();

  return (
    <div className="h-screen bg-black text-white w-full flex flex-col justify-between items-center text-center">
      <div className="flex flex-1 justify-center items-center flex-col">
        <div className="text-7xl tracking-tight font-extrabold text-green-400">
          404
        </div>
        <div className="text-4xl tracking-tight font-extrabold">
          ¡Ups! Parece que está perdido
        </div>
        <div className="mb-4">
          Pero no se preocupe, puede encontrar muchas cosas en nuestro menú.
        </div>
        <button
          type="button"
          onClick={() => history.push('/menu')}
          className="text-sm flex-grow-0 space-x-2 flex items-center rounded px-3 py-2 font-medium tracking-tight border border-white border-opacity-20 hover:border-opacity-30 outline-none focus:outline-none transition duration-500 ease-in-out"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <div>Ir a menú</div>
        </button>
      </div>
      <div className="flex items-center text-center">
        <img alt="404 Banner" src={Banner} />
      </div>
    </div>
  );
}

export default NotFound;
