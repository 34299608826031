/* eslint-disable indent */
import React, { useEffect } from 'react';
import axios from 'axios';
import is from 'is_js';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Header from '../../shared-components/Header';
import { envAtom } from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';

function Reset() {
  const params = useParams();
  const env = useRecoilValue(envAtom);

  useEffect(() => {
    if (params) {
      axios({
        method: 'post',
        url: `${env.url}reset/validate`,
        data: {
          Id: params.id,
          Token: params.token,
        },
        headers: {
          'Ocp-Apim-Subscription-Key': envConfig.REACT_APP_API_TOKEN,
        },
      })
        .then((response) => {
          if (is.falsy(response.data.data)) {
            console.log(response.data.internalMessage);
            console.log('Error');
          } else {
            console.log(response.data.internalMessage);
            console.log('Success');
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }, [params]);

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required('Este campo es requerido.'),
      passwordConfirmation: Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          'Las contraseñas deben ser iguales.'
        )
        .required('Este campo es requerido.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      axios({
        method: 'post',
        url: `${env.url}password/change`,
        data: {
          Id: params.id,
          Token: params.token,
          Password: values.password,
        },
        headers: {
          'Ocp-Apim-Subscription-Key': envConfig.REACT_APP_API_TOKEN,
        },
      })
        .then((response) => {
          formik.resetForm();
          setSubmitting(false);
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
        });
    },
  });

  return (
    <div className="flex flex-col h-screen bg-cyan-500 text-white">
      <Header />
      <div className="flex flex-1 justify-center bg-black bg-opacity-90">
        <div className="flex flex-col items-center h-1/2 w-full md:w-1/3 px-4 md:px-0 mt-10">
          <div className="p-6 rounded-xl border border-white border-opacity-10">
            <div className="text-4xl tracking-tight font-extrabold mb-1 text-center">
              Recuperar Contraseña
            </div>
            <div className="font-medium text-sm mb-4 text-center">
              Escriba su correo electrónico y recibira las instrucciones para
              recuperar la contraseña
            </div>
            <div className="w-full mb-2">
              <form
                noValidate
                autoComplete="on"
                onSubmit={formik.handleSubmit}
                className="text-sm"
              >
                <div className="mb-3">
                  <div className="font-semibold tracking-tight mb-2">
                    Contraseña <span className="text-rose-400">*</span>
                  </div>
                  <input
                    required
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    placeholder="***"
                    className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  />
                  {formik.errors.password && formik.touched.password && (
                    <div className="w-full font-semibold text-xs text-rose-400 mt-1">
                      {formik.errors.password}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <div className="font-semibold tracking-tight mb-2">
                    Confirmar Contraseña{' '}
                    <span className="text-rose-400">*</span>
                  </div>
                  <input
                    required
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    type="password"
                    autoComplete="password"
                    value={formik.values.passwordConfirmation}
                    onChange={formik.handleChange}
                    placeholder="***"
                    className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  />
                  {formik.errors.passwordConfirmation &&
                    formik.touched.passwordConfirmation && (
                      <div className="w-full font-semibold text-xs text-rose-400 mt-1">
                        {formik.errors.passwordConfirmation}
                      </div>
                    )}
                </div>
                <div className="flex space-x-2">
                  <button
                    className="w-full md:w-1/2 flex items-center justify-center text-center text-sm transition duration-500 ease-in-out bg-gray-700 hover:bg-rose-600 text-white font-semibold py-3 rounded"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    className="w-full md:w-1/2 flex items-center justify-center text-center text-sm transition duration-500 ease-in-out bg-green-500 hover:bg-green-600 text-white font-semibold py-3 rounded"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    {formik.isSubmitting && (
                      <div>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      </div>
                    )}
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reset;
