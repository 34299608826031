import React from 'react';

function Subtitle() {
  return (
    <div className="text-sm font-medium mb-4 text-center md:text-left">
      Hecho en Puerto Rico y la primera franquicia boricua desde 1988
    </div>
  );
}

export default Subtitle;
