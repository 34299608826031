import { selector } from 'recoil';
import _ from 'lodash';
import is from 'is_js';
import {
  addonsAtom,
  cardAtom,
  paymentOptionAtom,
  hourAtom,
  optionAtom,
  orderAtom,
  quantityAtom,
  serviceAtom,
  promoCodeAtom,
  storeAtom,
  stateAtom
} from './Atoms';

export const countSelector = selector({
  key: 'count',
  get: ({ get }) => _.countBy(get(orderAtom), 'name'),
});

export const optionSelector = selector({
  key: 'options',
  get: ({ get }) => {
    let addonsSubtotal = 0;
    const opt = get(optionAtom);
    const qty = get(quantityAtom);
    const add = get(addonsAtom);

    if (is.not.empty(add)) {
      _.map(add, (a) => {
        addonsSubtotal += a.price;
      });
    } else {
      addonsSubtotal = 0;
    }

    return (opt.activePrice + addonsSubtotal) * qty;
  },
});

export const checkoutSelector = selector({
  key: 'checkout',
  get: ({ get }) => {
    const order = get(orderAtom);
    const hour = get(hourAtom);
    const store = get(storeAtom);
    const service = get(serviceAtom);
    const card = get(cardAtom);
    const state = get(stateAtom);
    const promoCode = get(promoCodeAtom);
    const paymentOption = get(paymentOptionAtom);

    let subtotal = 0;
    let minimum = 0;
    let discount = 0;
    let serviceCost = 0;
    let processingFee = 0;
    let deliveryTotal = 0;
    const errors = {};

    let munTax = 0;
    let staTax = 0;

    for (let i = 0; i < order.length; i++) {
      subtotal += order[i].price;
      munTax = order[i].price * order[i].stateTaxPct;
      staTax = order[i].price * order[i].munTaxPct;
    }

    if (state?.isOrderServiceFeeFixed) {
      processingFee = state?.orderServiceFee;
    } else if (state) {
      processingFee = state?.orderServiceFee * parseFloat(subtotal);
    }
    if (service === 0 && store?.locations) {
      if (store?.locations[0].pickupFeeType === 2) {
        processingFee += (store?.locations[0].pickupFee * subtotal);
      } else if (store.locations[0].pickupFeeType === 1) {
        processingFee += store?.locations[0].pickupFee;
      }
    }

    if (service === 0) {
      minimum = store?.minimumPickupOrderAmount;
      processingFee = store?.locations[0].pickupFee;
    } else if (service === 1 && hour) {
      minimum = store?.minimumDeliveryOrderAmount;
      deliveryTotal = hour?.actualCost;
    } else {
      minimum = store?.minimumDeliveryOrderAmount;
      deliveryTotal = store?.locations[0].deliveryFee;
    }

    if (promoCode && !(
      (promoCode?.appliesTo === 0 && service !== 0) ||
      (promoCode?.appliesTo === 1 && service !== 1) ||
      promoCode?.minimumAmount > subtotal
    )) {
      if (promoCode.type === 0) {
        // free delivery
        discount = deliveryTotal;
      } else if (promoCode.type === 4) {
        // gift card
        discount = promoCode.value;
      }
    }

    if (service === 0 && paymentOption?.hasFreePickup) {
      processingFee = 0;
    }
    if (service === 1 && paymentOption?.hasFreeDelivery) {
      deliveryTotal = 0;
    }

    if (paymentOption?.taxExcluded) {
      munTax = 0;
      staTax = 0;
    }

    if (!hour) {
      errors.hour = 'Debes seleccionar una hora.';
    } else {
      delete errors.hour;
    }

    if (subtotal <= minimum) {
      errors.minimum = `El mínimo en productos para someter una orden es de $${minimum?.toFixed(
        2
      )}. Actualmente tienes $${subtotal.toFixed(2)} en tu carrito.`;
    } else {
      delete errors.minimum;
    }

    if (!card && !paymentOption) {
      errors.card = 'Debes registrar y/o seleccionar un método de pago.';
    } else {
      delete errors.card;
    }

    serviceCost = processingFee + deliveryTotal;

    return {
      minimum,
      metMinimum: subtotal >= minimum,
      hasPaymentSelected: card || paymentOption,
      subtotal,
      service: serviceCost,
      munTax,
      staTax,
      totalTax: munTax + staTax,
      total: (subtotal + munTax + staTax + serviceCost) - discount,
      errors,
      discountTotal: discount,
    };
  },
});
