import { DotsVerticalIcon } from '@heroicons/react/outline';
import React from 'react';

function Placeholder() {
  return (
    <div className="flex items-center justify-between space-x-4 p-4 h-24 animate-pulse">
      <div className="text-gray-300 space-y-1">
        <div className="h-1 w-24 bg-gray-300 rounded-full animate-pulse" />
        <div className="h-1 w-12 bg-gray-300 rounded-full animate-pulse" />
        <div className="h-1 w-52 bg-gray-300 rounded-full animate-pulse" />
        <div className="h-1 w-44 bg-gray-300 rounded-full animate-pulse" />
        <div className="h-1 w-44 bg-gray-300 rounded-full animate-pulse" />
        <div className="h-1 w-44 bg-gray-300 rounded-full animate-pulse" />
      </div>
      <div className="px-1 py-2">
        <DotsVerticalIcon className="h-6 w-6 text-gray-300 " />
      </div>
    </div>
  );
}

export default Placeholder;
