import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import Header from '../../shared-components/Header';

function Privacy() {
  const date = 'Mayo 13 de 2024';
  return (
    <AnimatePresence>
      <Header />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            {/* Header */}
            <p className="mt-1 text-xl tracking-tight font-bold text-gray-900 sm:text-2xl lg:text-4xl hover:text-yellow-200">
              Política de Privacidad
            </p>
            {/* Subheader */}
            <p className="mt-1 text-md tracking-tight font-semibold text-gray-600 sm:text-xl lg:text-2xl">
              Como cuidamos los datos
            </p>
            {/* Last Update */}
            <p className="mt-1 text-md tracking-tight font-semibold text-gray-600 ">
              {`Actualizado: ${date}`}
            </p>
            {/* Intro */}
            <p className="mt-8 text-md tracking-tight font-normal text-gray-600 px-0 text-justify">
              Esta Política de Privacidad te ayudará a comprender mejor cómo
              recopilamos, usamos y compartimos tu información personal
              cuando ordenas en Antonino&apos;s Pizza. Si modificamos nuestras
              prácticas de privacidad, actualizaremos esta política de
              privacidad y te informaremos de manera oportuna.
            </p>
            {/* Body */}
            <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">Nuestros valores</h1>
                <li className="text-md text-left font-normal ml-0">
                  En Antonino&apos;s Pizza, la confianza es fundamental. Nos comprometemos
                  a manejar tu información personal de manera responsable.
                  Tres valores principales guían nuestra operación: transparencia,
                  seguridad y respeto por tu privacidad.
                </li>
                <li className="text-md text-left font-semibold ml-4 mt-4">
                  Tu información te pertenece:
                </li>
                <li className="text-md text-left font-normal ml-4">
                  Analizamos cuidadosamente la información necesaria para brindarte un servicio
                  óptimo y nos esforzamos por limitar la recolección de datos a lo esencial. Además,
                  mantenemos tus datos seguros y protegidos en todo momento.
                </li>
                <li className="text-md text-left font-semibold ml-4 mt-4">
                  Protegemos tu información
                </li>
                <li className="text-md text-left font-normal ml-4">
                  Tu privacidad es nuestra prioridad. No compartiremos tu información personal con
                  terceros a menos que lo autorices o estemos legalmente obligados a hacerlo. Nos
                  comprometemos a informarte sobre cualquier solicitud de divulgación de datos, a
                  menos que estemos legalmente impedidos de hacerlo.
                </li>
              </ol>
            </p>
            <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">
                  Tus derechos sobre tu información
                </h1>
                <li className="text-md text-left font-normal ml-4">
                  Cuando realizas un pedido en nuestro sitio web, recopilamos información personal
                  como tu nombre, dirección de correo electrónico, número de teléfono,
                  dirección de entrega y detalles de pago. También podemos recopilar
                  información sobre tu dispositivo y actividad de navegación.
                </li>
                <li className="text-md text-left font-normal ml-4 mt-4">
                  Utilizamos la información que recopilamos para procesar tus pedidos,
                  comunicarnos contigo sobre el estado de tu pedido, mejorar nuestros
                  servicios y personalizar tu experiencia. También podemos utilizar tu
                  información para fines de marketing, como enviarte ofertas promocionales o
                  actualizaciones sobre nuestros productos y servicios.
                </li>
                <li className="text-md text-left font-normal ml-4 mt-4">
                  Podemos compartir tu información personal con proveedores de
                  servicios de tercerosque nos ayudan a operar nuestro sitio web,
                  procesar pagos, entregar pedidos y realizar otras funciones
                  comerciales. También podemos compartir tu información según lo requiera
                  la ley o para proteger nuestros derechos, propiedad o seguridad.
                </li>
                <li className="text-md text-left font-normal ml-4 mt-4">
                  Tomamos en serio la seguridad de tu información personal y
                  utilizamos medidas estándar de la industria para protegerla
                  contra el acceso no autorizado, la divulgación, la alteración o la destrucción.
                </li>
                <li className="text-md text-left font-normal ml-4 mt-4">
                  Tienes derecho a acceder, corregir o eliminar tu información
                  personal. También puedes tener derecho a objetar o restringir
                  ciertos usos de tu información. Para ejercer estos derechos,
                  contáctanos utilizando la información proporcionada a continuación.
                </li>
              </ol>
            </p>
            <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">
                  Contáctanos
                </h1>
                <li className="text-md text-left font-normal ml-0">
                  Si tienes alguna pregunta o inquietud sobre nuestra Política
                  de Privacidad o el manejo de tu información personal, contáctanos en{' '}
                  <a
                    className="text-blue-800 font-semibold"
                    href="mailto:antoninospizza1988@gmail.com"
                  >
                    Servicio al Cliente
                  </a>
                  .
                </li>
                {/* <li className="text-md text-left font-semibold ml-4 mt-4">Gopanza LLC.</li>
                <li className="text-md text-left font-normal ml-4">ATTN. Privacy Team</li>
                <li className="text-md text-left font-normal ml-4">Apt 3307 Portal de Sofía</li>
                <li className="text-md text-left font-normal ml-4">Guaynabo, PR 00969</li> */}
              </ol>
            </p>
          </div>
        </div>
      </div>
    </AnimatePresence>
  );
}
export default Privacy;
