import React, { Fragment } from 'react';
import { Dialog, Transition, Disclosure } from '@headlessui/react';
import is from 'is_js';
import _ from 'lodash';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { NavLink } from 'react-router-dom';
import {
  orderAtom,
  sidebarAtom,
  tokenAtom,
  userAtom,
} from '../../../atoms/Atoms';
import logo from '../../../assets/images/logo.png';
import { phoneNumber } from '../../../utils';

function Sidebar() {
  const [show, setShow] = useRecoilState(sidebarAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const order = useRecoilValue(orderAtom);

  const routes = [
    { text: 'Pedidos', path: 'orders' },
    { text: 'Configuración', path: 'settings' },
    { text: 'Lealtad', path: 'loyalty' },
    { text: 'Contraseña', path: 'password' },
    { text: 'Pagos', path: 'payment' },
    { text: 'Mis Localidades', path: 'locations' },
  ];

  const delays = [
    'delay-75',
    'delay-100',
    'delay-150',
    'delay-200',
    'delay-300',
  ];

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-hidden"
        open={show}
        onClose={setShow}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-800 bg-opacity-80" />
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-700 delay-200"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-black shadow-xl text-white divide-y divide-gray-800">
                  <div className="flex items-center justify-between p-4">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-700 delay-200"
                      enterFrom="opacity-0 -translate-x-full"
                      enterTo="opacity-1 translate-x-0"
                      leave="transform transition ease-in-out duration-700"
                      leaveFrom="opacity-1 translate-x-0"
                      leaveTo="opacity-0 -translate-x-full"
                    >
                      <img alt="logo" src={logo} className="h-14 w-auto" />
                    </Transition.Child>
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-700 delay-200"
                      enterFrom="opacity-0 translate-x-full"
                      enterTo="opacity-1 translate-x-0"
                      leave="transform transition ease-in-out duration-700"
                      leaveFrom="opacity-1 translate-x-0"
                      leaveTo="opacity-0 translate-x-full"
                    >
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-500 focus:outline-none ring-0 focus:ring-0 transition duration-500 ease-in-out"
                        onClick={() => setShow(false)}
                      >
                        <span className="sr-only">Cerrar</span>
                        <XIcon className="h-4 w-4" aria-hidden="true" />
                      </button>
                    </Transition.Child>
                  </div>
                  <div className="min-h-0 flex-1 flex flex-col overflow-y-scroll no-scrollbar">
                    <Transition.Child
                      enter="transform transition ease-in-out duration-700 delay-200"
                      enterFrom="opacity-0 translate-y-1/4"
                      enterTo="opacity-1 translate-y-0"
                      leave="transform transition ease-in-out duration-700"
                      leaveFrom="opacity-1 translate-y-0"
                      leaveTo="opacity-0 translate-y-1/4"
                      className="flex-1 divide-y divide-gray-800 text-sm"
                    >
                      <NavLink
                        exact
                        to="/"
                        onClick={() => setShow(false)}
                        className="p-4 space-x-2 flex items-center justify-between text-white hover:text-green-400 transition ease-in-out duration-500"
                        activeClassName="text-green-500"
                      >
                        <span>Inicio</span>
                        <ChevronRightIcon className="w-4 h-4" />
                      </NavLink>
                      <NavLink
                        to="/menu"
                        onClick={() => setShow(false)}
                        className="p-4 space-x-2 flex items-center justify-between text-white hover:text-green-400 transition ease-in-out duration-500"
                        activeClassName="text-green-500"
                      >
                        <span>Menú</span>
                        <ChevronRightIcon className="w-4 h-4" />
                      </NavLink>
                      {is.not.empty(order) && (
                        <NavLink
                          to="/order"
                          onClick={() => setShow(false)}
                          className="p-4 space-x-2 flex flex-row items-center justify-between text-white hover:text-green-400 transition ease-in-out duration-500"
                          activeClassName="text-green-500"
                        >
                          <div className="flex items-center space-x-2">
                            <div>Orden</div>
                            <div className="flex items-center justify-center text-xs font-semibold h-6 w-6 rounded-full border border-gray-700">
                              {order.length}
                            </div>
                          </div>
                          <ChevronRightIcon className="w-4 h-4" />
                        </NavLink>
                      )}
                      {!user && (
                        <>
                          <NavLink
                            to="/signin"
                            onClick={() => setShow(false)}
                            className="p-4 space-x-2 flex items-center justify-between text-white hover:text-green-400 transition ease-in-out duration-500"
                            activeClassName="text-green-500"
                          >
                            <div>Iniciar Sesión</div>
                            <ChevronRightIcon className="w-4 h-4" />
                          </NavLink>
                          <NavLink
                            to="/signup"
                            onClick={() => setShow(false)}
                            className="p-4 space-x-2 flex items-center justify-between text-white hover:text-green-400 transition ease-in-out duration-500"
                            activeClassName="text-green-500"
                          >
                            <div>Crear Cuenta</div>
                            <ChevronRightIcon className="w-4 h-4" />
                          </NavLink>
                        </>
                      )}
                      {user && (
                        <>
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="w-full p-4 space-x-2 flex items-center justify-between text-white hover:text-green-400 transition ease-in-out duration-500">
                                  <div>Perfil</div>
                                  <ChevronDownIcon
                                    className={`${
                                      open ? 'transform rotate-180' : ''
                                    } w-4 h-4 transition duration-500 ease-in-out`}
                                  />
                                </Disclosure.Button>
                                <Transition>
                                  <Disclosure.Panel className="text-sm text-gray-500 divide-y divide-gray-900">
                                    <Transition.Child
                                      enter="transition duration-700 ease-out"
                                      enterFrom="transform translate-x-1/2 opacity-0"
                                      enterTo="transform translate-x-0 opacity-100"
                                      leave="transition duration-75 ease-out"
                                      leaveFrom="transform opacity-100"
                                      leaveTo="transform opacity-0"
                                    >
                                      <div className="p-4 space-y-2 text-xs">
                                        <div className="flex flex-col text-white transition ease-in-out duration-500">
                                          <div className="tracking-tight truncate truncate-1-lines">
                                            {user.completeName}
                                          </div>
                                          <div className="text-gray-500 tracking-tight truncate truncate-1-lines">
                                            {user.email}
                                          </div>
                                          <div className="text-gray-500 tracking-tight truncate truncate-1-lines">
                                            {phoneNumber(user.phoneNumber)}
                                          </div>
                                        </div>
                                      </div>
                                    </Transition.Child>

                                    {_.map(routes, (route, index) => (
                                      <Transition.Child
                                        key={route.text}
                                        enter={`transition duration-700 ease-out ${delays[index]}`}
                                        enterFrom="transform translate-x-1/2 opacity-0"
                                        enterTo="transform translate-x-0 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform opacity-100"
                                        leaveTo="transform opacity-0"
                                      >
                                        <NavLink
                                          to={`/profile/${route.path}`}
                                          onClick={() => setShow(false)}
                                          className="py-4 pr-4 pl-8 space-x-2 flex items-center text-gray-400 hover:text-green-400 transition ease-in-out duration-500"
                                          activeClassName="text-green-500"
                                        >
                                          <ChevronRightIcon className="w-4 h-4" />
                                          <div>{route.text}</div>
                                        </NavLink>
                                      </Transition.Child>
                                    ))}
                                  </Disclosure.Panel>
                                </Transition>
                              </>
                            )}
                          </Disclosure>
                        </>
                      )}
                    </Transition.Child>
                  </div>
                  {user && (
                    <button
                      type="button"
                      className="p-4 w-full flex items-center justify-center text-sm text-center text-white bg-black hover:bg-green-500 transition ease-in-out duration-500"
                      onClick={() => {
                        setUser(null);
                        setToken(null);
                        localStorage.removeItem('ant-token');
                        localStorage.removeItem('ant-user');
                        localStorage.removeItem('ant-state');
                        localStorage.removeItem('ant-card');
                      }}
                    >
                      Cerrar Sesión
                    </button>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Sidebar;
