import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBadgeCheck,
  faChevronRight,
  faStar,
} from '@fortawesome/pro-duotone-svg-icons';
import { useHistory } from 'react-router-dom';
import EmptyLocations from '../../../../../../../../shared-components/illustrations/EmptyLocations';
import {
  H2,
  H3,
} from '../../../../../../../../shared-components/typography/Title';
import Subtitle from '../../../../../../../../shared-components/typography/Subtitle';
import Button from '../../../../../../../../shared-components/buttons/Button';
import Loader from '../../../../../../../../shared-components/loader/Loader';
import Delivery from '../../../../../../../../shared-components/illustrations/Delivery';
import api from '../../../../../../../../../api/api';
import {
  tokenAtom,
  locationAtom,
  notificationsAtom,
  orderAtom,
  serviceAtom,
  storeAtom,
} from '../../../../../../../../../atoms/Atoms';

function MediumDevicesList() {
  const history = useHistory();
  const token = useRecoilValue(tokenAtom);
  const setService = useSetRecoilState(serviceAtom);
  const [order, setOrder] = useRecoilState(orderAtom);
  const [locations, setLocations] = useRecoilState(locationAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [store, setStore] = useRecoilState(storeAtom);
  const [state, setState] = useState(null);

  const getBusiness = (e) => {
    setLocations({
      ...locations,
      loadingBusinesses: true,
      businesses: [],
    });
    api
      .get(`/businesses/${e.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setLocations({
            ...locations,
            loadingBusinesses: false,
            businesses: [],
          });
          setNotifications({
            ...notifications,
            title: 'Error al buscar tiendas',
            description: response.data.error,
            error: true,
          });
        } else {
          setLocations({
            ...locations,
            loadingBusinesses: false,
            businesses: response.data.data.results,
          });
        }
      })
      .catch((error) => {
        setLocations({
          ...locations,
          loadingBusinesses: false,
          businesses: [],
        });
        setNotifications({
          ...notifications,
            title: 'Error al buscar tiendas',
            description: `Lo sentimos, hubo un error buscando tiendas. Favor de intentar nuevamente. ${error.message}`,
            error: true
        });
      });
  };

  const handleAlert = async (e) => {
    await setStore(e);
    await setService(1);
    await setOrder([]);
    await setLocations({
      ...locations,
      alert: null,
      page: 0,
      modal: false,
      location: state,
    });
    await localStorage.setItem('ant-store', JSON.stringify(e));
    await localStorage.setItem('ant-location', JSON.stringify(state));
    await localStorage.setItem('ant-service', 1);
    await localStorage.removeItem('ant-order');
    await history.push('/menu');
  };

  if (locations.loading) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center">
        <Loader color="bg-gray-700" />
      </div>
    );
  }

  if (!locations.loading && locations.locations.length === 0) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center">
        <EmptyLocations className="w-64 h-44" />
        <div className="text-center p-4">
          <H2 text="Sin Localidades" />
          <Subtitle text="Aún no tienes localidades registradas en tu perfil. Comienza registrando una localidad para seleccionar la tienda mas cercana a ti." />
        </div>
        <Button
          text="Registrar"
          textColor="text-white"
          className="bg-emerald-500 hover:bg-emerald-400 w-24"
          onClick={() => setLocations({ ...locations, current: 'register' })}
        />
      </div>
    );
  }

  return (
    <div className="w-full grid grid-cols-2 divide-x divide-gray-100 dark:divide-gray-600 text-sm overflow-hidden">
      <motion.div
        variants={{
          hide: {
            opacity: 0,
          },
          show: {
            opacity: 1,
            transition: {
              staggerChildren: 0.1,
            },
          },
        }}
        initial="hide"
        animate="show"
        className="flex flex-col flex-1 overflow-y-auto divide-y divide-gray-100 dark:divide-gray-600"
      >
        {_.map(locations.locations, (l) => (
          <motion.button
            type="button"
            key={l.id}
            variants={{
              hide: { opacity: 0 },
              show: {
                opacity: 1,
              },
            }}
            className="hover:bg-gray-50 dark:hover:bg-gray-600 h-16 flex items-center text-left w-full p-4 space-x-4 transition duration-500 ease-in-out"
            onClick={() => {
              getBusiness(l);
              setState(l);
            }}
          >
            <FontAwesomeIcon
              icon={faStar}
              className={
                state?.id === l.id ? 'text-amber-400' : 'text-gray-300'
              }
            />
            <div className="flex-1 text-xs truncate">
              <div className="text-gray-800 dark:text-white truncate font-medium">
                {l.name}
              </div>
              <div className="text-gray-400 truncate">{l.addressLine}</div>
            </div>
            <FontAwesomeIcon
              icon={faChevronRight}
              className="text-xs text-gray-400"
            />
          </motion.button>
        ))}
      </motion.div>
      <div className="flex flex-col flex-1 overflow-hidden divide-y divide-gray-100 dark:divide-gray-600">
        {/* No address selected */}
        {!state && (
          <div className="flex flex-col flex-1 items-center justify-center text-xs tracking-tight p-4 text-center">
            <Delivery className="h-16 mb-4" />
            <H3 text="Seleccionar Localización" />
            <div className="text-center text-gray-400">
              Selecciona una de tus localizaciones para saber que tiendas pueden
              llevarte la compra.
            </div>
          </div>
        )}
        {/* Address selected and loading */}
        {locations.loadingBusinesses && (
          <div className="flex flex-col flex-1 items-center justify-center text-xs tracking-tight p-4 text-center">
            <Loader />
          </div>
        )}
        {/* Address selected, not loading and no results */}
        {state &&
          !locations.loadingBusinesses &&
          locations.businesses.length === 0 && (
            <div className="flex flex-col flex-1 items-center justify-center text-xs tracking-tight p-4 text-center">
              <Delivery className="h-16 mb-4" />
              <H3 text="Aún on llegamos a esta dirección" />
              <div className="text-center text-gray-400">
                No hemos encontrado tiendas para entregar a la dirección
                seleccionada. Intenta de nuevo mas adelante.
              </div>
            </div>
          )}
        {/* Address selected, not loading and results */}
        {state &&
          !locations.loadingBusinesses &&
          locations.businesses.length > 0 && (
            <motion.div
              variants={{
                hide: {
                  opacity: 0,
                },
                show: {
                  opacity: 1,
                  transition: {
                    staggerChildren: 0.1,
                  },
                },
              }}
              initial="hide"
              animate="show"
              className="relative flex flex-col flex-1 text-sm tracking-tight overflow-y-auto divide-y divide-gray-100 dark:divide-gray-600"
            >
              {locations.alert && (
                <motion.div
                  initial={{
                    y: '100%',
                  }}
                  animate={{
                    y: 0,
                    transition: {
                      delay: 0.5,
                    },
                  }}
                  exit={{
                    y: '100%',
                  }}
                  className="inset-x-0 absolute z-10 flex flex-col items-center justify-center text-center bottom-0 bg-gray-900 bg-opacity-95 h-1/2 text-white p-4 rounded-br-xl"
                >
                  <div className="font-semibold mb-2">¡Oopss!</div>
                  <div className="text-xs">
                    Al seleccionar otra tienda perderás todos los productos que
                    se encuantran en tu carrito. Si estás de acuerdo haz click
                    en aceptar.
                  </div>
                  <div className="space-x-2 mt-4">
                    <Button
                      text="Cancelar"
                      onClick={() => {
                        setLocations({ ...locations, alert: null });
                      }}
                      className="bg-gray-800 w-20 border border-gray-700 hover:border-gray-600"
                    />
                    <Button
                      text="Aceptar"
                      onClick={() => handleAlert(locations.alert)}
                      className="bg-emerald-500 hover:bg-emerald-400 w-20"
                    />
                  </div>
                </motion.div>
              )}
              <div className="text-white uppercase text-xs md:h-8 text-center m-2">
                La(s) tienda(s) abajo entregan a tu localidad. <br />
                Escoje la tienda de donde deseas recibir tu pedido
              </div>
              {_.map(locations.businesses, (e) => (
                <motion.button
                  type="button"
                  key={e.id}
                  variants={{
                    hide: { opacity: 0 },
                    show: {
                      opacity: 1,
                    },
                  }}
                  onClick={async () => {
                    if (store && order.length > 0 && store.id !== e.id) {
                      await setLocations({ ...locations, alert: e });
                    } else {
                      await setStore(e);
                      await setService(1);
                      await setLocations({
                        ...locations,
                        modal: false,
                        page: 0,
                        location: state,
                      });
                      await localStorage.setItem('ant-service', 1);
                      await localStorage.setItem(
                        'ant-location',
                        JSON.stringify(state)
                      );
                      await localStorage.setItem(
                        'ant-store',
                        JSON.stringify(e)
                      );
                      await history.push('/menu');
                    }
                  }}
                  className="flex items-center hover:bg-gray-50 dark:hover:bg-gray-600 h-16 lex items-center text-xs outline-none p-4 space-x-4 focus:outline-none ring-0 focus:ring-0 transition duration-200 ease-in-out"
                >
                  <FontAwesomeIcon
                    icon={faStar}
                    className={
                      store?.id === e.id ? 'text-amber-400' : 'text-gray-300'
                    }
                  />
                  <div className="flex-1 text-left truncate">
                    <div className="font-medium truncate">{e.name}</div>
                    <div className="text-gray-400 truncate">
                      Mínimo de pedido: $
                      {e.minimumDeliveryOrderAmount.toFixed(2)}/Costo por
                      servicio: ${e.locations[0].deliveryFee.toFixed(2)}
                    </div>
                  </div>
                  <FontAwesomeIcon
                    icon={faBadgeCheck}
                    className="text-xs text-gray-400"
                  />
                </motion.button>
              ))}
            </motion.div>
          )}
      </div>
    </div>
  );
}

export default MediumDevicesList;
