import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Transition } from '@headlessui/react';
import Title from './Title';
import Subtitle from './Subtitle';
import { locationAtom, pickupModalAtom } from '../../../../../atoms/Atoms';
import logo from '../../../../../assets/images/logo.png';

function Content() {
  const setPickupModal = useSetRecoilState(pickupModalAtom);
  const [locations, setLocations] = useRecoilState(locationAtom);

  return (
    <main className="flex flex-col flex-1 justify-start md:justify-center mt-16 md:mt-0 items-center px-4">
      <Transition
        appear
        show
        enter="transform transition duration-700"
        enterFrom="opacity-0 scale-50"
        enterTo="opacity-100 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 scale-100 "
        leaveTo="opacity-0 scale-95"
        className="flex flex-col items-center"
      >
        <img src={logo} alt="logo" className="w-24 md:w-48 h-auto" />
        <Title />
        <Subtitle />
        <div className="flex items-center space-x-2">
          <button
            type="button"
            onClick={() => setLocations({ ...locations, modal: true })}
            className="flex items-center flex-1 md:flex-none items-center justify-center w-40 space-x-2 px-4 py-3 border bg-green-500 border-green-500 border-opacity-50 rounded font-semibold text-sm text-white hover:bg-green-800 hover:border-green-800 transition duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
          >
            <span>Delivery</span>
          </button>
          <button
            type="button"
            onClick={() => setPickupModal(true)}
            className="flex items-center flex-1 md:flex-none items-center justify-center w-40 space-x-2 px-4 py-3 border bg-green-500 border-green-500 border-white border-opacity-50 rounded font-semibold text-sm text-white hover:bg-green-800 hover:border-green-800 transition duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
          >
            <span>Pickup</span>
          </button>
        </div>
      </Transition>
    </main>
  );
}

export default Content;
