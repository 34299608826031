import React, { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import Scroll from 'react-scroll';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { storeAtom, menuAtom } from '../../../atoms/Atoms';
import Header from '../../shared-components/Header';
import getMenu from '../../../utils/getMenu';
import Banner from './shared-components/Bannner';
import Modal from './shared-components/modal/Modal';
import Items from './shared-components/items/Items';
import Toolbar from './shared-components/toolbar/Toolbar';
import Title from './shared-components/title/Title';

function Menu() {
  const history = useHistory();
  const [store, setStore] = useRecoilState(storeAtom);
  const [menu, setMenu] = useRecoilState(menuAtom);

  useMount(async () => {
    const storage = window.localStorage.getItem('ant-store');
    if (!storage) {
      history.push('/');
    }
  });

  return (
    <>
      <Modal />
      <Header />
      <Banner />
      <Toolbar />
      <Title />
      <Items />
    </>
  );
}

export default Menu;
