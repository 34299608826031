import React from 'react';
import _ from 'lodash';

function Loader() {
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        {_.times(9, (index) => (
          <div
            key={index}
            className="animate-pulse flex h-32 border border-gray-100 outline-none focus:outline-none"
          >
            <div className="flex flex-col justify-between p-4 h-full w-2/3 text-left">
              <div>
                <div className="mb-2 bg-gray-100 w-32 h-2 rounded-full" />
                <div className="mb-2 bg-gray-100 w-full h-2 rounded-full" />
                <div className="mb-2 bg-gray-100 w-full h-2 rounded-full" />
                <div className="mb-2 bg-gray-100 w-full h-2 rounded-full" />
              </div>
              <div className="mb-2 bg-gray-100 w-12 h-2 rounded-full" />
            </div>
            <div className="flex flex-shrink-0 justify-center items-center w-1/3 h-full bg-cover bg-center overflow-hidden">
              <div className="w-24 h-24 bg-gray-100 animate-pulse rounded" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Loader;
