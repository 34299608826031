import React from 'react';
import { useRecoilState } from 'recoil';
import { Transition } from '@headlessui/react';
import _ from 'lodash';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import Card from './shared-components/Card';
import { locationAtom, refreshLocationsAtom } from '../../../../../atoms/Atoms';
import Modal from './shared-components/Modal';
import FavoriteModal from './shared-components/FavoriteModal';
import Placeholder from './shared-components/Placeholder';
import ImageModal from './shared-components/ImageModal';
import AddNewModal from './shared-components/AddNewModal';

function Locations() {
  const [locations, setLocations] = useRecoilState(locationAtom);

  return (
    <div className="divide-y divide-gray-200">
      <Modal />
      <ImageModal />
      <FavoriteModal />
      <AddNewModal />
      <Transition
        as="ul"
        show
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-4"
      >
        {locations.loading &&
          _.times(5, (x) => <Placeholder key={x} index={x} />)}
      </Transition>
      <Transition
        as="ul"
        show={locations.locations.length > 0}
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-4"
      >
        <Transition.Child
          as={React.Fragment}
          enter="transform transition duration-1000 delay-50"
          enterFrom="opacity-0 -translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transform duration-1000 transition ease-in-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <button
            type="button"
            onClick={() => setLocations({ ...locations, add_new_modal: true })}
            className="group bg-gray-100 hover:shadow-xl transform hover:scale-105 flex flex-col items-center justify-center w-full border-2 border-purple-300 border-dashed p-12 text-center rounded-md outline-none focus:outline-none ring-0 focus:ring-0 transition duration-500 ease-in-out"
          >
            <LocationMarkerIcon className="w-10 h-10 text-purple-400" />
            <span className="mt-2 block text-xs font-semibold bg-purple-400 text-white rounded-full px-2 py-1">
              Registrar Localidad
            </span>
          </button>
        </Transition.Child>
        {_.map(locations.locations, (location, index) => (
          <Card key={location.id} location={location} index={index} />
        ))}
      </Transition>
    </div>
  );
}

export default Locations;
