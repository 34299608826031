import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AnimatePresence, motion } from 'framer-motion';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';
import { menuAtom, itemAtom } from '../../../../../atoms/Atoms';

function Deals() {
  const menu = useRecoilValue(menuAtom);
  const [state, setState] = useState([]);
  const setItem = useSetRecoilState(itemAtom);

  useEffect(() => {
    if (menu?.sections?.length > 0) {
      const deals = _.filter(menu, (e) => e.departmentName === 'Ofertas');
      if (deals.length > 0) {
        setState(deals[0].items);
      }
    }
  }, [menu]);

  return (
    <AnimatePresence>
      {state.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            animate: {
              type: 'spring',
              stiffness: 2000,
              duration: 1
            }
          }}
          exit={{ opacity: 0 }}
          className="mb-8"
        >
          <div className="text-2xl font-bold border-b pb-4 mb-4">Ofertas</div>
          <Flickity
            options={{
              freeScroll: true,
              wrapAround: true,
              autoPlay: false
            }}
            className="outline-none ring-0 focus:ring-0 focus:ring-0"
          >
            {_.map(state, (item) => (
              <button
                key={item.id}
                type="button"
                onClick={() => setItem(item)}
                className="flex h-32 w-full md:w-1/3 border hover:bg-gray-50 ring-0 focus:ring-0 outline-none focus:outline-none mr-4"
              >
                <div className="flex flex-col justify-between p-4 h-full w-2/3 text-left">
                  <div>
                    <div className="font-bold text-sm mb-2">{item.name}</div>
                    <div className="text-xs overflow-hidden truncate-2-lines">
                      {item.description}
                    </div>
                  </div>
                  <div className="text-xs font-semibold text-green-400">
                    {item.prices[1] || item.addOns[0] ? 'Desde' : ''} $
                    {item && item.prices[0].activePrice.toFixed(2)}
                  </div>
                </div>
                <div className="flex justify-center items-center w-1/3 h-full bg-cover bg-center overflow-hidden">
                  {item.imageUrl && <img alt="Product" src={item.imageUrl} />}
                </div>
              </button>
            ))}
          </Flickity>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Deals;
