import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import { Transition } from '@headlessui/react';
import moment from 'moment';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import api from '../../../../../api/api';
import { Spinner } from '../../../../shared-components/Spinner';

function Loyalty() {
  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
  );
  const [loading, setLoading] = useState(false);
  const [loyalty, setLoyalty] = useState(null);

  useEffect(() => {
    setLoading(true);
    api.get('loyalty/history')
      .then((response) => {
        setLoading(false);
        setLoyalty(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="w-full">
      <div className="p-4 space-y-2">
        <div className="text-xl font-extrabold tracking-tight">{loading ? 'Puntos' : `Puntos: ${loyalty?.points.toLocaleString()}`}</div>
        <Transition
          show={loading}
          enter="transform transition duration-1000 ease-in-out delay-700"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transform duration-200 transition ease-in-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Spinner className="mx-auto" />
        </Transition>
        <Transition
          show={!loading}
          enter="transform transition duration-1000 ease-in-out delay-700"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transform duration-200 transition ease-in-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="divide-y divide-gray-200"
        >
          <Line
            className="mb-7"
            height={50}
            options={{
              plugins: {
                legend: {
                  display: false
                }
              }
            }}
            data={{
              datasets: [
                {
                  label: 'Puntos de lealtad',
                  fill: true,
                  backgroundColor: 'rgb(52, 211, 153)',
                  borderWidth: 5,
                  data: _.map(
                    loyalty?.balanceHistory,
                    (x) => ({
                      x: moment(x.date).format('MMM DD YYYY'),
                      y: x.points,
                    })
                  ),
                },
              ],
            }}
          />
          <div className="divide-y divide-gray-200">
            {_.map(loyalty?.history, (l) => (
              <div className="flex items-center justify-between space-x-4 p-4">
                <div>
                  <div className="text-xs font-semibold tracking-tight text-gray-800">
                    {l.confirmationCode?.length > 0 ? l.confirmationCode : l.notes}
                  </div>
                  <div className="text-xs">
                    <span>Puntos: </span>
                    <span className="text-gray-800 font-medium">
                      {l.points.toLocaleString()}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="text-xs">
                    {moment(l.createdAt).format('MMM DD YYYY - hh:mm A')}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Transition>
      </div>
    </div>
  );
}

export default Loyalty;
