import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import LogRocket from 'logrocket';
import is from 'is_js';
import { Transition } from '@headlessui/react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MenuIcon } from '@heroicons/react/outline';
import logo from '../../assets/images/logo.png';
import { orderAtom, sidebarAtom, userAtom } from '../../atoms/Atoms';
import Dropdown from './Dropdown';
import envConfig from '../../envConfig';

function Header() {
  const user = useRecoilValue(userAtom);
  const setSidebar = useSetRecoilState(sidebarAtom);
  const order = useRecoilValue(orderAtom);

  useEffect(() => {
    if (user && envConfig.UseLogRocket === 'true') {
      LogRocket.identify(user.id, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      });
    }
  }, [user]);

  return (
    <div className="bg-cyan-500 shadow-xl">
      <nav className="h-24 space-x-4 flex flex-row flex-shrink-0 justify-between items-center font-semibold text-xs bg-black bg-opacity-90 px-4">
        <div className="flex flex-1 items-center space-x-4">
          <NavLink exact to="/">
            <img alt="logo" src={logo} className="h-14 w-14" />
          </NavLink>
          <div className="hidden md:flex flex-row items-center h-full">
            <div className="flex flex-row items-center space-x-3">
              <NavLink
                exact
                to="/"
                className="transition duration-500 ease-in-out text-gray-300 hover:text-green-500"
                activeClassName="text-green-500 hover:text-green-500"
              >
                Inicio
              </NavLink>
              <NavLink
                to="/menu"
                className="transition duration-500 ease-in-out text-gray-300 hover:text-green-500"
                activeClassName="text-green-500 hover:text-green-500"
              >
                Menu
              </NavLink>
              {is.not.empty(order) && (
                <NavLink
                  to="/order"
                  disabled
                  className="transition duration-500 ease-in-out text-gray-300 hover:text-green-500"
                  activeClassName="text-green-500 hover:text-green-500"
                >
                  Orden
                </NavLink>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Transition
            show={!!user}
            enter="transform transition duration-700 ease-in-out"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transform duration-200 transition ease-in-out"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <Dropdown />
          </Transition>
          <button
            type="button"
            onClick={() => setSidebar(true)}
            className="flex md:hidden text-lg text-white hover:text-gray-300 transition duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
          >
            <MenuIcon className="w-5 h-5" />
          </button>
        </div>
      </nav>
    </div>
  );
}

export default Header;
