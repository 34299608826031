import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import is from 'is_js';
import { Transition } from '@headlessui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import 'moment/locale/es';
import { timezoneAtom } from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import Loader from '../../../shared-components/loader/Loader';
import Header from '../../../shared-components/Header';
import { orderStatus, orderType, renderDate } from '../../../../utils';

moment.locale('es');

function OrderDetails() {
  const history = useHistory();
  const { code } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const timezone = useRecoilValue(timezoneAtom);

  useEffect(() => {
    if (code) {
      api
        .get(`order/${code}`)
        .then((response) => {
          setOrder(response.data.data);
          console.log(response.data.data.items);
          setLoading(false);
        })
        .catch((error) => {
          console.log('error', error.message);
        });
    } else {
      history.push('/');
    }
  }, [code]);

  return (
    <div className="min-h-screen mx-auto no-scrollbar">
      <Header />
      <Transition
        show={loading}
        enter="transform transition duration-500"
        enterFrom="opacity-0 scale-50"
        enterTo="opacity-100 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 scale-100 "
        leaveTo="opacity-0 scale-95"
        className="h-screen flex flex-col items-center justify-center space-y-2 text-xs text-gray-600"
      >
        <Loader color="bg-gray-400" />
      </Transition>
      <Transition
        show={!loading}
        enter="transform transition duration-500"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-full"
        className="container mx-auto px-4 py-12 flex flex-col max-w-lg"
      >
        <div className="font-bold text-center tracking-tight text-xl uppercase">
          Pedido
        </div>
        <div className="font-medium text-gray-500 text-center tracking-tight mb-4">
          {order?.confirmationCode}
        </div>
        <div className="text-sm text-gray-500 border grid grid-cols-3 divide-x">
          <div className="divide-y divide-gray-200">
            <div className="py-2 px-4">
              <div className="text-xs">Tienda:</div>
              <div className="text-gray-800">
                {order?.businessName}
              </div>
            </div>
          </div>
          <div className="divide-y divide-gray-200">
            <div className="py-2 px-4">
              <div className="text-xs">Cliente:</div>
              <div className="text-gray-800">
                {order?.customerName}
              </div>
            </div>
          </div>
          <div className="divide-y divide-gray-200">
            <div className="py-2 px-4">
              <div className="text-xs">Servicio:</div>
              <div className="text-gray-800">
                {orderType(order?.type)}
              </div>
            </div>
          </div>
        </div>
        <div className="text-sm text-gray-500 border grid grid-cols-2 divide-x mb-4">
          <div className="divide-y divide-gray-200">
            <div className="py-2 px-4">
              <div className="text-xs">Hora de Servicio:</div>
              <div className="text-gray-800 truncate">
                {renderDate(order?.endSlotTime, timezone, 'dddd, D [de] MMMM, YYYY h:mm A')}
              </div>
            </div>
            <div className="py-2 px-4">
              <div className="text-xs">Codigo:</div>
              <div className="text-gray-800">
                {order?.confirmationCode}
              </div>
            </div>
            <div className="py-2 px-4">
              <div className="text-xs">Pago:</div>
              <div className="text-gray-800">{order?.paymentName}</div>
            </div>
            <div className="py-2 px-4">
              <div className="text-xs">Estado:</div>
              <div className="text-gray-800">
                {orderStatus(order?.status)}
              </div>
            </div>
            <div className="py-2 px-4">
              <div className="text-xs">Artículos:</div>
              <div className="text-gray-800">
                {order?.itemCount}
              </div>
            </div>
            {order?.discountCode && (
              <div className="py-2 px-4">
                <div className="text-xs">Código Promocional:</div>
                <div className="text-gray-800">
                  {order?.discountCode}
                </div>
              </div>
            )}
          </div>
          <div className="divide-y divide-gray-200">
            <div className="py-2 px-4">
              <div className="text-xs">Total:</div>
              <div className="text-gray-800">
                ${order?.orderTotal.toFixed(2)}
              </div>
            </div>
            <div className="py-2 px-4">
              <div className="text-xs">Subtotal:</div>
              <div className="text-gray-800">
                ${order?.subTotal.toFixed(2)}
              </div>
            </div>
            <div className="py-2 px-4">
              <div className="text-xs">Impuesto Municipal:</div>
              <div className="text-gray-800">
                ${order?.municipalTax.toFixed(2)}
              </div>
            </div>
            <div className="py-2 px-4">
              <div className="text-xs">Impuesto Estatal:</div>
              <div className="text-gray-800">
                ${order?.stateTax.toFixed(2)}
              </div>
            </div>
            <div className="py-2 px-4">
              <div className="text-xs">Costo por servicio:</div>
              <div className="text-gray-800">
                ${order?.processingFee.toFixed(2)}
              </div>
            </div>
            {order?.discountAmount > 0 && (
              <div className="py-2 px-4">
                <div className="text-xs">Descuento:</div>
                <div className="text-gray-800">
                  ${order?.discountAmount.toFixed(2)}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="text-sm font-semibold tracking-tight mb-2">
          Productos
        </div>
        <div className="text-xs border divide-y divide-gray-200 mb-4">
          {_.map(order?.items, (item) => (
            <div
              key={item.id}
              className="flex items-center space-x-2 py-2 px-4"
            >
              {item.itemImage && (
                <img
                  alt={item.name}
                  src={item.itemImage}
                  className="h-10 w-auto"
                />
              )}
              <div>
                <div>{item.name}</div>
                {item.specialInstruction && (
                  <div>{item.specialInstruction.replaceAll('|', ' | ')}</div>
                )}
                {item.addOns && is.not.empty(item.addOns) && (
                  <div>Extras: {_.map(item.addOns, (a) => a.completeName.trim()).toString().replaceAll(',', ' | ')}</div>
                )}
                {item.sides && is.not.empty(item.sides) && (
                  <div>Acompañantes: {_.map(item.sides, (s) => s.completeName.trim()).toString().replaceAll(',', ' | ')}</div>
                )}
                <div className="text-xs text-gray-400">
                  ${item.price.toFixed(2)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Transition>
    </div>
  );
}

export default OrderDetails;
