import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import is from 'is_js';
import axios from 'axios';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Formik } from 'formik';
import Header from '../../shared-components/Header';
import {
  cardAtom,
  notificationsAtom,
  stateAtom,
  tokenAtom,
  userAtom,
} from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';

function SignIn() {
  const history = useHistory();
  const setUser = useSetRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setCard = useSetRecoilState(cardAtom);
  const setState = useSetRecoilState(stateAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  return (
    <div className="flex flex-col h-screen bg-cyan-500 text-white">
      <Header />
      <div className="flex flex-1 justify-center bg-black bg-opacity-90 p-4">
        <div className="flex flex-col flex-grow-0 w-full md:w-1/2 lg:w-1/3">
          <div className="p-6 rounded-xl border border-white border-opacity-10">
            <div className="text-center text-4xl tracking-tight font-extrabold mb-1">
              Iniciar Sesión
            </div>
            <div className="text-center font-medium text-sm mb-4">
              <span>¿Aún no tienes cuenta?</span>
              <NavLink
                to="/signup"
                className="ml-1 font-medium text-green-500 hover:text-green-400 transition duration-500 ease-in-out"
              >
                Crear Cuenta
              </NavLink>
            </div>
            <div className="w-full mb-2">
              <Formik
                initialValues={{ email: '', password: '' }}
                validate={(values) => {
                  const errors = {};
                  if (is.empty(values.email)) {
                    errors.email =
                      'El correo electónico es un campo requerido.';
                  } else if (is.not.email(values.email)) {
                    errors.email =
                      'Lo sentimos, este correo electrónico no es valido. ¡Favor de escribir un correo electrónico valido!';
                  }

                  if (is.empty(values.password)) {
                    errors.password = 'La contraseña es un campo requerido.';
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  axios({
                    method: 'post',
                    url: `${envConfig.REACT_APP_API_URL}login`,
                    headers: {
                      'Ocp-Apim-Subscription-Key':
                        envConfig.REACT_APP_API_TOKEN,
                    },
                    data: {
                      Username: values.email,
                      Password: values.password,
                    },
                  })
                    .then((response) => {
                      if (response.data.success) {
                        setState(response.data.data.state);
                        console.log('emailConfirmed', response.data.data.state.profile.emailConfirmed);
                        console.log('phoneNumberConfirmed', response.data.data.state.profile.phoneNumberConfirmed);
                        console.log('ValidateUserEmail', envConfig.ValidateUserEmail);
                        console.log('ValidateUserPhone', envConfig.ValidateUserPhone);

                        let isValid = true;
                        if (envConfig.ValidateUserEmail === 'true' &&
                          !response.data.data.state.profile.emailConfirmed) {
                          isValid = false;
                        }
                        if (envConfig.ValidateUserPhone === 'true' &&
                          !response.data.data.state.profile.phoneNumberConfirmed) {
                          isValid = false;
                        }
                        setUser({
                          ...response.data.data.state.profile,
                          isValidated: isValid
                        });
                        localStorage.setItem(
                          'ant-state',
                          JSON.stringify(response.data.data.state)
                        );
                        localStorage.setItem(
                          'ant-user',
                          JSON.stringify({
                            ...response.data.data.state.profile,
                            isValidated: isValid
                          })
                        );
                        localStorage.setItem(
                          'ant-token',
                          JSON.stringify(response.data.data.accessToken)
                        );
                        localStorage.setItem(
                          'ant-cc-id',
                          response.data.data.state.profile.favPaymentMethodId
                        );
                        setCard(
                          response.data.data.state.profile.favPaymentMethodId
                        );
                        setToken(response.data.data.accessToken);
                        history.goBack();
                      } else {
                        setNotifications({
                          ...notifications,
                          signInError: response.data.error,
                        });
                      }
                      setSubmitting(false);
                    })
                    .catch((err) => {
                      setNotifications({
                        ...notifications,
                        signInError: err.message,
                      });
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form noValidate onSubmit={handleSubmit} className="text-sm">
                    <div className="mb-3">
                      <div className="font-semibold tracking-tight mb-2">
                        E-mail <span className="text-rose-400">*</span>
                      </div>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="juan@gmail.com"
                        className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && (
                        <div className="w-full font-semibold text-xs text-red-400 mt-1">
                          {errors.email}
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <div className="font-semibold tracking-tight mb-2">
                        Contraseña <span className="text-rose-400">*</span>
                      </div>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="****"
                        value={values.password}
                        className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                        onChange={handleChange}
                      />
                      {errors.password && touched.password && (
                        <div className="w-full font-semibold text-xs text-red-400 mt-1">
                          {errors.password}
                        </div>
                      )}
                    </div>
                    <div className="text-center font-medium text-sm my-4">
                      <span>¿Olvidó su contraseña?</span>
                      <NavLink
                        to="/password"
                        className="ml-1 font-medium text-green-500 hover:text-green-400 transition duration-500 ease-in-out"
                      >
                        Recuperar Contraseña
                      </NavLink>
                    </div>
                    <div className="flex space-x-2">
                      <button
                        className="w-full md:w-1/2 flex items-center justify-center text-center text-sm transition duration-500 ease-in-out bg-gray-700 hover:bg-rose-600 text-white font-semibold py-3 rounded"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          history.goBack();
                        }}
                      >
                        Cancelar
                      </button>
                      <button
                        className="w-full md:w-1/2 flex items-center justify-center text-center text-sm transition duration-500 ease-in-out bg-green-500 hover:bg-green-600 text-white font-semibold py-3 rounded"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <div>
                            <svg
                              className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              />
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              />
                            </svg>
                          </div>
                        )}
                        Iniciar Sesión
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
