import React from 'react';

function Textarea({
  id,
  name,
  value,
  onChange,
  required,
  placeholder,
  label,
  error,
  className,
  rows,
}) {
  return (
    <div
      className={`${className} text-xs tracking-tight space-y-1 dark:text-gray-400`}
    >
      {label && (
        <div className="font-semibold disabled:cursor-not-allowed disabled:opacity-50">
          {label} {required && <span className="text-red-400">*</span>}
        </div>
      )}
      <textarea
        id={id}
        name={name}
        rows={rows}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        className="placeholder-gray-300 text-gray-900 text-sm rounded-md p-3 w-full appearance-none focus:ring-1 focus:ring-emerald-200 border border-gray-200 focus:border-green-400 outline-none focus:outline-none transition ease-in-out duration-500"
      />
      {error && <div className="text-xs font-light text-red-400">{error}</div>}
    </div>
  );
}

export default Textarea;
