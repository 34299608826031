import React from 'react';
import Avatar from './shared-components/Avatar';
import SettingsForm from './shared-components/SettingsForm';
import AccountValidation from './shared-components/AccountValidation';
import Modal from './shared-components/Modal';

function Settings() {
  return (
    <div className="divide-y divide-gray-200">
      <Modal />
      <Avatar />
      <AccountValidation />
      <SettingsForm />
    </div>
  );
}

export default Settings;
