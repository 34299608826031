import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellOn } from '@fortawesome/pro-duotone-svg-icons';
import { Transition } from '@headlessui/react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { notificationsAtom } from '../../../../atoms/Atoms';

function OrderError() {
  const history = useHistory();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  return (
    <Transition
      show={notifications.orderError}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-4 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="divide-y max-w-sm w-full bg-white shadow-lg rounded-xl pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4 text-xs">
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
              <FontAwesomeIcon icon={faBellOn} className="text-red-500" />
            </div>
            <div className="flex-1">
              <p className=" font-medium text-gray-700">Sin Productos</p>
              <div className="mt-1 text-gray-400">
                ¡Lo sentimos! Su carrrito esta vacio y no podemos completar su
                orden. Puede visitar nuestro menú y añadir productos a su orden.
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={() => {
            history.push('/menu');
            setNotifications({ ...notifications, orderError: false });
          }}
          className="text-blue-500 p-4 w-full text-xs font-semibold tracking-tight bg-gray-100 hover:bg-gray-50 transition duration-500 ease-in-out"
        >
          Ver Menú
        </button>
      </div>
    </Transition>
  );
}

export default OrderError;
