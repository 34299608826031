import React, { useState } from 'react';
import _ from 'lodash';
import { Transition } from '@headlessui/react';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';
import { useRecoilState, useRecoilValue } from 'recoil';
import { locationAtom, tokenAtom } from '../../../../../../atoms/Atoms';
import api from '../../../../../../api/api';
import Loader from '../../../../../shared-components/loader/Loader';
import envConfig from '../../../../../../envConfig';

function Card({ location, index }) {
  const token = useRecoilValue(tokenAtom);
  const [locations, setLocations] = useRecoilState(locationAtom);
  const [loading, setLoading] = useState(false);

  const deleteLocation = () => {
    setLoading(true);

    api
      .delete(`user/location/${location.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        const array = JSON.parse(JSON.stringify(locations));
        setLocations({
          ...locations,
          locations: _.remove(array.locations, (l) => l.id !== location.id),
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Transition.Child
      as={React.Fragment}
      enter={`transform transition duration-1000 delay-${index}00`}
      enterFrom="opacity-0 -translate-x-20"
      enterTo="opacity-100 translate-x-0"
      leave="transform duration-1000 transition ease-in-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0 scale-95"
    >
      <li
        className={`${
          location?.id === locations.location?.id &&
          'shadow-xl transform scale-105'
        }
          col-span-1 flex flex-col text-center bg-white divide-y divide-gray-200 rounded-md`}
      >
        <div className="flex-1 flex flex-col p-8">
          <button
            type="button"
            onClick={() => {
              setLocations({
                ...locations,
                image_modal: true,
                image_current: location,
              });
            }}
            className="ring-0 focus:ring-0 outline-none focus:outline-none"
          >
            <img
              className={`${
                location?.id === locations.location?.id &&
                'ring-2 ring-offset-2 ring-amber-400'
              } transform transition duration-500 ease-in-out hover:scale-105 w-32 h-32 flex-shrink-0 mx-auto rounded-full shadow-md border border-gray-300`}
              src={`https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/pin-l+ff4013(${location.lon},${location.lat})/${location.lon},${location.lat},19,0,60/600x600?access_token=${envConfig.REACT_APP_MAPBOX_ACCESS_TOKEN}`}
              alt="Location"
            />
          </button>
          <h3 className="mt-6 text-gray-900 text-xs font-medium uppercase">
            {location.name}
          </h3>
          <div className="mt-1 flex-grow flex flex-col justify-between text-xs">
            <div className="text-gray-500">{location.addressLine}</div>
            <div className="mt-3">
              <button
                type="button"
                disabled={locations.location?.id === location?.id}
                onClick={() => {
                  setLocations({
                    ...locations,
                    set_favorite: true,
                    check_favorite: location,
                  });
                }}
                className={
                  locations.location?.id === location?.id
                    ? 'text-amber-500'
                    : 'text-gray-200 hover:text-gray-300 transition duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none'
                }
              >
                <StarIcon className="w-7 h-7" />
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="w-0 flex-1 flex">
              <button
                type="button"
                disabled={loading}
                onClick={deleteLocation}
                className="relative w-0 flex-1 inline-flex items-center disabled:opacity-50 justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 ring-0 focus:ring-0 outline-none focus:outline-none"
              >
                {loading ? (
                  <Loader color="bg-gray-500" />
                ) : (
                  <TrashIcon
                    className="w-4 h-4 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
            <div className="-ml-px w-0 flex-1 flex">
              <button
                type="button"
                onClick={() => {
                  setLocations({
                    ...locations,
                    profileModal: true,
                    profileCurrent: location,
                  });
                }}
                className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 ring-0 focus:ring-0 outline-none focus:outline-none"
              >
                <PencilIcon
                  className="w-4 h-4 text-gray-400"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
      </li>
    </Transition.Child>
  );
}

export default Card;
