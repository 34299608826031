import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Transition } from '@headlessui/react';
import Header from './shared-components/Header';
import Content from './shared-components/content/Content';
import Footer from './shared-components/Footer';
import Pickup from './shared-components/modals/Pickup';
import Delivery from './shared-components/modals/delivery/Delivery';
import {
  tokenAtom,
  storesAtom
} from '../../../atoms/Atoms';
import api from '../../../api/api';

function Home() {
  const token = useRecoilValue(tokenAtom);
  const setStores = useSetRecoilState(storesAtom);
  useEffect(() => {
    let headers = { };
    if (token) {
      headers = { Authorization: `Bearer ${token}` };
    }
    api
      .get('businesses', { headers })
      .then((response) => {
        // console.log(response.data.data.results);
        setStores(response.data.data.results);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      {/* Service options */}
      <Pickup />
      <Delivery />
      <div className="bg-cyan-500 text-white overflow-hidden">
        <div className="flex bg-black bg-opacity-90">
          <div className="h-screen flex flex-col flex-1 md:bg-none bg-pizza-image-5 bg-cover">
            <Header />
            <Content />
            <Footer />
          </div>
          <div className="hidden md:flex md:flex-col md:flex-1">
            <Transition
              appear
              show
              enter="transform transition duration-1000 ease-in-out delay-700"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transform duration-200 transition ease-in-out"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div className="shadow-inner h-screen bg-cover bg-center bg-pizza-image hover:scale-110 transform transition ease-in-out duration-1000" />
            </Transition>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
