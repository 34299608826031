import React from 'react';
import { useRecoilState } from 'recoil';
import { quantityAtom } from '../../../../../../atoms/Atoms';

function Counter() {
  const [quantity, setQuantity] = useRecoilState(quantityAtom);

  return (
    <div className="h-full flex items-center justify-between text-sm w-1/3 border rounded-full px-4">
      <button
        type="button"
        disabled={quantity === 1}
        className="font-semibold text-gray-600 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed appearance-none outline-none focus:outline-none transition duration-500 ease-in-out"
        onClick={() => {
          setQuantity(quantity === 1 ? 1 : quantity - 1);
        }}
      >
        −
      </button>
      <div className="flex flex-1 items-center justify-center font-medium text-xs">
        {quantity}
      </div>
      <button
        type="button"
        className="font-semibold text-gray-600 hover:text-gray-700 appearance-none outline-none focus:outline-none transition duration-500 ease-in-out"
        onClick={() => setQuantity(quantity + 1)}
      >
        +
      </button>
    </div>
  );
}

export default Counter;
