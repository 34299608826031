import React from 'react';
import { NavLink } from 'react-router-dom';
import { ChevronRightIcon, ShoppingCartIcon } from '@heroicons/react/outline';

function Breadcrumbs() {
  return (
    <div className="flex items-center space-x-2 text-xs tracking-tight font-bold justify-end">
      <NavLink
        to="/"
        className="hover:text-gray-400 transition duration-500 ease-in-out"
      >
        Inicio
      </NavLink>
      <ChevronRightIcon className="w-3 h-3" />
      <NavLink
        to="/menu"
        className="hover:text-gray-400 transition duration-500 ease-in-out"
      >
        Menú
      </NavLink>
      <ChevronRightIcon className="w-3 h-3" />
      <ShoppingCartIcon className="w-4 h-4 text-gray-400" />
    </div>
  );
}

export default Breadcrumbs;
