import moment from 'moment';

export const phoneNumber = (phone) => {
  const cleaned = `${phone}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

export const classNames = (...classes) => classes.filter(Boolean).join(' ');

export const subtotalCalc = (order) => {
  let subtotal = 0;
  for (let i = 0; i < order.length; i++) {
    subtotal += order[i].price;
  }
  return subtotal;
};

export const munTaxCalc = (order) => subtotalCalc(order) * 0.01;

export const stateTaxCalc = (order) => subtotalCalc(order) * 0.06;

export const totalCalc = (order) =>
  subtotalCalc(order) + munTaxCalc(order) + stateTaxCalc(order);

export const paymentType = (type) => {
  switch (type) {
    case 0:
      return 'Stripe';
    case 1:
      return 'ATH Movil';
    case 7:
      return 'ATH';
    case 3:
      return 'Other';
    case 4:
      return 'EBT';
    case 5:
      return 'Paypal';
    case 10:
      return 'MCS';
    case 11:
      return 'MMM';
    case 16:
      return 'SSS';
    case 17:
      return 'Humana';
    default:
      return type;
  }
};

export const orderType = (type) => {
  switch (type) {
    case 0:
      return 'Recogido';
    case 1:
      return 'Entrega';
    case 6:
      return 'Deli Pickup';
    case 7:
      return 'Deli Delivery';
    default:
      return 'Undefined';
  }
};

export const orderStatus = (status) => {
  switch (status) {
    case 0:
      return 'Esperando Selección';
    case 2:
      return 'Entrega fue Asignada';
    case 3:
      return 'Esperando ser preparado';
    case 6:
      return 'Recogido por Cliente';
    case 7:
      return 'Entregado';
    case 8:
      return 'Cancelado por tienda';
    case 9:
      return 'Cancelado';
    case 10:
      return 'Pago fue declinado';
    case 5:
      return 'De camino a ser entregado';
    case 16:
      return 'En proceso de selección';
    case 17:
      return 'Selección finalizada';
    case 18:
      return 'En proceso de empaque';
    case 20:
      return 'Listo para ser recogido';
    case 21:
      return 'Esperando asignación de conductor';
    case 27:
      return 'Pago de paypal pendiente';
    case 30:
      return 'Pago pendiente';
    case 32:
      return 'Bajo revisión';
    case 33:
      return 'IVU Control Failure';
    default:
      return 'Desconocido';
  }
};

function prepareDate(date, timezone) {
  if (date) {
    const dateString = date.toString();
    if (dateString.includes('Z') && dateString.includes('T')) {
      return moment.utc(`${date.replace('T', ' ').replace('Z', '')}+0000`)
        .tz(timezone);
    }
    return moment.utc(date).tz(timezone);
  }
  return null;
}

export const renderDate = (date, timezone, format) => {
  try {
    if (date) {
      const momentDate = prepareDate(date, timezone);
      let formatToUse = 'ddd MMMM Do, YYYY hh:mm a';
      if (format) {
        formatToUse = format;
      }
      return momentDate.isValid() ? momentDate.format(formatToUse) : date;
    }
    return '';
  } catch (error) {
    return date;
  }
};
