/* eslint-disable indent */
import React from 'react';
import axios from 'axios';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { NavLink, useHistory } from 'react-router-dom';
import Cleave from 'cleave.js/react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Header from '../../shared-components/Header';
import {
  cardAtom,
  envAtom,
  notificationsAtom,
  tokenAtom,
  stateAtom,
  userAtom,
} from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';

function SignUp({ referral }) {
  const history = useHistory();
  const env = useRecoilValue(envAtom);
  const setUser = useSetRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setState = useSetRecoilState(stateAtom);
  const setCard = useSetRecoilState(cardAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      gender: 5,
      language: 5,
      password: '',
      passwordConfirmation: '',
      friendCode: referral,
      allowMarketing: true,
      acceptTos: false
    },
    validationSchema: Yup.object().shape({
      fname: Yup.string().required('Este campo es requerido.'),
      lname: Yup.string().required('Este campo es requerido.'),
      email: Yup.string()
        .email('Favor de ingresar un email valido.')
        .required('Este campo es requerido.'),
      phone: Yup.string().required('Este campo es requerido.'),
      password: Yup.string().required('Este campo es requerido.'),
      passwordConfirmation: Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          'Las contraseñas deben ser iguales.'
        )
        .required('Este campo es requerido.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      axios({
        method: 'post',
        url: `${env.url}user/register`,
        data: {
          Email: values.email,
          FirstName: values.fname,
          LastName: values.lname,
          PhoneNumber: values.phone,
          Password: values.password,
          Birthdate: new Date(),
          Gender: parseInt(values.gender, 2),
          PreferredLanguage: parseInt(values.language, 2),
          FriendCode: values.friendCode,
          AllowMarketing: values.allowMarketing
        },
        headers: {
          'Ocp-Apim-Subscription-Key': envConfig.REACT_APP_API_TOKEN,
        },
      })
        .then((response) => {
          if (response.data.error) {
            setNotifications({
              ...notifications,
              signUpError: response.data.error,
            });
          } else {
            setNotifications({
              ...notifications,
              signUpSuccess: true,
            });
            formik.resetForm();
            let isValid = true;
            if (envConfig.ValidateUserEmail === 'true' &&
              !response.data.data.state.profile.emailConfirmed) {
              isValid = false;
            }
            if (envConfig.ValidateUserPhone === 'true' &&
              !response.data.data.state.profile.phoneNumberConfirmed) {
              isValid = false;
            }
            setUser({
              ...response.data.data.state.profile,
              isValidated: isValid
            });
            localStorage.setItem(
              'ant-state',
              JSON.stringify(response.data.data.state)
            );
            localStorage.setItem(
              'ant-user',
              JSON.stringify({
                ...response.data.data.state.profile,
                isValidated: isValid
              })
            );
            localStorage.setItem(
              'ant-token',
              JSON.stringify(response.data.data.accessToken)
            );
            localStorage.setItem(
              'ant-cc-id',
              JSON.stringify(
                response.data.data.state.profile.favPaymentMethodId
              )
            );
            setState(response.data.data.state);
            setCard(response.data.data.state.profile.favPaymentMethodId);
            setToken(response.data.data.accessToken);
            history.goBack();
          }
          setSubmitting(false);
        })
        .catch((error) => {
          setNotifications({ ...notifications, signUpError: error.message });
          setSubmitting(false);
        });
    },
  });

  return (
    <div className="flex flex-col min-h-screen bg-cyan-500 text-white">
      <Header />
      <div className="flex flex-1 justify-center bg-black bg-opacity-90 p-4">
        <div className="flex flex-col flex-grow-0 w-full md:w-2/3 lg:w-2/3">
          <div className="p-6 rounded-xl border border-white border-opacity-10">
            <div className="text-4xl tracking-tight font-extrabold mb-1">
              Crear Cuenta
            </div>
            <div className="font-medium text-sm mb-4">
              <span>¿Ya tienes cuenta?</span>
              <NavLink
                to="/signin"
                className="ml-1 font-bold text-green-400 hover:text-green-400 transition duration-500 ease-in-out"
              >
                Iniciar Sesión
              </NavLink>
            </div>
            <form
              noValidate
              autoComplete="on"
              onSubmit={formik.handleSubmit}
              className="text-sm"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div>
                  <div className="font-semibold tracking-tight mb-2">
                    Nombre <span className="text-rose-400">*</span>
                  </div>
                  <input
                    required
                    type="text"
                    name="fname"
                    id="fname"
                    placeholder="Juan"
                    autoComplete="given-name"
                    autoCapitalize="true"
                    className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                    value={formik.values.fname}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.fname && formik.touched.fname && (
                    <div className="w-full font-semibold text-xs text-rose-400 mt-1">
                      {formik.errors.fname}
                    </div>
                  )}
                </div>
                <div>
                  <div className="font-semibold tracking-tight mb-2">
                    Apellido <span className="text-rose-400">*</span>
                  </div>
                  <input
                    required
                    type="text"
                    name="lname"
                    id="lname"
                    autoComplete="family-name"
                    placeholder="del Pueblo"
                    autoCapitalize="true"
                    className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                    value={formik.values.lname}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.lname && formik.touched.lname && (
                    <div className="w-full font-semibold text-xs text-rose-400 mt-1">
                      {formik.errors.lname}
                    </div>
                  )}
                </div>
                <div>
                  <div className="font-semibold tracking-tight mb-2">
                    E-mail <span className="text-rose-400">*</span>
                  </div>
                  <input
                    required
                    type="email"
                    name="email"
                    id="email"
                    placeholder="juan@gmail.com"
                    autoComplete="email"
                    value={formik.values.email}
                    className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                    onChange={formik.handleChange}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <div className="w-full font-semibold text-xs text-rose-400 mt-1">
                      {formik.errors.email}
                    </div>
                  )}
                </div>
                <div>
                  <div className="font-semibold tracking-tight mb-2">
                    Phone <span className="text-rose-400">*</span>
                  </div>
                  <Cleave
                    required
                    id="phone"
                    name="phone"
                    type="text"
                    autoComplete="tel"
                    options={{
                      phone: true,
                      phoneRegionCode: 'PR',
                    }}
                    value={formik.values.phone}
                    onChange={(e) => {
                      formik.setFieldValue('phone', e.target.rawValue);
                    }}
                    placeholder="787 777 7777"
                    className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  />
                  {formik.errors.phone && formik.touched.phone && (
                    <div className="w-full font-semibold text-xs text-rose-400 mt-1">
                      {formik.errors.phone}
                    </div>
                  )}
                </div>
                <div>
                  <div className="font-semibold tracking-tight mb-2">
                    Género
                  </div>
                  <select
                    id="gender"
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  >
                    <option value={5} disabled>
                      Seleccionar género...
                    </option>
                    <option value={0}>Masculino</option>
                    <option value={1}>Femenino</option>
                    <option value={2}>Prefiero no contestar</option>
                  </select>
                </div>
                <div>
                  <div className="font-semibold tracking-tight mb-2">
                    Lenguaje
                  </div>
                  <select
                    id="language"
                    name="language"
                    value={formik.values.language}
                    onChange={formik.handleChange}
                    className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  >
                    <option value={5} disabled>
                      Seleccionar lenguage...
                    </option>
                    <option value={1}>Español</option>
                    <option value={0}>English</option>
                  </select>
                </div>
                <div>
                  <div className="font-semibold tracking-tight mb-2">
                    Contraseña <span className="text-rose-400">*</span>
                  </div>
                  <input
                    required
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    placeholder="***"
                    className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  />
                  {formik.errors.password && formik.touched.password && (
                    <div className="w-full font-semibold text-xs text-rose-400 mt-1">
                      {formik.errors.password}
                    </div>
                  )}
                </div>
                <div>
                  <div className="font-semibold tracking-tight mb-2">
                    Confirmar Contraseña{' '}
                    <span className="text-rose-400">*</span>
                  </div>
                  <input
                    required
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    type="password"
                    autoComplete="password"
                    value={formik.values.passwordConfirmation}
                    onChange={formik.handleChange}
                    placeholder="***"
                    className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  />
                  {formik.errors.passwordConfirmation &&
                    formik.touched.passwordConfirmation && (
                      <div className="w-full font-semibold text-xs text-rose-400 mt-1">
                        {formik.errors.passwordConfirmation}
                      </div>
                    )}
                </div>
              </div>
              <div className="flex items-center">
                <input
                  value={formik.values.allowMarketing}
                  checked={formik.values.allowMarketing}
                  name="allowMarketing"
                  type="checkbox"
                  onChange={formik.handleChange}
                  className="w-4 h-4 text-accent bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="marketing-checkbox" className="ml-2 text-sm font-medium text-acceptTos">
                  Al marcar, acepta recibir correos electrónicos y SMS de marketing.
                </label>
              </div>
              <div className="flex items-center mt-2">
                <input
                  value={formik.values.acceptTos}
                  name="acceptTos"
                  type="checkbox"
                  onChange={formik.handleChange}
                  className="w-4 h-4 text-accent bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="marketing-checkbox" className="ml-2 text-sm font-medium text-white">
                  Al marcar, confirmas que lees y aceptas los Términos de Servicio: <a href={envConfig.TosUrl} target="_blank" rel="noreferrer" className="text-accent hover:underline text-sm font-regular tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">Ver aquí</a>
                </label>
              </div>
              <div className="flex space-x-2 mt-4">
                <button
                  className="w-full md:w-1/2 flex items-center justify-center text-center text-sm transition duration-500 ease-in-out bg-gray-700 hover:bg-rose-600 text-white font-semibold py-3 rounded"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    history.goBack();
                  }}
                >
                  Cancelar
                </button>
                <button
                  className="disabled:opacity-50 w-full md:w-1/2 flex items-center justify-center text-center text-sm transition duration-500 ease-in-out bg-green-500 hover:bg-green-600 text-white font-semibold py-3 rounded"
                  type="submit"
                  disabled={formik.isSubmitting || !formik.values.acceptTos}
                >
                  {formik.isSubmitting && (
                    <div>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    </div>
                  )}
                  Crear Cuenta
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
