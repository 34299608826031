import React from 'react';
import TokenError from './shared-components/TokenError';
import CreditCardSuccess from './shared-components/CreditCardSuccess';
import CreditCardError from './shared-components/CreditCardError';
import CreditCardDeletesSuccess from './shared-components/CreditCardDeletesSuccess';
import SignUpError from './shared-components/SignUpError';
import SignUpSuccess from './shared-components/SignUpSuccess';
import ForgotSuccess from './shared-components/ForgotSuccess';
import UserError from './shared-components/UserError';
import OrderError from './shared-components/OrderError';
import StoreError from './shared-components/StoreError';
import SignInError from './shared-components/SignInError';
import GenericError from './shared-components/GenericError';
import UserValidationError from './shared-components/UserValidationError';
import GenericMessage from './shared-components/GenericMessage';

function Notifications() {
  return (
    <div className="fixed z-50 inset-0 flex items-end justify-center pointer-events-none p-4 sm:items-start sm:justify-end">
      <div className="space-y-4">
        <TokenError />
        <CreditCardSuccess />
        <CreditCardError />
        <CreditCardDeletesSuccess />
        <SignInError />
        <SignUpError />
        <SignUpSuccess />
        <ForgotSuccess />
        <UserError />
        <UserValidationError />
        <OrderError />
        <StoreError />
        <GenericError />
        <GenericMessage />
      </div>
    </div>
  );
}

export default Notifications;
