import React from 'react';
import { Transition } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import { moreAtom } from '../../../../../../../atoms/Atoms';
import BusinessMap from './shared-components/BusinessMap';
import BusinessHours from './shared-components/BusinessHours';

function Business() {
  const open = useRecoilValue(moreAtom);
  return (
    <Transition
      show={open}
      enter="transition ease-in-out duration-500 transform"
      enterFrom="opacity-0 -translate-y-10"
      enterTo="opacity-1 translate-y-0"
      leave="transition ease-in-out duration-100 transform"
      leaveFrom="opacity-1 translate-y-0"
      leaveTo="opacity-0 -translate-y-10"
      className="grid grid-cols-1 md:grid-cols-2 gap-4 border-t border-b py-8 mt-8"
    >
      <BusinessHours />
      <BusinessMap />
    </Transition>
  );
}

export default Business;
