import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { motion } from 'framer-motion';
import { useRecoilState } from 'recoil';
import { XIcon } from '@heroicons/react/outline';
import { locationAtom } from '../../../../../../atoms/Atoms';
import envConfig from '../../../../../../envConfig';

function Modal() {
  const [locations, setLocations] = useRecoilState(locationAtom);

  const closeModal = () => {
    setLocations({ ...locations, image_modal: false });
  };

  return (
    <Transition show={locations.image_modal} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="flex justify-center items-center h-screen">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="bg-gray-900 absolute inset-0 backdrop-filter backdrop-blur-sm bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-500"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            afterLeave={() => {
              setLocations({ ...locations, image_current: null });
            }}
          >
            <div className="flex flex-col w-full h-5/6 md:h-auto w-full max-w-lg my-8 divide-y divide-gray-200 transition-all transform bg-white shadow-xl duration-500 easy-in-out">
              {/* HEADER */}
              <Dialog.Title
                as="h3"
                className="flex items-center space-x-2 px-4 justify-between text-lg font-medium h-20"
              >
                {/* HEADER TITLE */}
                <div>Localidad</div>
                {/* HEADER ACTIONS */}
                <div className="flex items-center space-x-2">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="flex items-center justify-center text-gray-500 hover:text-gray-600 ring-0 focus:ring-0 bg-gray-100 w-10 h-10 rounded-full outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                  >
                    <XIcon className="w-5 h-5" />
                  </button>
                </div>
              </Dialog.Title>
              <div className="flex flex-col flex-1 overflow-y-auto no-scrollbar p-4 space-y-4">
                <motion.div
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -20, opacity: 0 }}
                  transition={{
                    type: 'spring',
                    stiffness: 600,
                    damping: 15,
                    mass: 1,
                    delay: 0.5,
                  }}
                >
                  <div className="text-sm">{locations.image_current?.name}</div>
                  <div className="text-xs text-gray-500">
                    {locations.image_current?.addressLine}
                  </div>
                </motion.div>
                <motion.div
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.5, opacity: 0 }}
                  transition={{
                    type: 'spring',
                  }}
                  className="bg-gray-100 h-96 bg-center bg-cover"
                  style={{
                    backgroundImage: `url('https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/pin-s+ff4013(${locations.image_current?.lon},${locations.image_current?.lat})/${locations.image_current?.lon},${locations.image_current?.lat},15,0,0/600x600?access_token=${envConfig.REACT_APP_MAPBOX_ACCESS_TOKEN}')`,
                  }}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
