import React, { useEffect } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import {
  ChevronRightIcon,
  CogIcon,
  CreditCardIcon,
  KeyIcon,
  LocationMarkerIcon,
  StarIcon,
  ReceiptTaxIcon,
} from '@heroicons/react/outline';
import { useRecoilState, useRecoilValue } from 'recoil';
import api from '../../../api/api';
import {
  locationAtom,
  paymentMethodsAtom,
  profileAtom,
  tokenAtom,
} from '../../../atoms/Atoms';
import Header from '../../shared-components/Header';
import Alert from './shared-components/orders/shared-components/Alert';
import Modal from './shared-components/orders/shared-components/Modal';
import Password from './shared-components/password/Password';
import Payment from './shared-components/payment/Payment';
import Settings from './shared-components/settings/Settings';
import Sidebar from './shared-components/Sidebar';
import Orders from './shared-components/orders/Orders';
import Locations from './shared-components/locations/Locations';
import Loyalty from './shared-components/loyalty/Loyalty';

function Profile() {
  const history = useHistory();
  const { section } = useParams();
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [payments, setPayments] = useRecoilState(paymentMethodsAtom);
  const [locations, setLocations] = useRecoilState(locationAtom);
  const token = useRecoilValue(tokenAtom);

  const navigation = [
    {
      name: 'Lealtad',
      path: 'loyalty',
      icon: StarIcon,
    },
    {
      name: 'Pedidos',
      path: 'orders',
      icon: ReceiptTaxIcon,
      badge: profile.orders?.length || 0,
    },
    { name: 'Configuración', path: 'settings', icon: CogIcon },
    { name: 'Contraseña', path: 'password', icon: KeyIcon },
    {
      name: 'Métodos de Pago',
      path: 'payment',
      icon: CreditCardIcon,
      badge: payments.payments.length,
    },
    {
      name: 'Mis Localidades',
      path: 'locations',
      icon: LocationMarkerIcon,
      badge: locations.locations.length,
    },
  ];

  const getOrders = () => {
    setProfile({
      ...profile,
      ordersLoading: true,
    });

    api
      .post(
        '/user/orders',
        {
          Page: 0,
          Size: 10000,
          Query: '',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          setProfile({
            ...profile,
            ordersLoading: false,
            ordersError: response.data.error,
          });
        } else {
          setProfile({
            ...profile,
            ordersLoading: false,
            ordersError: null,
            orders: response.data.data.results,
          });
        }
      })
      .catch((error) => {
        setProfile({
          ...profile,
          ordersLoading: false,
          ordersError: error.message,
        });
      });
  };

  const getPayments = () => {
    setPayments({
      ...payments,
      loading: true,
      error: null,
    });

    api
      .get('user/cards', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setPayments({
            ...payments,
            loading: false,
            error: response.data.error,
          });
        } else {
          setPayments({
            ...payments,
            payments: response.data.data.results,
            loading: false,
            error: null,
          });
        }
      })
      .catch((error) => {
        setPayments({
          ...payments,
          loading: false,
          error: error.message,
        });
      });
  };

  const getLocations = () => {
    api
      .get('user/locations', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setLocations({
            ...locations,
            loading: false,
            error: response.data.error,
          });
        }

        setLocations({
          ...locations,
          locations: response.data.data.results,
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        setLocations({
          ...locations,
          loading: false,
          error: error.message,
        });
      });
  };

  useEffect(() => {
    if (!section) {
      history.push('/profile/orders');
    }
  }, [section]);

  useEffect(() => {
    if (token) {
      getOrders();
      getPayments();
      getLocations();
    }
  }, [token]);

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Modal />
      <Alert />
      <Sidebar navigation={navigation} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="block md:hidden">
          <Header />
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto no-scrollbar focus:outline-none bg-gray-100">
          <div className="p-4 bg-white shadow-lg sticky top-0 z-20 backdrop-filter backdrop-blur-lg bg-opacity-50">
            <div className="text-2xl font-extrabold tracking-tight mb-2">
              {section === 'orders' && 'Pedidos'}
              {section === 'settings' && 'Configuración'}
              {section === 'password' && 'Contraseña'}
              {section === 'payment' && 'Pagos'}
              {section === 'locations' && 'Mis Localidades'}
              {section === 'loyalty' && 'Lealtad'}
            </div>
            <div className="flex items-center text-xs text-gray-400">
              <NavLink
                to="/"
                className="font-medium text-green-500 hover:text-green-400"
              >
                Inicio
              </NavLink>
              <ChevronRightIcon className="w-3 h-3" />
              <div>Perfil</div>
              <ChevronRightIcon className="w-3 h-3" />
              {section === 'orders' && <div>Pedidos</div>}
              {section === 'settings' && <div>Configuración</div>}
              {section === 'password' && <div>Contraseña</div>}
              {section === 'payment' && <div>Pagos</div>}
              {section === 'locations' && <div>Mis Localidades</div>}
              {section === 'loyalty' && <div>Lealtad</div>}
            </div>
          </div>
          {section === 'orders' && <Orders />}
          {section === 'settings' && <Settings />}
          {section === 'password' && <Password />}
          {section === 'payment' && <Payment />}
          {section === 'locations' && <Locations />}
          {section === 'loyalty' && <Loyalty />}
        </main>
      </div>
    </div>
  );
}

export default Profile;
