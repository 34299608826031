import axios from 'axios';
import envConfig from '../envConfig';

export default async function getStoreMenu(store, setStore) {
  return new Promise((resolve, reject) => {
    const localStore = localStorage.getItem('ant-store') ? JSON.parse(localStorage.getItem('ant-store')) : null;
    let id = store ? store.id : null;
    if (id === null && localStore) {
      setStore(localStore);
      id = localStore.id;
    }

    axios
      .get(`${envConfig.REACT_APP_API_URL}items/${id}`, {
        headers: {
          'Ocp-Apim-Subscription-Key': envConfig.REACT_APP_API_TOKEN,
        },
      })
      .then((response) => {
        resolve(response.data.data.departments);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
