import React from 'react';
import Service from './shared-components/Service';
import Categories from './shared-components/Categories';
import Cart from './shared-components/Cart';
import Search from './shared-components/Search';

function Toolbar() {
  return (
    <div className="sticky top-0 z-20 bg-gray-100 backdrop-filter backdrop-blur-lg bg-opacity-50 shadow-lg transition-colors duration-200 ease-linear">
      <div className="container mx-auto px-4 flex flex-1 h-full text-xs font-semibold divide-x">
        <Service />
        <Categories />
        <Search />
        <Cart />
      </div>
    </div>
  );
}

export default Toolbar;
