import React from 'react';
import { NavLink } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import { LoginIcon, UserAddIcon } from '@heroicons/react/outline';
import { userAtom } from '../../../../atoms/Atoms';
import Dropdown from '../../../shared-components/Dropdown';

function Header() {
  const user = useRecoilValue(userAtom);

  return (
    <Transition
      appear
      show
      enter="transform transition duration-700 ease-in-out"
      enterFrom="opacity-0 -translate-x-full"
      enterTo="opacity-100 translate-x-0"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 -translate-x-0"
      leaveTo="opacity-0 -translate-x-full"
      className="flex items-center h-20 px-4 absolute top-0 right-0 md:left-0 text-right md:text-left"
    >
      {user && <Dropdown reverse />}
      {!user && (
        <div className="flex items-center space-x-2">
          <NavLink
            to="/signin"
            className="flex items-center space-x-2 px-3 py-2 border border-white md:border-opacity-25 rounded font-semibold text-xs text-white hover:bg-green-500 hover:border-green-500 transition duration-500 ease-in-out outline-none focus:outline-none cursor-pointer"
          >
            <LoginIcon className="h-4 w-4" />
            <span>Iniciar Sesión</span>
          </NavLink>
          <NavLink
            to="/signup"
            className="flex items-center space-x-2 px-3 py-2 border border-white md:border-opacity-25 rounded font-semibold text-xs text-white hover:bg-green-500 hover:border-green-500 transition duration-500 ease-in-out outline-none focus:outline-none cursor-pointer"
          >
            <UserAddIcon className="h-4 w-4" />
            <span>Crear Cuenta</span>
          </NavLink>
        </div>
      )}
    </Transition>
  );
}

export default Header;
