import React from 'react';
import { useLocalStorage } from 'react-use';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../../../../api/api';
import { tokenAtom, userAtom } from '../../../../../../atoms/Atoms';
import Form from '../../../../../shared-components/form/Form';
import Input from '../../../../../shared-components/form/Input';
import Select from '../../../../../shared-components/form/Select';
import CleaveInput from '../../../../../shared-components/form/CleaveInput';
import Button from '../../../../../shared-components/buttons/Button';

function SettingsForm() {
  const setUser = useSetRecoilState(userAtom);
  const token = useRecoilValue(tokenAtom);
  const [user] = useLocalStorage('ant-user');
  console.log('User-data', user);
  const formik = useFormik({
    initialValues: {
      name: user ? user.firstName : '',
      lastName: user ? user.lastName : '',
      email: user ? user.email : '',
      phone: user ? user.phoneNumber : '',
      gender: user ? user.gender : '',
      language: user ? user.preferredLanguage : '',
      allowSMS: user ? user.allowSMS : false,
      allowEmails: user ? user.allowEmails : false,
      allowPush: user ? user.allowPush : false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es requerido.'),
      lastName: Yup.string().required('Este campo es requerido.'),
      email: Yup.string()
        .email('Favor de ingresar un email valido.')
        .required('Este campo es requerido.'),
      phone: Yup.string().required('Este campo es requerido.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      // const val = {
      //   Email: values.email,
      //   FirstName: values.name,
      //   LastName: values.lastName,
      //   PhoneNumber: values.phone,
      //   Password: values.password,
      //   Birthdate: new Date(),
      //   Gender: parseInt(values.gender, 10),
      //   PreferredLanguage: parseInt(values.language, 10),
      //   allowSMS: values.allowSMS,
      //   AllowEmails: values.allowEmails,
      //   AllowPush: true,
      // };
      api
        .post(
          'user/profile',
          {
            Email: values.email,
            FirstName: values.name,
            LastName: values.lastName,
            PhoneNumber: values.phone,
            Password: values.password,
            Birthdate: new Date(),
            Gender: parseInt(values.gender, 10),
            PreferredLanguage: parseInt(values.language, 10),
            allowSMS: values.allowSMS,
            AllowEmails: values.allowEmails,
            AllowPush: true,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
          } else {
            const profile = JSON.parse(localStorage.getItem('ant-user'));
            profile.firstName = response.data.data.firstName;
            profile.lastName = response.data.data.lastName;
            profile.email = response.data.data.email;
            profile.phoneNumber = response.data.data.phoneNumber;
            profile.gender = response.data.data.gender;
            profile.preferredLanguage = response.data.data.preferredLanguage;
            profile.allowSMS = response.data.data.allowSMS;
            profile.allowEmails = response.data.data.allowEmails;
            profile.allowPush = true;
            profile.completeName = `${response.data.data.firstName} ${response.data.data.lastName}`;
            localStorage.setItem('ant-user', JSON.stringify(profile));
            setUser(response.data.data);
          }
          setSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
        });
    },
  });

  return (
    <div className="p-4 space-y-2">
      <div className="text-xl font-extrabold tracking-tight">Editar Perfil</div>
      <div className="text-sm">
        Actualiza la información de tu perfil aquí. Esta información será
        utilizada para comunicarnos contigo mientras preparamos tu pedido.
      </div>
      <Form>
        <Input
          label="Nombre"
          required
          type="text"
          name="name"
          id="name"
          placeholder="Juan"
          autoComplete="given-name"
          autoCapitalize="true"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={
            formik.errors.name && formik.touched.name && formik.errors.name
          }
        />
        <Input
          label="Apellido"
          required
          type="text"
          name="lastName"
          id="lastName"
          autoComplete="family-name"
          placeholder="del Pueblo"
          autoCapitalize="true"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={
            formik.errors.lastName &&
            formik.touched.lastName &&
            formik.errors.lastName
          }
        />
        <Input
          label="Correo Electrónico"
          required
          type="email"
          name="email"
          id="email"
          placeholder="juan@gmail.com"
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={
            formik.errors.email && formik.touched.email && formik.errors.email
          }
        />
        <CleaveInput
          label="Teléfono"
          required
          id="phone"
          name="phone"
          type="text"
          autoComplete="tel"
          options={{
            phone: true,
            phoneRegionCode: 'PR',
          }}
          value={formik.values.phone}
          onChange={(e) => {
            formik.setFieldValue('phone', e.target.rawValue);
          }}
          placeholder="787 777 7777"
          error={
            formik.errors.phone && formik.touched.phone && formik.errors.phone
          }
        />
        <Select
          label="Género"
          id="gender"
          name="gender"
          value={formik.values.gender}
          onChange={formik.handleChange}
          options={[
            {
              key: '5',
              value: 5,
              text: 'Seleccionar género...',
              disabled: true,
            },
            {
              key: '0',
              value: 0,
              text: 'Masculino',
            },
            {
              key: '1',
              value: 1,
              text: 'Femenino',
            },
            {
              key: '2',
              value: 2,
              text: 'Prefiero no contestar',
            },
          ]}
        />
        <Select
          label="Lenguaje"
          id="language"
          name="language"
          value={formik.values.language}
          onChange={formik.handleChange}
          options={[
            {
              key: '0',
              value: 0,
              text: 'English',
            },
            {
              key: '1',
              value: 1,
              text: 'Español',
            },
          ]}
        />
        <div>
          <div className="text-xl font-extrabold tracking-tight">Comunicaciones</div>
          <div className="text-sm">
            Autorización para envio de mensajes de promoción.
          </div>
        </div>
        <br />
        <div className="flex items-center">
          <input
            value={formik.values.allowSMS}
            checked={formik.values.allowSMS}
            name="allowSMS"
            type="checkbox"
            onChange={formik.handleChange}
            error={
              formik.errors.name && formik.touched.name && formik.errors.name
            }
            className="w-6 h-6 text-accent bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label htmlFor="marketing-checkbox" className="ml-4 text-base font-medium text-acceptTos">
            Mensajes de Texto
          </label>
        </div>
        <br />
        <br />
        <div className="flex items-center">
          <input
            value={formik.values.allowEmails}
            checked={formik.values.allowEmails}
            name="allowEmails"
            type="checkbox"
            onChange={formik.handleChange}
            error={
              formik.errors.name && formik.touched.name && formik.errors.name
            }
            className="w-6 h-6 text-accent bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label htmlFor="marketing-checkbox" className="ml-4 text-base font-medium text-acceptTos">
            Correo electrónico
          </label>
        </div>
        {/* <Input
          label="Notificaciones Push"
          type="checkbox"
          name="allowPush"
          id="allowPush"
          disabled
          value={formik.values.allowPush}
          onChange={formik.handleChange}
          error={
            formik.errors.name && formik.touched.name && formik.errors.name
          }
        /> */}
      </Form>
      <Button
        text="Cancelar"
        textColor="text-gray-400"
        onClick={(e) => {
          e.preventDefault();
          formik.setValues({
            name: user ? user.firstName : '',
            lastName: user ? user.lastName : '',
            email: user ? user.email : '',
            phone: user ? user.phoneNumber : '',
            gender: user ? user.gender : '',
            language: user ? user.preferredLanguage : '',
          });
        }}
        className="bg-gray-200 hover:bg-gray-300 w-20 mr-2"
      />
      <Button
        text="Guardar"
        textColor="text-white"
        disabled={formik.isSubmitting}
        loading={formik.isSubmitting}
        onClick={formik.submitForm}
        loaderColor="bg-emerald-300"
        className="bg-emerald-500 hover:bg-emerald-400 w-20"
      />
    </div>

  );
}

export default SettingsForm;
