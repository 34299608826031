import React from 'react';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { optionAtom } from '../../../../../../atoms/Atoms';

function Options({ items }) {
  const [option, setOption] = useRecoilState(optionAtom);

  return (
    <div>
      <div className="text-sm font-medium space-x-1 mb-2 pb-2 border-b">
        <span className="uppercase">Opciones</span>
        <span className="text-xs font-normal text-gray-500">(Requerido)</span>
      </div>
      {_.map(items, (i) => (
        <div key={i.id} className="mb-4">
          <div className="text-sm text-gray-700 flex items-center space-x-2">
            <input
              name="price"
              type="radio"
              checked={i === option}
              onChange={() => {
                setOption(i);
              }}
              className="border-gray-200 border-2 text-black focus:border-gray-300 focus:ring-black h-6 w-6 cursor-pointer"
            />
            <div>
              {i.name ? <div>{i.name}</div> : null}
              {i.description ? (
                <div className="text-gray-500 text-xs">{i.description}</div>
              ) : null}
              <div className="text-gray-400 text-xs">
                ${i.activePrice.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Options;
