import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Header from '../../shared-components/Header';
import { envAtom, notificationsAtom } from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';

function Password() {
  const history = useHistory();
  const env = useRecoilValue(envAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Favor de ingresar un email valido.')
        .required('Este campo es requerido.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      axios({
        method: 'post',
        url: `${env.url}password/reset`,
        headers: {
          'Ocp-Apim-Subscription-Key': envConfig.REACT_APP_API_TOKEN,
        },
        data: {
          Email: values.email,
        },
      })
        .then((response) => {
          console.log(response);
          formik.resetForm();
          setNotifications({ ...notifications, forgotSuccess: true });
          setSubmitting(false);
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
        });
    },
  });

  return (
    <div className="flex flex-col h-screen bg-cyan-500 text-white">
      <Header />
      <div className="flex flex-1 justify-center bg-black bg-opacity-90">
        <div className="flex flex-col items-center h-1/2 w-full md:w-1/3 px-4 md:px-0 mt-10">
          <div className="p-6 rounded-xl border border-white border-opacity-10">
            <div className="text-4xl tracking-tight font-extrabold mb-1 text-center">
              Recuperar Contraseña
            </div>
            <div className="font-medium text-sm mb-4 text-center">
              Escriba su correo electrónico y recibira las instrucciones para
              recuperar la contraseña
            </div>
            <div className="w-full mb-2">
              <form
                noValidate
                autoComplete="on"
                onSubmit={formik.handleSubmit}
                className="text-sm"
              >
                <div className="mb-3">
                  <div className="font-semibold tracking-tight mb-2">
                    E-mail <span className="text-rose-400">*</span>
                  </div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="juan@gmail.com"
                    className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <div className="w-full font-semibold text-xs text-rose-400 mt-1">
                      {formik.errors.email}
                    </div>
                  )}
                </div>
                <div className="flex space-x-2">
                  <button
                    className="w-full md:w-1/2 flex items-center justify-center text-center text-sm transition duration-500 ease-in-out bg-gray-700 hover:bg-rose-600 text-white font-semibold py-3 rounded"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    className="w-full md:w-1/2 flex items-center justify-center text-center text-sm transition duration-500 ease-in-out bg-green-500 hover:bg-green-600 text-white font-semibold py-3 rounded"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    {formik.isSubmitting && (
                      <div>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      </div>
                    )}
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Password;
