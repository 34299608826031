import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBadgeCheck,
  faChevronLeft,
  faChevronRight,
  faPencil,
  faStar,
} from '@fortawesome/pro-duotone-svg-icons';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import api from '../../../../../../../../../api/api';
import {
  locationAtom,
  orderAtom,
  serviceAtom,
  storeAtom,
  tokenAtom,
} from '../../../../../../../../../atoms/Atoms';
import Delivery from '../../../../../../../../shared-components/illustrations/Delivery';
import { Spinner } from '../../../../../../../../shared-components/Spinner';
import Button from '../../../../../../../../shared-components/buttons/Button';

function SmallDevicesList() {
  const history = useHistory();
  const token = useRecoilValue(tokenAtom);
  const [location, setLocation] = useRecoilState(locationAtom);
  const [store, setStore] = useRecoilState(storeAtom);
  const [order, setOrder] = useRecoilState(orderAtom);
  const setService = useSetRecoilState(serviceAtom);
  const [state, setState] = useState(null);

  const getBusiness = (e) => {
    setLocation({
      ...location,
      loadingBusinesses: true,
      businesses: [],
      page: 1,
    });

    api
      .get(`/businesses/${e.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setLocation({
            ...location,
            loadingBusinesses: false,
            page: 1,
            businesses: [],
          });
        } else {
          setLocation({
            ...location,
            loadingBusinesses: false,
            page: 1,
            businesses: response.data.data.results,
          });
        }
      })
      .catch((error) => {
        setLocation({
          ...location,
          loadingBusinesses: false,
          businesses: [],
          error: error.message,
        });
      });
  };

  const handleStore = async (e) => {
    if (store && order.length > 0 && store.id !== e.id) {
      setLocation({ ...location, alert: e });
    } else {
      await setStore(e);
      await setService(1);
      await setLocation({
        ...location,
        modal: false,
        page: 0,
        location: state,
      });
      await localStorage.setItem('ant-service', 1);
      await localStorage.setItem('ant-store', JSON.stringify(e));
      await localStorage.setItem('ant-location', JSON.stringify(state));
      await history.push('/menu');
    }
  };

  const handleAlert = async (e) => {
    await setStore(e);
    await setService(1);
    await setOrder([]);
    await setLocation({
      ...location,
      alert: null,
      page: 0,
      modal: false,
      location: state,
    });
    await localStorage.setItem('ant-store', JSON.stringify(e));
    await localStorage.setItem('ant-service', 1);
    await localStorage.removeItem('ant-order');
    await localStorage.setItem('ant-location', JSON.stringify(state));
    await history.push('/menu');
  };

  if (location.page === 0) {
    return (
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
          },
          show: {
            opacity: 1,
            transition: {
              staggerChildren: 0.01,
            },
          },
        }}
        initial="hidden"
        animate="show"
        className="group relative flex flex-1 flex-col md:hidden overflow-hidden"
      >
        <button
          type="button"
          onClick={() => setLocation({ ...location, current: 'register' })}
          className="absolute z-10 bottom-4 right-4 text-white font-semibold text-xs bg-emerald-500 hover:bg-emerald-400 h-12 w-12 rounded-full shadow-xl outline-none focus:outline-none ring-0 focus:ring-0"
        >
          <FontAwesomeIcon icon={faPencil} className="text-xl" />
        </button>
        <div className="flex items-center justify-between h-16 px-4 text-white bg-gray-600">
          <motion.div
            initial={{ x: 10 }}
            animate={{ x: 0 }}
            exit={{ x: 10 }}
            className="font-medium text-xs"
          >
            Mis Localidades
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                delay: 1,
              },
            }}
            exit={{ opacity: 0, scale: 0 }}
            className="text-xs font-medium flex items-center justify-center h-6 w-6 text-white bg-rose-500 rounded-full"
          >
            {location.locations.length}
          </motion.div>
        </div>
        <div className="flex-1 overflow-auto divide-y divide-gray-100 dark:divide-gray-600 text-gray-800 dark:text-white">
          {_.map(location.locations, (l) => (
            <motion.button
              type="button"
              variants={{
                hidden: { x: '100%', opacity: 0 },
                show: { x: 0, opacity: 1 },
              }}
              key={l.id}
              whileTap={{ x: -5 }}
              className="h-16 flex items-center text-left w-full p-4 space-x-4 transition duration-500 ease-in-out hover:bg-gray-100 dark:hover:bg-gray-600"
              onClick={() => {
                getBusiness(l);
                setState(l);
              }}
            >
              <FontAwesomeIcon
                icon={faStar}
                className={
                  state?.id === l.id ? 'text-amber-400' : 'text-gray-300'
                }
              />
              <div className="flex-1 text-xs truncate">
                <div className="font-medium">{l.name}</div>
                <div className="text-gray-400 truncate">{l.addressLine}</div>
              </div>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="text-xs text-gray-400"
              />
            </motion.button>
          ))}
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ x: '-100%', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: '100%', opacity: 0 }}
      className="group relative flex flex-1 flex-col md:hidden overflow-hidden"
    >
      {location.alert && (
        <motion.div
          initial={{
            y: '100%',
          }}
          animate={{
            y: 0,
            transition: {
              delay: 0.5,
            },
          }}
          exit={{
            y: '100%',
          }}
          className="w-full absolute z-10 flex flex-col flex-1 items-center justify-center text-center bottom-0 bg-gray-900 bg-opacity-95 h-1/2 text-white p-4 rounded-b-xl"
        >
          <div className="font-semibold mb-2">¡Oopss!</div>
          <div className="text-xs">
            Al seleccionar otra tienda perderás todos los productos que se
            encuantran en tu carrito. Si estás de acuerdo haz click en aceptar.
          </div>
          <div className="space-x-2 mt-4">
            <Button
              text="Cancelar"
              onClick={() => {
                setLocation({ ...location, alert: null });
              }}
              className="bg-gray-800 w-20 border border-gray-700 hover:border-gray-600"
            />
            <Button
              text="Aceptar"
              onClick={() => handleAlert(location.alert)}
              className="bg-emerald-500 hover:bg-emerald-400 w-20"
            />
          </div>
        </motion.div>
      )}

      <div className="flex items-center justify-between h-16 px-4 text-white bg-gray-600">
        <motion.button
          type="button"
          initial={{ x: 10 }}
          animate={{ x: 0 }}
          exit={{ x: 10 }}
          whileTap={{ x: -5 }}
          onClick={() => setLocation({ ...location, page: 0, businesses: [] })}
          className="flex items-center space-x-2 text-white outline-none focus:outline-none ring-0 focus:ring-0"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="text-xs" />
          <div className="font-medium text-xs">Atrás</div>
        </motion.button>
        {!location.loadingBusinesses && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                delay: 1,
              },
            }}
            exit={{ opacity: 0, scale: 0 }}
            className="text-xs font-medium flex items-center justify-center h-6 w-6 text-white bg-rose-500 rounded-full"
          >
            {location.businesses.length}
          </motion.div>
        )}
        {location.loadingBusinesses && <Spinner />}
      </div>

      {/* Not loading and no results */}
      {!location.loadingBusinesses && location.businesses.length === 0 && (
        <div className="flex flex-col flex-1 items-center justify-center text-xs tracking-tight p-4">
          <Delivery className="h-16 mb-4" />
          <div>Aún no llegamos a esta dirección</div>
          <div className="text-center text-gray-400">
            No hemos encontrado tiendas para entregar a la dirección
            seleccionada. Intenta mas adelante.
          </div>
        </div>
      )}

      {/* loaded some results */}
      <AnimatePresence>
        {!location.loadingBusinesses && location.businesses.length > 0 && (
        <div className="flex flex-col flex-1 text-sm tracking-tight overflow-y-auto divide-y divide-gray-100 dark:divide-gray-600">
          <div className="text-white uppercase text-xs md:h-8 text-center m-2">
            La(s) tienda(s) abajo entregan a tu localidad. <br />
            Escoje la tienda de donde deseas recibir tu pedido
          </div>
          {_.map(location.businesses, (e) => (
            <motion.button
              type="button"
              key={e.id}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              whileTap={{ x: -5 }}
              disabled={location.alert}
              onClick={() => handleStore(e)}
              className="disabled:cursor-not-allowed flex items-center h-16 lex items-center hover:bg-gray-100 dark:hover:bg-gray-600 text-xs outline-none p-4 space-x-4 focus:outline-none ring-0 focus:ring-0 transition duration-200 ease-in-out"
            >
              <FontAwesomeIcon
                icon={faStar}
                className={
                  store?.id === e.id ? 'text-amber-400' : 'text-gray-300'
                }
              />
              <div className="flex-1 text-left truncate">
                <div className="font-medium truncate">{e.name}</div>
                <div className="text-gray-400 truncate">
                  Mínimo de pedido: ${e.minimumDeliveryOrderAmount.toFixed(2)}
                  /Costo por servicio: $
                  {e.locations[0].deliveryFee.toFixed(2)}
                </div>
              </div>
              <FontAwesomeIcon
                icon={faBadgeCheck}
                className="text-xs text-gray-400"
              />
            </motion.button>
          ))}
        </div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default SmallDevicesList;
