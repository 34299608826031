import React from 'react';
import Loader from '../loader/Loader';

function Button({
  disabled,
  onClick,
  className,
  loading,
  text,
  loaderColor,
  textColor,
  icon,
}) {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`${className} inline-flex text-xs h-10 flex justify-center items-center text-center disabled:opacity-25 text-xs ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out font-semibold rounded-md
      `}
    >
      {icon}
      {loading ? (
        <Loader color={loaderColor} />
      ) : (
        <span className={textColor}>{text}</span>
      )}
    </button>
  );
}

export default Button;
