import React, { useState, Fragment, useRef } from 'react';
import is from 'is_js';
import _ from 'lodash';
import { Dialog, Transition } from '@headlessui/react';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { MailIcon } from '@heroicons/react/outline';
import { constSelector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  tokenAtom,
  cardAtom,
  paymentOptionAtom,
  hourAtom,
  orderAtom,
  storeAtom,
  stateAtom,
  personAtom,
  serviceAtom,
  locationAtom,
  promoCodeAtom,
  confirmationAtom,
  userAtom,
} from '../../../../../atoms/Atoms';
import { checkoutSelector } from '../../../../../atoms/Selectors';
import api from '../../../../../api/api';
import Button from '../../../../shared-components/buttons/Button';

function Checkout() {
  const ref = useRef(null);
  const history = useHistory();
  const user = useRecoilValue(userAtom);
  const [order, setOrder] = useRecoilState(orderAtom);
  const [hour, setHour] = useRecoilState(hourAtom);
  const card = useRecoilValue(cardAtom);
  const paymentOption = useRecoilValue(paymentOptionAtom);
  const store = useRecoilValue(storeAtom);
  const state = useRecoilValue(stateAtom);
  const person = useRecoilValue(personAtom);
  const service = useRecoilValue(serviceAtom);
  const location = useRecoilValue(locationAtom);
  const promoCode = useRecoilValue(promoCodeAtom);
  const token = useRecoilValue(tokenAtom);
  const checkout = useRecoilValue(checkoutSelector);
  const setConfirmation = useSetRecoilState(confirmationAtom);
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rewards, setRewards] = useState(null);
  const [orderError, setOrderError] = useState(
    'Ocurrió un error al someter su orden. Favor intentar nuevamente.'
  );

  function submitOrder(selectedReward) {
    setRewards(null);
    setLoading(true);
    const phone = person.phone ? person.phone : '';
    const name = person.name ? person.name : '';
    // let sFee = 0;
    // if (state.isOrderServiceFeeFixed) {
    //   sFee = state.orderServiceFee;
    // } else {
    //   sFee = state.orderServiceFee * checkout?.subtotal;
    // }
    // if (service === 0) {
    //   if (store.locations[0].pickupFeeType === 2) {
    //     sFee += (store.locations[0].pickupFee * checkout?.subtotal);
    //   } else if (store.locations[0].pickupFeeType === 1) {
    //     sFee += store.locations[0].pickupFee;
    //   }
    // }

    const data = {
      OGuid: uuid(),
      AppVersion: '1.0',
      PaymentType: card ? 0 : paymentOption?.paymentType,
      OrderLat: null,
      OrderLon: null,
      BusinessId: store.id,
      CardId: card,
      PaymentOptionId: paymentOption?.id,
      DeliveryLocationId: service === 1 ? location?.location?.id : null,
      DeliveryTotal: service === 1 ? checkout?.service : 0,
      SubTotal: checkout?.subtotal,
      MunicipalTax: checkout?.munTax,
      StateTax: checkout?.staTax,
      TaxTotal: checkout?.totalTax,
      ProcessingFee: checkout?.service,
      OrderTotal: checkout?.total,
      LocationId: store?.locations[0].id,
      PickUpName: `${name} ${phone}`,
      SpecialInstruction: person.instructions
        ? person.instructions
        : null,
      Type: service,
      items: order,
      DesiredDateTime: hour?.time,
      CouponId: promoCode?.id
    };

    if (selectedReward) {
      data.loyaltyRewardIds = [selectedReward.id];
    }

    setSubmitting(true);
    setShowError(false);

    // console.log(JSON.stringify(data));

    api
      .post('orders', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          setOrderError(response.data.error);
          setShowError(true);
        } else {
          setConfirmation(response);
          history.push(
            `/order-details/${response.data.data.confirmationCode}`
          );
          localStorage.removeItem('ant-cart');
          setOrder([]);
          setHour(null);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setLoading(false);
        setSubmitting(false);
        setOrderError(error);
        setShowError(true);
      });
  }

  function checkRewards() {
    setSubmitting(true);
    setRewards(null);
    api.get(`loyalty/rewards/${store.id}`)
      .then((response) => {
        setSubmitting(false);
        if (response.data.error) {
          setOrderError(response.data.error);
          setShowError(true);
        } else if (response.data.data.results.length > 0) {
          const tempRewards = JSON.parse(JSON.stringify(response.data.data.results));
          for (let i = 0; i < tempRewards.length; i++) {
            if (tempRewards[i].canRedeem && checkout?.total < tempRewards[i].minimumOrderTotal) {
              // tempRewards[i].canRedeem = false;
            }
          }
          setRewards(tempRewards);
        } else {
          submitOrder();
        }
      })
      .catch((err) => {
        setSubmitting(false);
        setOrderError(err);
        setShowError(true);
      });
  }

  return (
    <div>
      <button
        disabled={is.any.falsy(
          !submitting,
          checkout.metMinimum,
          checkout.hasPaymentSelected,
          hour,
          user?.isValidated
        )}
        type="button"
        onClick={() => {
          checkRewards();
        }}
        className="uppercase disabled:opacity-30 disabled:cursor-not-allowed tracking-tight font-bold w-full bg-green-400 text-white py-5"
      >
        {submitting ? 'Sometiendo Order...' : 'Completar Orden'}
      </button>
      <div className={showError ? 'text-red-400 m-4' : 'hidden'}>
        {orderError}
      </div>
      <div className="flex items-center justify-center px-4 h-12 bg-cyan-500 text-xs text-center font-medium text-white space-x-2">
        <MailIcon className="w-4 h-4" />
        <div>
          Al someter su orden recibirás un correo electrónico con los detalles
          de la orden.
        </div>
      </div>

      <Transition.Root show={rewards?.length > 0} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={ref}
          open={rewards?.length > 0}
          onClose={() => setRewards([])}
        >
          <div className="flex justify-center items-center h-screen px-4 md:px-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="bg-gray-900 absolute inset-0 backdrop-filter backdrop-blur-sm bg-opacity-50 transition-opacity" />
            </Transition.Child>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="flex flex-col w-full h-5/6 w-full max-w-lg my-8 divide-y divide-gray-200 transition-all transform bg-white shadow-xl duration-500 easy-in-out">
                <Dialog.Title
                  as="h3"
                  className="flex items-center space-x-2 px-4 justify-between text-lg font-medium h-20"
                >
                  {/* HEADER TITLE */}
                  <div>Recompensas disponibles</div>
                </Dialog.Title>
                <div className="flex flex-col flex-1 overflow-y-auto no-scrollbar divide-y divide-gray-200">
                  {_.map(rewards, (s) => (
                    <button
                      type="button"
                      disabled={!s.canRedeem || s.minimumOrderTotal > checkout?.total}
                      key={s.id}
                      onClick={() => {
                        submitOrder(s);
                      }}
                      className={`${s.canRedeem && s.minimumOrderTotal <= checkout?.total ? 'bg-green-400' : 'bg-green-300'} text-white hover:bg-green-600 disabled:cursor-not-allowed flex items-center space-x-2 text-center rounded-md truncate mx-4 mt-4 py-5 px-10 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out`}
                    >
                      <span className="flex-col mr-auto">{s.name}</span>
                      {s.canRedeem && s.minimumOrderTotal <= checkout?.total && (
                        <span className="flex-col">Usar {s.pointForRedeem} puntos</span>
                      )}
                      {s.canRedeem && s.minimumOrderTotal > checkout?.total && (
                        <span className="flex-col">Compra mínima de ${s.minimumOrderTotal}</span>
                      )}
                      {!s.canRedeem && (
                        <span className="flex-col">Necesitas {s.pointsNeeded} puntos adicionales</span>
                      )}
                    </button>
                  ))}
                </div>
                <div className="flex items-center space-x-2 px-4 bg-gray-100 h-20">
                  <Button
                    className="px-2 mr-auto"
                    text="Cerrar"
                    textColor="text-red-400"
                    onClick={() => setRewards(null)}
                  />
                  <Button
                    className="px-2 bg-gray-700 hover:bg-gray-600"
                    text="No aplicar descuento"
                    textColor="text-white"
                    onClick={() => submitOrder()}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default Checkout;
