import React, { Fragment, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { useLocalStorage } from 'react-use';
import { useHistory } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, XIcon } from '@heroicons/react/outline';
import {
  menuAtom,
  orderAtom,
  pickupModalAtom,
  serviceAtom,
  storeAtom,
  storesAtom,
} from '../../../../../atoms/Atoms';
import Button from '../../../../shared-components/buttons/Button';

function Pickup() {
  const ref = useRef(null);
  const history = useHistory();
  const [store, setStore] = useRecoilState(storeAtom);
  const [open, setOpen] = useRecoilState(pickupModalAtom);
  const stores = useRecoilValue(storesAtom);
  const setOrder = useSetRecoilState(orderAtom);
  const setMenu = useSetRecoilState(menuAtom);
  const setService = useSetRecoilState(serviceAtom);

  // Find, set and remove local storage.
  const [locStore, setLocStore] = useLocalStorage('ant-store');
  const [locOrder, setLocOrder, removeLocOrder] = useLocalStorage('ant-cart');
  const [locService, setLocService] = useLocalStorage('ant-service');

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={ref}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className="flex justify-center items-center h-screen px-4 md:px-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="bg-gray-900 absolute inset-0 backdrop-filter backdrop-blur-sm bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-500"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex flex-col w-full h-5/6 w-full max-w-lg my-8 divide-y divide-gray-200 transition-all transform bg-white shadow-xl duration-500 easy-in-out">
              <Dialog.Title
                as="h3"
                className="flex items-center space-x-2 px-4 justify-between text-lg font-medium h-20"
              >
                {/* HEADER TITLE */}
                <div>Seleccionar Tienda</div>
                {/* HEADER ACTIONS */}
                <div className="flex items-center space-x-2">
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="flex items-center justify-center text-gray-500 hover:text-gray-600 ring-0 focus:ring-0 bg-gray-100 w-10 h-10 rounded-full outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                  >
                    <XIcon className="w-5 h-5" />
                  </button>
                </div>
              </Dialog.Title>
              <div className="flex flex-col flex-1 overflow-y-auto no-scrollbar divide-y divide-gray-200">
                {_.map(_.filter(stores, (s) => s.locations[0].isPickUpAvailable), (s) => (
                  <button
                    type="button"
                    key={s.id}
                    onClick={() => {
                      // local storage
                      setLocStore(s);
                      setStore(s);
                      setService(0);
                      setLocService(0);
                      if (locStore && s && s.id !== locStore.id) {
                        removeLocOrder();
                        setOrder([]);
                        setMenu(null);
                      }

                      setOpen(false);
                      history.push('/menu');
                    }}
                    className="flex items-center space-x-2 text-left w-full truncate p-4 hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out"
                  >
                    <CheckCircleIcon
                      className={
                        store && store.id === s.id
                          ? 'text-green-500 w-4 h-4'
                          : 'text-gray-300 w-4 h-4'
                      }
                    />
                    <div className="w-full space-x-1 text-sm font-medium">
                      <div>{s.description}</div>
                      {/* <div className="text-xs text-gray-400 truncate">
                        {s.locations[0].directionsText}
                      </div> */}
                    </div>
                  </button>
                ))}
              </div>
              <div className="flex items-center justify-end space-x-2 px-4 bg-gray-100 h-20">
                <Button
                  className="w-20 bg-gray-700 hover:bg-gray-600"
                  text="Cerrar"
                  textColor="text-white"
                  onClick={() => setOpen(false)}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Pickup;
