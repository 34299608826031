import React from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { profileAtom } from '../../../../../atoms/Atoms';
import Order from './shared-components/Order';
import Placeholder from './shared-components/Placeholder';

function Orders() {
  const profile = useRecoilValue(profileAtom);

  if (profile.ordersLoading) {
    return (
      <div className="divide-y divide-gray-200">
        {_.times(10, (x) => (
          <Placeholder key={x} />
        ))}
      </div>
    );
  }

  return (
    <div className="divide-y divide-gray-200">
      {_.map(profile.orders, (e) => (
        <Order key={e.id} order={e} />
      ))}
    </div>
  );
}

export default Orders;
