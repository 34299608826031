import React, { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { BellIcon, XIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useRecoilState, useRecoilValue } from 'recoil';
import api from '../../../../../../api/api';
import { paymentMethodsAtom, tokenAtom } from '../../../../../../atoms/Atoms';
import CleaveInput from '../../../../../shared-components/form/CleaveInput';
import Input from '../../../../../shared-components/form/Input';
import Form from '../../../../../shared-components/form/Form';
import Button from '../../../../../shared-components/buttons/Button';

function Modal() {
  const [payments, setPayments] = useRecoilState(paymentMethodsAtom);
  const token = useRecoilValue(tokenAtom);

  const close = () => {
    setPayments({ ...payments, modal: false });
  };

  const formik = useFormik({
    initialValues: {
      'cc-name': '',
      'cc-exp': '',
      'cc-zip': '',
    },
    validationSchema: Yup.object().shape({
      'cc-name': Yup.string().required('Campo requerido'),
      'cc-exp': Yup.string().required('Campo requerido'),
      'cc-zip': Yup.string().required('Campo requerido'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setPayments({
        ...payments,
        error: null,
      });

      api
        .put(
          'cards',
          {
            id: payments.current.id,
            Zipcode: values['cc-zip'],
            ExpMonth: values['cc-exp'].slice(0, 2),
            ExpYear: values['cc-exp'].slice(3, 7),
            Name: values['cc-name'],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            setPayments({
              ...payments,
              modalError: response.data.error,
            });
          } else {
            const array = JSON.parse(JSON.stringify(payments));
            const index = _.findIndex(
              array.payments,
              (o) => o.id === payments.current.id
            );
            array.payments[index] = response.data.data;

            setPayments({
              ...payments,
              modal: false,
              modalError: null,
              payments: array.payments,
            });
            formik.resetForm();
          }
          setSubmitting(false);
        })
        .catch((err) => {
          setPayments({
            ...payments,
            modalError: err.message,
          });
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (payments.current) {
      formik.setValues({
        'cc-name': payments.current?.cardholderName,
        'cc-exp': payments.current?.expirationDate,
        'cc-zip': payments.current?.zipcode,
      });
    }
  }, [payments.current]);

  return (
    <Transition show={payments.modal} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={close}
      >
        <div className="flex justify-center items-center h-screen">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="bg-gray-900 absolute inset-0 backdrop-filter backdrop-blur-sm bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            afterLeave={() => setPayments({ ...payments, current: null })}
          >
            <div className="flex flex-col w-full h-5/6 md:h-auto w-full max-w-lg my-8 divide-y divide-gray-200 transition-all transform bg-white shadow-xl duration-500 easy-in-out">
              {/* HEADER */}
              <Dialog.Title
                as="h3"
                className="flex items-center space-x-2 px-4 justify-between text-lg font-medium h-20"
              >
                {/* HEADER TITLE */}
                <div>Editar</div>
                {/* HEADER ACTIONS */}
                <div className="flex items-center space-x-2">
                  <button
                    type="button"
                    onClick={close}
                    className="flex items-center justify-center text-gray-500 hover:text-gray-600 ring-0 focus:ring-0 bg-gray-100 w-10 h-10 rounded-full outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                  >
                    <XIcon className="w-5 h-5" />
                  </button>
                </div>
              </Dialog.Title>
              {/* CONTENT */}
              <div className="flex flex-col flex-1 overflow-y-auto no-scrollbar p-4">
                <div className="flex justify-between text-xs font-mono bg-gray-100 p-4 rounded-md">
                  <div>
                    <div className="font-semibold mb-2 font-sans">
                      Estas editando:
                    </div>
                    <div className="font-semibold">
                      {payments.current?.brand}
                    </div>
                    <div>{payments.current?.cardholderName}</div>
                    <div>**** **** **** {payments.current?.last4}</div>
                    <div>Valida hasta: {payments.current?.expirationDate}</div>
                    <div>Codigo postal: {payments.current?.zipcode}</div>
                  </div>
                  <span className="relative flex h-3 w-3">
                    <span className="absolute animate-ping inline-flex h-full w-full rounded-full bg-purple-400 opacity-75" />
                    <span className="inline-flex rounded-full h-3 w-3 bg-purple-500" />
                  </span>
                </div>
                <Form>
                  <Input
                    label="Nombre"
                    name="cc-name"
                    type="text"
                    placeholder="Juan del Pueblo"
                    required
                    autoComplete="cc-name"
                    value={formik.values['cc-name']}
                    onChange={formik.handleChange}
                    error={
                      formik.errors['cc-name'] &&
                      formik.touched['cc-name'] &&
                      formik.errors['cc-name']
                    }
                    className="col-span-1 md:col-span-2"
                  />
                  <CleaveInput
                    label="Fecha de expiración"
                    name="cc-exp"
                    type="text"
                    placeholder="MM/YYYY"
                    required
                    autoComplete="cc-exp"
                    value={formik.values['cc-exp']}
                    onChange={formik.handleChange}
                    options={{
                      date: true,
                      datePattern: ['m', 'Y'],
                    }}
                    error={
                      formik.errors['cc-exp'] &&
                      formik.touched['cc-exp'] &&
                      formik.errors['cc-exp']
                    }
                  />
                  <Input
                    label="Codigo postal"
                    name="cc-zip"
                    type="text"
                    placeholder="00907..."
                    required
                    autoComplete="postal-code"
                    value={formik.values['cc-zip']}
                    onChange={formik.handleChange}
                    error={
                      formik.errors['cc-zip'] &&
                      formik.touched['cc-zip'] &&
                      formik.errors['cc-zip']
                    }
                  />
                  <Transition
                    show={!!payments.modalError}
                    enter="transform duration-1000 transition ease-in-out"
                    enterFrom="opacity-0 -translate-x-full"
                    enterTo="opacity-100 rotate-0 translate-x-0"
                    leave="transform duration-1000 transition ease-in-out"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="opacity-0 -translate-x-full"
                    className="flex items-center text-xs text-rose-500 space-x-2 mt-4 pt-4"
                  >
                    <BellIcon className="w-4 h-4" />
                    <div>{payments.modalError}</div>
                  </Transition>
                </Form>
              </div>
              {/* FOOTER */}
              <div className="flex items-center justify-end space-x-2 px-4 bg-gray-100 h-20">
                <Button
                  className="w-20 bg-gray-700 hover:bg-gray-600"
                  text="Cerrar"
                  textColor="text-white"
                  onClick={close}
                />
                <Button
                  className="w-20 bg-green-400 hover:bg-green-500"
                  text="Guardar"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  loaderColor="bg-white"
                  textColor="text-white"
                  onClick={() => formik.submitForm()}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
