import React from 'react';
import { Transition } from '@headlessui/react';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellOn, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { notificationsAtom } from '../../../../atoms/Atoms';

function TokenError() {
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const history = useHistory();

  return (
    <Transition
      show={!!notifications.token}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-4 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="divide-y max-w-sm w-full bg-white shadow-lg rounded-xl pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4 text-xs">
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
              <FontAwesomeIcon icon={faBellOn} className="text-red-500" />
            </div>
            <div className="flex-1 pt-0.5">
              <p className=" font-medium text-gray-700">Error de usuario</p>
              <div className="mt-1 text-gray-400">
                ¡Lo sentimos! Estamos teniendo problemas con los datos de su
                usuario. Favor de iniciar sesión nuevamente.
              </div>
              <div className="text-xs text-red-300 font-light">
                Descripción: {notifications.token}
              </div>
            </div>
            <div className="flex-shrink-0 flex">
              <button
                type="button"
                onClick={() => {
                  setNotifications({ ...notifications, token: null });
                }}
                className="bg-white rounded-full inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="sr-only">Cerrar</span>
                <FontAwesomeIcon icon={faTimesCircle} />
              </button>
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={() => {
            setNotifications({ ...notifications, token: null });
            history.push('/signin');
          }}
          className="text-blue-500 p-4 w-full text-xs font-semibold tracking-tight bg-gray-100 hover:bg-gray-50 transition duration-500 ease-in-out"
        >
          Iniciar Sesión
        </button>
      </div>
    </Transition>
  );
}

export default TokenError;
