import { ShareIcon, XIcon } from '@heroicons/react/outline';
import React, { useState, useEffect } from 'react';
import is from 'is_js';
import moment from 'moment';
import { Dialog, Transition } from '@headlessui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import api from '../../../../../../api/api';
import { timezoneAtom } from '../../../../../../atoms/Atoms';
import { orderType, orderStatus, renderDate } from '../../../../../../utils';

function Modal() {
  const { order } = useParams();
  const history = useHistory();
  const location = useLocation();
  const timezone = useRecoilValue(timezoneAtom);
  const [current, setCurrent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const close = async () => {
    await history.push('/profile/orders');
    await setShow(false);
  };

  useEffect(() => {
    if (order) {
      setLoading(true);
      api
        .get(`/order/${order}`)
        .then((response) => {
          setLoading(false);
          setCurrent(response.data.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [order]);

  return (
    <Transition appear show={!!order} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-hidden"
        onClose={close}
      >
        <div className="flex justify-center items-center h-screen">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="bg-gray-900 absolute inset-0 backdrop-filter backdrop-blur-sm bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex flex-col w-full h-5/6 w-full max-w-lg my-8 divide-y divide-gray-200 transition-all transform bg-white shadow-xl duration-500 easy-in-out">
              {/* HEADER */}
              <Dialog.Title
                as="h3"
                className="flex items-center space-x-2 px-4 justify-between text-lg font-medium h-20"
              >
                {/* HEADER TITLE */}
                <div>Pedido {order}</div>
                {/* HEADER ACTIONS */}
                <div className="flex items-center space-x-2">
                  <a
                    href={`mailto:?subject=Pedido de Antoninos Pizza&body=Hola, quiero compartir contigo mi pedido de Antoninos Pizza contigo. ${location.origin}/order-details/${order}`}
                    className="flex items-center justify-center text-sky-500 hover:text-sky-600 ring-0 focus:ring-0 bg-sky-100 w-10 h-10 rounded-full outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                  >
                    <ShareIcon className="w-5 h-5" />
                  </a>
                  <button
                    type="button"
                    onClick={close}
                    className="flex items-center justify-center text-gray-500 hover:text-gray-600 ring-0 focus:ring-0 bg-gray-100 w-10 h-10 rounded-full outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                  >
                    <XIcon className="w-5 h-5" />
                  </button>
                </div>
              </Dialog.Title>
              {/* CONTENT */}
              <div className="flex flex-col flex-1 bg-white overflow-y-auto no-scrollbar p-4">
                <Transition
                  show={loading}
                  enter="transform transition duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transform duration-700 transition ease-in-out"
                  leaveFrom="opacity-100 translate-x-0"
                  leaveTo="opacity-0 -translate-x-full"
                  afterLeave={() => setShow(true)}
                >
                  <div className="text-sm text-gray-500 border grid grid-cols-2 divide-x mb-4">
                    <div className="divide-y divide-gray-200">
                      <div
                        className="flex flex-col justify-center py-2 px-4 space-y-2"
                        style={{ height: '3.313rem' }}
                      >
                        <div className="h-2 w-10 rounded-full bg-gray-200 animate-pulse" />
                        <div className="h-2 w-20 rounded-full bg-gray-200 animate-pulse" />
                      </div>
                      <div
                        className="flex flex-col justify-center py-2 px-4 space-y-2"
                        style={{ height: '3.313rem' }}
                      >
                        <div className="h-2 w-10 rounded-full bg-gray-200 animate-pulse" />
                        <div className="h-2 w-20 rounded-full bg-gray-200 animate-pulse" />
                      </div>
                      <div
                        className="flex flex-col justify-center py-2 px-4 space-y-2"
                        style={{ height: '3.313rem' }}
                      >
                        <div className="h-2 w-10 rounded-full bg-gray-200 animate-pulse" />
                        <div className="h-2 w-20 rounded-full bg-gray-200 animate-pulse" />
                      </div>
                      <div
                        className="flex flex-col justify-center py-2 px-4 space-y-2"
                        style={{ height: '3.313rem' }}
                      >
                        <div className="h-2 w-10 rounded-full bg-gray-200 animate-pulse" />
                        <div className="h-2 w-20 rounded-full bg-gray-200 animate-pulse" />
                      </div>
                      <div
                        className="flex flex-col justify-center py-2 px-4 space-y-2"
                        style={{ height: '3.313rem' }}
                      >
                        <div className="h-2 w-10 rounded-full bg-gray-200 animate-pulse" />
                        <div className="h-2 w-20 rounded-full bg-gray-200 animate-pulse" />
                      </div>
                      <div
                        className="flex flex-col justify-center py-2 px-4 space-y-2"
                        style={{ height: '3.313rem' }}
                      >
                        <div className="h-2 w-10 rounded-full bg-gray-200 animate-pulse" />
                        <div className="h-2 w-20 rounded-full bg-gray-200 animate-pulse" />
                      </div>
                    </div>
                    <div className="divide-y divide-gray-200">
                      <div
                        className="flex flex-col justify-center py-2 px-4 space-y-2"
                        style={{ height: '3.313rem' }}
                      >
                        <div className="h-2 w-10 rounded-full bg-gray-200 animate-pulse" />
                        <div className="h-2 w-20 rounded-full bg-gray-200 animate-pulse" />
                      </div>
                      <div
                        className="flex flex-col justify-center py-2 px-4 space-y-2"
                        style={{ height: '3.313rem' }}
                      >
                        <div className="h-2 w-10 rounded-full bg-gray-200 animate-pulse" />
                        <div className="h-2 w-20 rounded-full bg-gray-200 animate-pulse" />
                      </div>
                      <div
                        className="flex flex-col justify-center py-2 px-4 space-y-2"
                        style={{ height: '3.313rem' }}
                      >
                        <div className="h-2 w-10 rounded-full bg-gray-200 animate-pulse" />
                        <div className="h-2 w-20 rounded-full bg-gray-200 animate-pulse" />
                      </div>
                      <div
                        className="flex flex-col justify-center py-2 px-4 space-y-2"
                        style={{ height: '3.313rem' }}
                      >
                        <div className="h-2 w-10 rounded-full bg-gray-200 animate-pulse" />
                        <div className="h-2 w-20 rounded-full bg-gray-200 animate-pulse" />
                      </div>
                      <div
                        className="flex flex-col justify-center py-2 px-4 space-y-2"
                        style={{ height: '3.313rem' }}
                      >
                        <div className="h-2 w-10 rounded-full bg-gray-200 animate-pulse" />
                        <div className="h-2 w-20 rounded-full bg-gray-200 animate-pulse" />
                      </div>
                      <div
                        className="flex flex-col justify-center py-2 px-4 space-y-2"
                        style={{ height: '3.313rem' }}
                      >
                        <div className="h-2 w-10 rounded-full bg-gray-200 animate-pulse" />
                        <div className="h-2 w-20 rounded-full bg-gray-200 animate-pulse" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end h-10 space-y-2">
                    <div className="h-2 w-10 rounded-full bg-gray-200 animate-pulse" />
                    <div className="h-2 w-20 rounded-full bg-gray-200 animate-pulse" />
                  </div>
                </Transition>
                <Transition
                  show={show}
                  enter="transform transition duration-1000"
                  enterFrom="opacity-0 translate-x-full"
                  enterTo="opacity-100 -translate-x-0"
                  leave="transform duration-200 transition ease-in-out"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="text-sm text-gray-500 border grid grid-cols-3 divide-x">
                    <div className="divide-y divide-gray-200">
                      <div className="py-2 px-4">
                        <div className="text-xs">Tienda:</div>
                        <div className="text-gray-800">
                          {current?.businessName}
                        </div>
                      </div>
                    </div>
                    <div className="divide-y divide-gray-200">
                      <div className="py-2 px-4">
                        <div className="text-xs">Cliente:</div>
                        <div className="text-gray-800">
                          {current?.customerName}
                        </div>
                      </div>
                    </div>
                    <div className="divide-y divide-gray-200">
                      <div className="py-2 px-4">
                        <div className="text-xs">Servicio:</div>
                        <div className="text-gray-800">
                          {orderType(current?.type)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-sm text-gray-500 border grid grid-cols-2 divide-x mb-4">
                    <div className="divide-y divide-gray-200">
                      <div className="py-2 px-4">
                        <div className="text-xs">Fecha:</div>
                        <div className="text-gray-800 truncate">
                          {renderDate(current?.endSlotTime, timezone, 'dddd, D [de] MMMM, YYYY h:mm A')}
                        </div>
                      </div>
                      <div className="py-2 px-4">
                        <div className="text-xs">Codigo:</div>
                        <div className="text-gray-800">
                          {current?.confirmationCode}
                        </div>
                      </div>
                      <div className="py-2 px-4">
                        <div className="text-xs">Pago:</div>
                        <div className="text-gray-800">{current?.paymentName}</div>
                      </div>
                      <div className="py-2 px-4">
                        <div className="text-xs">Estado:</div>
                        <div className="text-gray-800">
                          {orderStatus(current?.status)}
                        </div>
                      </div>
                      <div className="py-2 px-4">
                        <div className="text-xs">Artículos:</div>
                        <div className="text-gray-800">
                          {current?.itemCount}
                        </div>
                      </div>
                      {current?.discountCode && (
                        <div className="py-2 px-4">
                          <div className="text-xs">Código Promocional:</div>
                          <div className="text-gray-800">
                            {current?.discountCode}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="divide-y divide-gray-200">
                      <div className="py-2 px-4">
                        <div className="text-xs">Hora:</div>
                        <div className="text-gray-800">
                          {moment
                            .tz(current?.endSlotTime, timezone).add(-4, 'hours')
                            .format('h:mm A')}
                        </div>
                      </div>
                      <div className="py-2 px-4">
                        <div className="text-xs">Total:</div>
                        <div className="text-gray-800">
                          ${current?.orderTotal.toFixed(2)}
                        </div>
                      </div>
                      <div className="py-2 px-4">
                        <div className="text-xs">Impuesto Municipal:</div>
                        <div className="text-gray-800">
                          ${current?.municipalTax.toFixed(2)}
                        </div>
                      </div>
                      <div className="py-2 px-4">
                        <div className="text-xs">Impuesto Estatal:</div>
                        <div className="text-gray-800">
                          ${current?.stateTax.toFixed(2)}
                        </div>
                      </div>
                      <div className="py-2 px-4">
                        <div className="text-xs">Costo por servicio:</div>
                        <div className="text-gray-800">
                          ${current?.processingFee.toFixed(2)}
                        </div>
                      </div>
                      {current?.discountAmount > 0 && (
                        <div className="py-2 px-4">
                          <div className="text-xs">Descuento:</div>
                          <div className="text-gray-800">
                            ${current?.discountAmount.toFixed(2)}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-xs font-medium mb-2">Productos</div>
                  <div className="text-xs border divide-y divide-gray-200 mb-4">
                    {_.map(current?.items, (item) => (
                      <div
                        key={item.id}
                        className="flex items-center space-x-2 py-2 px-4"
                      >
                        {item.itemImage && (
                          <img
                            alt={item.name}
                            src={item.itemImage}
                            className="h-10 w-auto"
                          />
                        )}
                        <div>
                          <div>{item.name}</div>
                          {item.specialInstruction && (
                            <div>{item.specialInstruction.replaceAll('|', ' | ')}</div>
                          )}
                          {item.addOns && is.not.empty(item.addOns) && (
                            <div>Extras: {_.map(item.addOns, (a) => a.completeName.trim()).toString().replaceAll(',', ' | ')}</div>
                          )}
                          {item.sides && is.not.empty(item.sides) && (
                            <div>Acompañantes: {_.map(item.sides, (s) => s.completeName.trim()).toString().replaceAll(',', ' | ')}</div>
                          )}
                          <div className="text-xs text-gray-400">
                            ${item.price.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-col items-end">
                    <div className="text-xs">Total</div>
                    <div className="font-bold">
                      ${current?.orderTotal.toFixed(2)}
                    </div>
                  </div>
                </Transition>
              </div>
              {/* FOOTER */}
              <div className="flex items-center justify-end space-x-2 px-4 bg-gray-100 h-20">
                <button
                  type="button"
                  className="font-semibold text-xs flex items-center justify-center rounded h-10 w-20 text-white bg-gray-700 hover:bg-gray-600 outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-in-out"
                  onClick={close}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
