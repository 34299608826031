import React, { useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { useRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimesCircle,
  faExclamationCircle,
} from '@fortawesome/pro-duotone-svg-icons';
import { notificationsAtom } from '../../../../atoms/Atoms';

function GenericError() {
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  useEffect(() => {
    if (notifications.error) {
      const timer = setTimeout(
        () => setNotifications({ ...notifications, error: null }),
        5000
      );
      return () => clearTimeout(timer);
    }
  }, [notifications.error]);

  return (
    <Transition
      show={!!notifications.error}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-4 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="divide-y max-w-sm w-full bg-white shadow-lg rounded-xl pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4 text-xs">
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
              <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500" />
            </div>
            <div className="flex-1 pt-0.5">
              <p className="font-medium text-gray-700">
                {notifications.title}
              </p>
              <div className="text-red-300">
                {notifications.description}
              </div>
            </div>
            <div className="flex-shrink-0 flex">
              <button
                type="button"
                onClick={() => {
                  setNotifications({
                    ...notifications,
                    error: false,
                  });
                }}
                className="bg-white rounded-full inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="sr-only">Cerrar</span>
                <FontAwesomeIcon icon={faTimesCircle} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}

export default GenericError;
