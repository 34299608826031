import React from 'react';
import { AnimatePresence } from 'framer-motion';
import Header from '../../shared-components/Header';

function Terms() {
  const date = 'Mayo 14 de 2024';

  return (
    <AnimatePresence>
      <Header />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            {/* Header */}
            <p className="mt-1 text-xl tracking-tight font-bold text-gray-900 sm:text-2xl lg:text-4xl">
              Términos y Condiciones
            </p>
            {/* Subheader */}
            <p className="mt-1 text-md tracking-tight font-semibold text-gray-600 sm:text-xl lg:text-2xl">
              Cómo trabajamos y te brindamos soporte
            </p>
            {/* Last Update */}
            <p className="mt-1 text-md tracking-tight font-semibold text-gray-600 ">
              {`Actualizado: ${date}`}
            </p>
            {/* Intro */}
            <p className="mt-8 text-md tracking-tight font-normal text-gray-600 px-0 text-justify">
              ¡Bienvenido a Antonino&apos;s Pizza! Al
              registrarte para obtener una Cuenta de Antoninos Pizza o al
              utilizar cualquier servicio de Antonino&apos;s Pizza,
              aceptas quedar sujeto a los siguientes términos y condiciones.
            </p>
            {/* Body */}
            <p className="mt-8 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">Quiénes somos</h1>
                <li className="text-md text-left font-normal ml-4">
                  Antonino&apos;s Pizza es una empresa puertorriqueña y la
                  primera franquicia de pizza en Puerto Rico, fundada en 1988.
                  Nos especializamos en ofrecer una experiencia deliciosa y
                  conveniente para nuestros clientes tanto en nuestras sucursales como
                  a través de nuestra plataforma de pedidos en línea.
                </li>
                <li className="text-md text-left font-normal ml-4">
                  Al utilizar nuestros servicios, aceptas cumplir con los
                  términos y condiciones establecidos aquí.
                </li>
              </ol>
            </p>
            <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">
                  Uso de la Página Web
                </h1>
                <li className="text-md text-left font-semibold ml-4 mt-4">
                  El uso de este sitio web está sujeto a las siguientes condiciones:
                </li>
                <li className="text-md text-left font-normal ml-4">
                  El contenido de las páginas de este sitio web es solo para
                  su información y uso general. Está sujeto a cambios sin previo aviso.
                </li>
                <li className="text-md text-left font-normal ml-4">
                  Al realizar un pedido a través de nuestro sitio web, usted
                  acepta cumplir con nuestras políticas de pedido y entrega.
                </li>
                <li className="text-md text-left font-normal ml-4">
                  Nos reservamos el derecho de rechazar o cancelar cualquier
                  pedido en cualquier momento por razones que incluyen,
                  pero no se limitan a, disponibilidad de productos, errores
                  en la descripción o el precio de los productos.
                </li>
              </ol>
            </p>
            <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">
                  Información Confidencial
                </h1>
                <li className="text-md text-left font-semibold ml-4 mt-4">
                  Protección de la Información y No Divulgación.
                </li>
                <li className="text-md text-left font-normal ml-4">
                  Entendemos la importancia de proteger tu privacidad y la
                  confidencialidad de cualquier información compartida con
                  nosotros durante el proceso de pedido en línea. Nos
                  comprometemos a mantener la confidencialidad de tus datos
                  y a no divulgarlos a terceros sin tu consentimiento.
                </li>
              </ol>
            </p>
            <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">
                  Cómo puedes contactarnos
                </h1>
                <li className="text-md text-left font-normal ml-0">
                  Si tienes alguna pregunta o necesitas ayuda con tu pedido
                  en línea, no dudes en ponerte en contacto con nosotros
                  enviándonos un correo electrónico a Soporte de Antonino&apos;s
                  Pizza.{' '}
                  <a
                    className="text-blue-800 font-semibold"
                    href="mailto:antoninospizza1988@gmail.com"
                  >
                    Servicio al Cliente
                  </a>
                  .
                </li>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </AnimatePresence>
  );
}

export default Terms;
