import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import is from 'is_js';
import _ from 'lodash';
import {
  CheckIcon,
  ChevronDownIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCcAmex,
  faCcVisa,
  faCcDinersClub,
  faCcMastercard,
  faCcDiscover,
} from '@fortawesome/free-brands-svg-icons';
import {
  paymentOptionAtom,
  cardAtom,
  showCreditCardFormAtom,
  storeAtom,
  serviceAtom,
  notificationsAtom,
  tokenAtom,
} from '../../../../../../atoms/Atoms';
import api from '../../../../../../api/api';
import Loader from '../../../../../shared-components/loader/Loader';

function PaymentOptions() {
  const token = useRecoilValue(tokenAtom);
  const store = useRecoilValue(storeAtom);
  const service = useRecoilValue(serviceAtom);
  const [card, setCard] = useRecoilState(cardAtom);
  const [paymentOption, setPaymentOption] = useRecoilState(paymentOptionAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [open, setOpen] = useRecoilState(showCreditCardFormAtom);
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [cards, setCards] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchPaymentOptions = () => {
    api
      .get(`store/paymentoptions/${store.locations[0].id}/${service}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setNotifications({
            ...notifications,
            token: response.data.error,
          });
        } else {
          setPaymentOptions(response.data.data.paymentOptions);
          setCards(response.data.data.userCards);
        }
      })
      .catch((err) => {
        setNotifications({ ...notifications, token: err.message });
      });
  };

  const removeCard = (cid) => {
    setLoading(true);
    const tempCardId = cid;
    api
      .post(`user/cards/remove/${cid}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setNotifications({
            ...notifications,
            token: response.data.error,
          });
        } else {
          setCards(_.filter(cards, (c) => c.id !== tempCardId));
          setNotifications({ ...notifications, creditCardDeleteSuccess: true });
        }
      })
      .then(() => {
        setLoading(false);
        if (tempCardId === card) {
          setCard(null);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setNotifications({ ...notifications, token: err.message });
      });
  };

  useEffect(() => {
    if (paymentOption) {
      if (
        paymentOption.needsBillingAgreement &&
        !paymentOption.hasActiveBillingAgreement
      ) {
        setPaymentOption(null);
      } else {
        setCard(null);
      }
    }
  }, [paymentOption]);

  useEffect(() => {
    if (card) {
      setPaymentOption(null);
    }
  }, [card]);

  useEffect(() => {
    if (token && store && !is.undefined(service)) {
      fetchPaymentOptions();
    }
  }, [token, store, service]);

  return (
    <>
      {(is.null(paymentOptions) || is.null(cards)) && (
        <div className="flex flex-col items-center p-4 space-y-2">
          <Loader color="bg-gray-600" />
        </div>
      )}
      {is.not.empty(paymentOptions) &&
        _.map(paymentOptions, (e) => (
          <div key={e.id} className="flex items-center divide-x">
            <button
              type="button"
              onClick={() => {
                setPaymentOption(e);
              }}
              className="flex flex-row items-center flex-1 text-xs p-4 font-medium font-bold tracking-tight hover:bg-gray-50 space-x-2 outline-none focus:outline-none transition duration-500 ease-in-out"
            >
              <CheckIcon
                className={
                  e.id === paymentOption?.id
                    ? 'w-4 h-4 text-green-400'
                    : 'w-4 h-4'
                }
              />
              <div className="space-x-1 flex">
                <img className="w-10" src={e.imageUrl} alt={e.name} />
                <span
                  className={
                    e.id === paymentOption?.id
                      ? 'text-green-400 m-auto'
                      : 'm-auto'
                  }
                >
                  {e.name}
                </span>
              </div>
            </button>
          </div>
        ))}
      {is.not.empty(cards) &&
        _.map(cards, (e) => (
          <div key={e.id} className="flex items-center divide-x">
            <button
              type="button"
              onClick={() => {
                setCard(e.id);
              }}
              className="flex flex-row items-center flex-1 text-xs p-4 font-medium font-bold tracking-tight hover:bg-gray-50 space-x-2 outline-none focus:outline-none transition duration-500 ease-in-out"
            >
              <CheckIcon
                className={e.id === card ? 'w-4 h-4 text-green-400' : 'w-4 h-4'}
              />
              <div className="space-x-1">
                <span className={e.id === card ? 'text-green-400' : ''}>
                  {e.nickname}
                </span>
                <span className="text-gray-400 font-light">
                  (Exp. {e.expMonth}/{e.expYear})
                </span>
              </div>
            </button>
            <button
              type="button"
              onClick={() => removeCard(e.id)}
              className="w-16 flex justify-center text-xs font-medium p-4 text-red-400 hover:text-red-600 outline-none focus:outline-none transition duration-500 ease-in-out"
            >
              {loading && card === e.id ? (
                <Loader color="bg-gray-600" />
              ) : (
                <TrashIcon className="w-4 h-4" />
              )}
            </button>
          </div>
        ))}
      {is.empty(cards) && (
        <div className="flex flex-col items-center p-4 space-y-2">
          <div className="text-gray-300 text-xs">
            Presione en registrar tarjeta para añadir método de pago
          </div>
          <div className="flex justify-center items-center space-x-1 text-gray-200 text-3xl">
            <FontAwesomeIcon icon={faCcAmex} />
            <FontAwesomeIcon icon={faCcVisa} />
            <FontAwesomeIcon icon={faCcDinersClub} />
            <FontAwesomeIcon icon={faCcMastercard} />
            <FontAwesomeIcon icon={faCcDiscover} />
          </div>
        </div>
      )}
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="flex justify-between items-center bg-gray-50 hover:bg-gray-100 w-full flex items-center text-xs font-medium font-bold tracking-tight space-x-2 outline-none focus:outline-none transition duration-500 ease-in-out"
      >
        <div className="flex items-center space-x-2 p-4">
          <PlusCircleIcon className="h-4 w-4 text-yellow-500" />
          <div className="space-x-1">Registrar tarjeta</div>
        </div>
        <div className="flex items-center justify-center w-16">
          <ChevronDownIcon
            className={`w-4 h-4 transition duration-500 easy-in-out ${
              open ? 'transform rotate-180' : ''
            }`}
          />
        </div>
      </button>
    </>
  );
}

export default PaymentOptions;
