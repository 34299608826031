import React, { useState, useEffect } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import is from 'is_js';
import _ from 'lodash';
import { XIcon } from '@heroicons/react/outline';
import {
  itemAtom,
  orderAtom,
  quantityAtom,
  optionAtom,
  addonsAtom,
  sideAtom,
  instructionsAtom,
} from '../../../../../atoms/Atoms';
import Counter from './shared-components/Counter';
import addItemXTimes from '../../../../../utils/addItemXTimes';
import formatItem from '../../../../../utils/formatItem';
import Options from './shared-components/Options';
import { optionSelector } from '../../../../../atoms/Selectors';
import Toppings from './shared-components/Toppings';
import Sides from './shared-components/Sides';
import Instructions from './shared-components/Instructions';

function Modal() {
  const [item, setItem] = useRecoilState(itemAtom);
  const [order, setOrder] = useRecoilState(orderAtom);
  const [quantity, setQuantity] = useRecoilState(quantityAtom);
  const [side, setSide] = useRecoilState(sideAtom);
  const [addons, setAddons] = useRecoilState(addonsAtom);
  const [option, setOption] = useRecoilState(optionAtom);
  const [instruction, setInstructions] = useRecoilState(instructionsAtom);
  const total = useRecoilValue(optionSelector);
  const [requirements, setRequirements] = useState([]);

  const onChangeReq = (obj, i) => {
    const tempItem = { ...item };
    const tempReqs = _.map(tempItem.requirements, (r) => ({
      ...r,
      selected: r.selected
    }));
    const reqIndex = item.requirements.findIndex((r) => r.id === obj.id);
    tempReqs[reqIndex].selected = true;
    tempItem.requirements = tempReqs;
    let check = true;
    for (let index = 0; index < tempItem.requirements.length; index++) {
      check = check && tempItem.requirements[index].selected;
    }
    tempItem.allReqsSelected = check;
    setItem(tempItem);
    const foundIdx = requirements.findIndex((add) => add.parentId === obj.id);
    if (foundIdx >= 0) {
      requirements.splice(foundIdx, 1);
      setRequirements([...requirements]);
    } else {
      setRequirements([...requirements, i]);
    }
  };

  useEffect(() => {
    if (item && option === '') {
      setOption(item.prices[0]);
      const tempItem = { ...item };
      let check = true;
      for (let index = 0; index < tempItem.requirements.length; index++) {
        check = check && tempItem.requirements[index].selected;
      }
      tempItem.allReqsSelected = check;
      setItem(tempItem);
    }
  }, [item]);

  return (
    <Transition show={is.not.null(item)} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={() => {
          setItem(null);
          setOption('');
          setAddons([]);
          setSide([]);
          setRequirements([]);
          setQuantity(1);
          setInstructions('');
        }}
      >
        <div className="flex justify-center items-center h-screen">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="bg-gray-900 absolute inset-0 backdrop-filter backdrop-blur-sm bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex flex-col w-full h-5/6 w-full max-w-lg my-8 divide-y divide-gray-200 transition-all transform bg-white shadow-xl duration-500 easy-in-out">
              <Dialog.Title
                as="div"
                className="flex items-center space-x-2 px-4 justify-between text-lg h-20"
              >
                {/* HEADER TITLE */}
                <div>
                  <div className="text-xs text-gray-500">
                    {item && item.sectionName}
                  </div>
                  <div className="font-semibold tracking-tight truncate">
                    {item && item.name}
                  </div>
                </div>
                {/* HEADER ACTIONS */}
                <button
                  type="button"
                  onClick={() => {
                    setItem(null);
                    setOption('');
                    setAddons([]);
                    setSide([]);
                    setRequirements([]);
                    setQuantity(1);
                    setInstructions('');
                  }}
                  className="flex items-center justify-center text-gray-500 hover:text-gray-600 ring-0 focus:ring-0 bg-gray-100 w-10 h-10 rounded-full outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                >
                  <XIcon className="w-5 h-5" />
                </button>
              </Dialog.Title>
              <div className="flex flex-col flex-1 bg-white overflow-y-auto no-scrollbar p-4 space-y-4">
                {/* Options */}
                {item?.imageUrl && (
                  <div className="flex justify-center">
                    <img
                      alt="Imagen de producto"
                      src={item?.imageUrl}
                      className="h-52 w-52 object-center object-cover"
                    />
                  </div>
                )}
                <div className="text-sm">{item && item.description}</div>
                {item && item.prices.length > 1 && (
                  <Options items={item.prices} />
                )}

                {item &&
                  is.not.empty(item.requirements) &&
                  _.map(item.requirements, (r) => (
                    <div key={r.id} className="mb-4">
                      <div className="text-sm font-medium space-x-1 mb-2 pb-2 border-b">
                        <span className="uppercase">{r.name}</span>
                        <span className="text-xs font-normal text-gray-500">
                          (Requerido)
                        </span>
                      </div>
                      <div className="space-y-2">
                        {_.map(r.items, (i) => (
                          <div
                            key={i.id}
                            className="text-sm text-gray-700 flex items-center space-x-2"
                          >
                            <input
                              name={r.name}
                              type="radio"
                              value={i.name}
                              className="border-gray-200 border-2 text-black focus:border-gray-300 focus:ring-black h-5 w-5"
                              onChange={() => onChangeReq(r, i)}
                            />
                            <div>{i.name}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}

                {item && is.not.empty(item.sides) && (
                  <Sides items={item.sides} />
                )}

                {item && is.not.empty(item.addOns) && (
                  <Toppings items={item.addOns} />
                )}
                <Instructions />
              </div>
              <div className="flex items-center justify-end space-x-2 p-4 bg-gray-100 h-20">
                <Counter />
                <button
                  type="button"
                  disabled={!item?.allReqsSelected}
                  onClick={() => {
                    const fItem = formatItem(
                      item,
                      addons,
                      requirements,
                      side,
                      option,
                      total / quantity,
                      instruction
                    );
                    localStorage.setItem(
                      'ant-cart',
                      JSON.stringify(addItemXTimes(fItem, order, quantity))
                    );
                    setOrder(addItemXTimes(fItem, order, quantity));
                    setItem(null);
                    setOption('');
                    setAddons([]);
                    setSide([]);
                    setRequirements([]);
                    setQuantity(1);
                    setInstructions('');
                  }}
                  className="disabled:cursor-not-allowed disabled:opacity-50 h-full flex flex-1 items-center justify-between w-2/3 text-sm uppercase text-white font-semibold bg-green-500 hover:bg-green-400 rounded-full px-4 py-4 space-x-2 outline-none focus:outline-none transition ease-in-out duration-500"
                >
                  <div className="text-xs truncate">Añadir al Carrito</div>
                  <div className="text-xs">
                    {!Number.isNaN(total) && `$${total.toFixed(2)}`}
                  </div>
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
